import { t } from "i18n-js";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { canPurchase } from "@circle-react/helpers/paywallCheckoutHelpers";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { FirstPaymentPreview } from "./FirstPaymentPreview";
import { FuturePaymentsPreview } from "./FuturePaymentsPreview";
import { TotalDueTodayPreview } from "./TotalDueTodayPreview";

export const CheckoutPreview = () => {
  const { previewMutation, hasAlreadyPurchased, paywall } =
    usePaywallCheckoutContext();
  const { data: preview } = previewMutation;

  if (previewMutation.isLoading) {
    return (
      <div className="py-2">
        <Loader variant="default" />
      </div>
    );
  }

  if (
    canPurchase(hasAlreadyPurchased, paywall) ||
    previewMutation.isIdle ||
    previewMutation.isError ||
    !preview
  ) {
    return null;
  }

  return (
    <div className="flex flex-col md:pt-6">
      <div className="mb-4 flex flex-row items-center">
        <div className="flex flex-row items-center">
          <Typography.LabelLg weight="semibold">
            {t("paywall_checkout.order_summary")}
          </Typography.LabelLg>
        </div>
      </div>
      <FirstPaymentPreview className="!py-0 !pb-6" preview={preview} />
      <FuturePaymentsPreview preview={preview} />
      <TotalDueTodayPreview preview={preview} />
    </div>
  );
};

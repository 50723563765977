import { useCallback } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { mapCurrencyAmountStrToInt } from "@circle-react/components/Paywalls/Admin/utils";
import type { PaywallPriceInterval } from "@circle-react/types";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { usePricingTabContext } from "../usePricingTabContext";
import { PricingTabAddPriceModalFields } from "./PricingTabAddPriceModalFields";
import { PricingTabAddPriceModalNotice } from "./PricingTabAddPriceModalNotice";
import { PricingTabPriceModalSelectField } from "./SelectField";

export const PricingTabAddPriceModalContent = () => {
  const { priceBuildOptions, priceTypeOptions, addPrice, hideAddPriceModal } =
    usePricingTabContext();

  const { handleSubmit } = useFormContext();
  const onConfirm = useCallback(() => {
    const submit = handleSubmit(data => {
      const price_type = data.price_type;
      const amount: number = mapCurrencyAmountStrToInt(data.amount);

      let interval: PaywallPriceInterval | undefined;
      let interval_count: number | undefined;
      if (data.billing_frequency) {
        const frequency =
          priceBuildOptions[data.price_type].frequencies[
            data.billing_frequency
          ];
        if (frequency) {
          interval = frequency.interval;
          interval_count = frequency.interval_count;
        }
      }

      let installments_count: number | undefined;
      if (data.installments_count) {
        installments_count = parseInt(data.installments_count);
      }

      let upfront_payment_amount: number | undefined;
      let isUpfrontPaymentRequiredForTrial: boolean | undefined;
      if (data.upfront_payment_enabled) {
        upfront_payment_amount = mapCurrencyAmountStrToInt(
          data.upfront_payment_amount,
        );
        isUpfrontPaymentRequiredForTrial = data.trial_requires_upfront_payment;
      }

      addPrice({
        price_type,
        amount,
        interval,
        interval_count,
        installments_count,
        upfront_payment_amount,
        trial_requires_upfront_payment:
          isUpfrontPaymentRequiredForTrial ?? false,
      });
      hideAddPriceModal();
    });
    submit().catch(error => console.error("Error adding price", error));
  }, [priceBuildOptions, addPrice, hideAddPriceModal, handleSubmit]);

  return (
    <ConfirmationModal
      isOpen
      title={t("settings.paywalls.modal.tabs.pricing.add_price_modal.title")}
      confirmText={t(
        "settings.paywalls.modal.tabs.pricing.add_price_modal.actions.confirm",
      )}
      onConfirm={onConfirm}
      onClose={hideAddPriceModal}
      onCancel={hideAddPriceModal}
      size="lg"
      dataTestId="save-paywall-price"
    >
      <div className="flex flex-col gap-4">
        <PricingTabPriceModalSelectField
          name="price_type"
          labelKey="payment_type"
          options={priceTypeOptions()}
        />
        <PricingTabAddPriceModalFields />
        <PricingTabAddPriceModalNotice />
      </div>
    </ConfirmationModal>
  );
};

import { usePricingTabContext } from "../../usePricingTabContext";
import { RepurchaseToggle } from "../Fields/RepurchaseToggle";

export const RepurchaseForm = () => {
  const { hasRecurringPrices, hasActivePrices } = usePricingTabContext();
  const isToggleDisabled = !hasActivePrices || hasRecurringPrices();
  const tooltipText = isToggleDisabled
    ? "can_be_repurchased_tooltip_disabled"
    : "can_be_repurchased_tooltip";

  return (
    <RepurchaseToggle
      tooltipText={tooltipText}
      isToggleDisabled={isToggleDisabled}
    />
  );
};

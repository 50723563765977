import classNames from "classnames";
import type { CommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import { useThemeContext } from "@circle-react/providers";
import { CommunityLogo } from "@circle-react-shared/CommunityLogo";
import { Typography } from "@circle-react-shared/uikit/Typography";

export interface CommunitiesModalLinkProps {
  community: CommunitySwitcher;
  href: string;
}

export const CommunitiesModalLink = ({
  community,
  href,
}: CommunitiesModalLinkProps) => {
  const { isDarkAppearance } = useThemeContext();

  return (
    <a
      key={community.id}
      href={href}
      className="hover:bg-tertiary focus:bg-tertiary flex items-center gap-x-3 rounded-xl p-1 transition-colors duration-75"
    >
      <div
        className={classNames(
          "switcher__icon !m-0 flex items-center justify-center rounded-xl border-2 border-transparent",
          {
            "active border-secondary": community.is_current_community,
          },
        )}
      >
        <CommunityLogo
          {...community}
          logo_attachment_url={undefined}
          icon_attachment_url={
            isDarkAppearance
              ? community.dark_icon_url
              : community.light_icon_url
          }
          shouldShowTooltip={false}
        />
      </div>
      <Typography.LabelMd weight="medium">{community.name}</Typography.LabelMd>
    </a>
  );
};

import { useBoolean } from "react-use";
import { t } from "@circle-react/custom_i18n";
import { useBroadcastReport } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { PreviewModal } from "./PreviewModal";
import { SmallInlinePreview } from "./SmallInlinePreview";

const localeNamespace = "settings.workflows.view";

export function ViewEmailDetails() {
  const { data } = useBroadcastReport();
  const [isPreviewModalOpen, toggleIsPreviewModalOpen] = useBoolean(false);

  return (
    <div className="flex justify-between gap-x-2">
      <div className="flex flex-1 items-center gap-x-4">
        <SmallInlinePreview />
        <div className="flex flex-col gap-y-1">
          <Typography.LabelSm weight="semibold">
            {data?.internal_name}
          </Typography.LabelSm>
          <div className="flex gap-x-1">
            <Typography.LabelXs weight="medium">
              {t([localeNamespace, "subject"])}
            </Typography.LabelXs>
            <Typography.LabelXs>{data?.subject}</Typography.LabelXs>
          </div>
        </div>
      </div>
      <div>
        <Button
          variant="secondary-light"
          type="button"
          onClick={toggleIsPreviewModalOpen}
        >
          {t([localeNamespace, "view_email"])}
        </Button>
      </div>
      <PreviewModal
        isOpen={isPreviewModalOpen}
        onClose={toggleIsPreviewModalOpen}
      />
    </div>
  );
}

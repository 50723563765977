import { useState } from "react";
import debounce from "lodash/debounce";
import { useFetchLesson } from "./useFetchLesson";
import { useFetchLessons } from "./useFetchLessons";

const lessonOptionFormatter = lesson => ({
  label: lesson.name,
  value: lesson.id,
  description: `${lesson.course_name} • ${lesson.section_name}`,
});

export const useLessonSelector = ({ selectedLessonId }) => {
  const [lessonName, setLessonName] = useState("");
  const { data, isLoading: isLoadingLessons } = useFetchLessons({
    name: lessonName,
  });
  const {
    data: lesson,
    isLoading: isLoadingLesson,
    isSuccess: isLessonLoaded,
  } = useFetchLesson({
    lessonId: selectedLessonId,
  });
  const lessons = data?.records ?? [];

  const lessonOptions = lessons.map(lessonOptionFormatter);

  if (lesson && !isLoadingLesson && isLessonLoaded) {
    lessonOptions.push(lessonOptionFormatter(lesson));
  }

  const handleLessonTitleChange = debounce(title => setLessonName(title), 500);

  return {
    isLoading: isLoadingLessons || isLoadingLesson,
    lessonOptions,
    handleLessonTitleChange,
  };
};

import PropTypes from "prop-types";
import { FiltersList } from "@circle-react-shared/Filter/FiltersList";

export const HistoryFilters = ({ filtersConfig }) => (
  <FiltersList className="border-stroke-background relative z-20 border-b px-6 py-3">
    {filtersConfig.map(({ name, component: Component }) => (
      <Component key={name} />
    ))}
  </FiltersList>
);

HistoryFilters.propTypes = {
  filtersConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

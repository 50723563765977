import { t } from "i18n-js";
import { FormCountrySelector } from "./FormCountrySelector";
import { FormItem } from "./FormItem";
import { SettingsSection } from "./SettingsSection";
import { I18N_ROOT } from "./constants";

export const PostalAddress = () => (
  <SettingsSection
    title={t([I18N_ROOT, "postal_address"])}
    description={t([I18N_ROOT, "postal_address_description"])}
    childrenClassName="mt-7"
  >
    <FormItem name="address_line_1" label={t([I18N_ROOT, "address_line_1"])} />
    <div className="grid gap-3 sm:grid-cols-1 md:grid-cols-2">
      <FormItem name="address_city" label={t([I18N_ROOT, "address_city"])} />
      <FormItem name="address_state" label={t([I18N_ROOT, "address_state"])} />
      <FormItem
        name="address_postal_code"
        label={t([I18N_ROOT, "address_postal_code"])}
      />
      <FormCountrySelector />
    </div>
  </SettingsSection>
);

import { SuggestedSpacesSection } from "./SuggestedSpacesSection";
import { WelcomeBanner } from "./WelcomeBanner";

export const HomePage = props => {
  const { community_member_id, display_welcome_banner } = props;

  return (
    <div>
      {display_welcome_banner && (
        <WelcomeBanner communityMemberId={community_member_id} />
      )}
      <SuggestedSpacesSection />
    </div>
  );
};

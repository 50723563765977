import { PaymentProcessorStatus } from "@circle-react/components/Paywalls/Admin/PaywallsAdmin/constants";

export const STATUS = Object.freeze({
  RESTRICTED: "restricted",
  PENDING: "pending",
  REJECTED: "rejected",
  ENABLED: "enabled",
});

export const paymentProcessorValidations = paymentProcessor => [
  () => (!paymentProcessor ? PaymentProcessorStatus.NOT_EXIST : undefined),
  () =>
    paymentProcessor.status === STATUS.RESTRICTED
      ? PaymentProcessorStatus.RESTRICTED
      : undefined,
  () =>
    paymentProcessor.is_restricted_for_application_fees
      ? PaymentProcessorStatus.RESTRICED_FOR_APPLICATION_FEES
      : undefined,
  () =>
    paymentProcessor.status === STATUS.PENDING
      ? PaymentProcessorStatus.PENDING
      : undefined,
  () =>
    paymentProcessor.status === STATUS.REJECTED
      ? PaymentProcessorStatus.REJECTED
      : undefined,
];

import classNames from "classnames";
import { t } from "i18n-js";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { canPurchase } from "@circle-react/helpers/paywallCheckoutHelpers";
import { memberProfileModalPaths } from "@circle-react/helpers/urlHelpers";

export const CheckoutFormAlerts = () => {
  const { paywall, communityRootUrl, hasAlreadyPurchased, paymentError } =
    usePaywallCheckoutContext();

  let type = "";
  let message = "";

  if (canPurchase(hasAlreadyPurchased, paywall)) {
    type = "warning";
    message = t("paywall_checkout.form.errors.already_purchased", {
      billing_url: `${communityRootUrl}${memberProfileModalPaths.billing.slice(
        1,
      )}`,
    });
  } else if (paymentError && paymentError.message) {
    type = paymentError.type;
    message = paymentError.message;
  }

  if (!type || !message) {
    return null;
  }

  return (
    <div
      className={classNames("rounded border px-6 py-5", {
        "border-red-500 bg-red-50 text-red-800": type === "danger",
        "border-yellow-500 bg-yellow-50 text-yellow-800": type === "warning",
      })}
      dangerouslySetInnerHTML={{
        __html: message,
      }}
      data-testid="checkout-alert"
    ></div>
  );
};

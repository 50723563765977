import { useModal } from "@ebay/nice-modal-react";
import { t } from "i18n-js";
import type { CommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import { Modal } from "@circle-react-uikit/ModalV2";
import { getAutomaticLoginRootUrl } from "../utils";
import { CommunitiesModalLink } from "./CommunitiesModalLink";

export interface MyCommunitiesModalProps {
  communities: CommunitySwitcher[];
  token: string;
}

export const CommunitiesModal = ({
  communities,
  token,
}: MyCommunitiesModalProps) => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{t("my_communities")}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="md:my-6 md:!px-6 md:!py-0">
          <div className="flex max-h-96 flex-col gap-2 py-0.5">
            {communities.map(community => (
              <CommunitiesModalLink
                key={community.id}
                community={community}
                href={getAutomaticLoginRootUrl(community.root_url, token)}
              />
            ))}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

import classNames from "classnames";
import { t } from "i18n-js";
import isUndefined from "lodash/isUndefined";
import { WORKFLOW_TABS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { useSetWorkflowSource } from "@circle-react/components/SettingsApp/Workflows/hooks/useOpenedFromPage";
import { Loader } from "@circle-react-uikit/Loader";
import { Tab } from "@circle-react-uikit/TabV2";
import { useWorkflowsData } from "../../context/WorkflowsDataProvider";
import { Header } from "../Header";
import { LandingPage } from "../LandingPage";
import { ArchivedTable } from "./ArchivedTable";
import { AutomationsTable } from "./AutomationsTable";
import { BulkActionsTable } from "./BulkActionsTable";
import { EmptyWorkflows } from "./EmptyWorkflows";
import { ScheduledTable } from "./ScheduledTable";
import { useWorkflowTabs } from "./useWorkflowTabs";

export const WorkflowTabs = () => {
  const {
    isLoading,
    totalWorkflows,
    hasArchivedWorkflows,
    isWorkflowExecutionEnabled,
    tabsCountersMap,
    selectedTabIndex,
    handleTabChange,
    tabNames,
    tabLabels,
  } = useWorkflowTabs();

  const { category } = useWorkflowsData();

  useSetWorkflowSource();

  if (isLoading || isUndefined(selectedTabIndex)) {
    return <Loader />;
  }

  if (!isLoading && (totalWorkflows === 0 || !isWorkflowExecutionEnabled)) {
    return category ? <EmptyWorkflows category={category} /> : <LandingPage />;
  }

  return (
    <>
      <Header
        showBottomBorder={false}
        title={t("settings.workflows.workflows")}
      />
      <main>
        <Tab.Group
          selectedIndex={selectedTabIndex}
          onChange={handleTabChange}
          className="overflow-hidden"
        >
          <Tab.List className="bg-primary border-primary border-b px-6">
            {tabNames.map(tab => (
              <Tab
                className={classNames({
                  hidden:
                    tab === WORKFLOW_TABS.ARCHIVED && !hasArchivedWorkflows,
                })}
                key={tab}
              >
                {tabLabels[tab]}
                <Tab.Counter>{tabsCountersMap[tab]}</Tab.Counter>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <AutomationsTable />
            </Tab.Panel>
            <Tab.Panel>
              <BulkActionsTable />
            </Tab.Panel>
            <Tab.Panel>
              <ScheduledTable />
            </Tab.Panel>
            {hasArchivedWorkflows && (
              <Tab.Panel>
                <ArchivedTable />
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </main>
    </>
  );
};

WorkflowTabs.propTypes = {};

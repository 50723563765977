import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../../types";
import { FormSendEmail } from "./FormSendEmail";
import { ViewSendEmail } from "./ViewSendEmail";

export const sendEmail: Readonly<ActionOption<object>> = {
  label: t("settings.workflows.form.actions.send_email"),
  value: "send_email",
  group: t("settings.workflows.form.action_groups.communication"),
  leftAddon: <Icon type="16-email" size={16} className="!h-4 !w-4" />,
  formComponent: FormSendEmail,
  viewComponent: ViewSendEmail,
  historyDetailViewComponent: () => null,
  icon: "email",
  released: false,
} as const;

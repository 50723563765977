import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { createBaseFilter } from "@circle-react-shared/MemberFilters/types";
import { getDefaultAction } from "../../constants";

const DEFAULT_AUDIENCE = {
  filter_rules: {
    filters: [createBaseFilter({ key: "role", value: "Member" })],
  },
};

export const WorkflowFormProvider = ({ workflow = {}, children }) => {
  const hasActions = !isEmpty(workflow?.actions);
  const actions = hasActions ? workflow.actions : [getDefaultAction()];

  const isBulkAction = workflow?.workflow_type === "bulk_action";
  const isScheduled = workflow?.workflow_type === "scheduled";
  const hasAudienceFilters =
    !isEmpty(workflow?.audience?.filter_rules?.filters) &&
    Object.values(workflow.audience.filter_rules.filters).some(Boolean);

  const audience =
    (isBulkAction || isScheduled) && hasAudienceFilters
      ? workflow.audience
      : DEFAULT_AUDIENCE;

  const schedule = isScheduled ? workflow.schedule : {};

  const methods = useForm({
    mode: "onChange",
    revalidateMode: "onChange",
    defaultValues: {
      ...workflow,
      actions,
      audience,
      schedule,
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

WorkflowFormProvider.propTypes = {
  workflow: PropTypes.object.isRequired,
  debug: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

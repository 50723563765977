import type { ReactNode } from "react";
import classNames from "classnames";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface SpaceGroupViewWrapperProps {
  listId?: string;
  isCollapsed?: boolean;
  children?: ReactNode;
  onCollapseToggle?: () => void;
  isDragging?: boolean;
}

export const SpaceGroupViewWrapper = ({
  listId = "",
  isCollapsed = false,
  children,
  onCollapseToggle,
  isDragging,
}: SpaceGroupViewWrapperProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const { isV3Enabled } = useIsV3();

  return (
    <button
      type="button"
      className={classNames(
        "text-c-sidebar hover:text-c-sidebar hover:bg-c-sidebar-hover focus-visible:text-c-sidebar focus-visible:bg-c-sidebar-hover group mb-1 flex w-full select-none items-center justify-between text-sm font-semibold",
        {
          "cursor-grab":
            !isDragging && isCommunityAdmin(currentCommunityMember),
          "cursor-grabbing": isDragging,
          "rounded-md px-2 py-1.5": !isV3Enabled,
          "rounded-lg px-4 py-1.5 transition-colors duration-200": isV3Enabled,
        },
      )}
      data-testid="space-group"
      onClick={onCollapseToggle}
      aria-expanded={!isCollapsed}
      aria-controls={listId}
    >
      {children}
    </button>
  );
};

import type { ComponentPropsWithoutRef } from "react";
import { useRef } from "react";
import { uniqueId } from "lodash";
import { DateTimePicker } from "@circle-react-uikit/DateTimePicker";

interface ScheduleDateTimePickerProps
  extends ComponentPropsWithoutRef<typeof DateTimePicker> {
  name: string;
}

/**
 * Schedule DateTimePicker is located on top of two modals.
 * We have to move the picker UI to a portal to avoid the z-index issues.
 */
export function ScheduleDateTimePicker({
  name,
  ...rest
}: ScheduleDateTimePickerProps) {
  const { current: dateTimePickerPortalId } = useRef(
    uniqueId("date-time-picker-portal-"),
  );

  return (
    <DateTimePicker
      name={name}
      portalId={dateTimePickerPortalId}
      popperClassName="!z-[1080] !w-auto"
      minDate={new Date()}
      {...rest}
    />
  );
}

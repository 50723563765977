import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { LessonItem } from "./LessonItem";

export const DraggableLessonItem = ({ lesson, section, space, ...rest }) => {
  const {
    attributes,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: lesson.sortId,
    data: {
      dragType: "lesson",
      item: lesson,
      sectionSortId: section.sortId,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <LessonItem
      isDraggable
      isDragging={isDragging}
      ref={setNodeRef}
      lesson={lesson}
      section={section}
      space={space}
      tableRowProps={{
        style,
        ...attributes,
      }}
      dragHandleProps={{
        listeners,
        setActivatorNodeRef,
      }}
      {...rest}
    />
  );
};

DraggableLessonItem.propTypes = {
  lesson: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  space: PropTypes.object.isRequired,
};

import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react/components/shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";
import { useWorkflow } from "../WorkflowForm/useWorkflow";
import { availableActions } from "./availableActions";

export const useEligibleActions = () => {
  const supportUnreleasedWorkflowsFeaturesEnabled =
    useSupportUnreleasedWorkflowsFeaturesEnabled();

  const { type: workflowType } = useWorkflow();

  // Filter out actions that are not released yet
  let eligibleActions = supportUnreleasedWorkflowsFeaturesEnabled
    ? availableActions
    : availableActions.filter(
        availableAction => availableAction.released !== false,
      );

  // Filter out actions that are not available for the current workflow type
  eligibleActions = eligibleActions.filter(
    action =>
      !action.availability || action.availability.includes(workflowType),
  );

  return { eligibleActions };
};

import { t } from "i18n-js";
import invariant from "tiny-invariant";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { toLocaleString } from "@circle-react/helpers/number";
import { useBroadcastReport } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { ViewRecipientListButton } from "@circle-react-shared/Emails/BroadcastReport/LinkClicksStats/ViewRecipients/ViewRecipientListButton";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.workflows.view";

export const BroadcastMeta = () => {
  const { data } = useBroadcastReport();
  invariant(data, "Broadcast data must be present");
  const {
    subject = "",
    completed_at: completedAt = null,
    recipient_count: recipientCount = null,
  } = data;
  return (
    <div>
      <div className="mb-1 gap-y-1">
        <div className="flex gap-x-1">
          <Typography.LabelSm weight="semibold" color="text-default">
            {t([localeNamespace, "subject"])}
          </Typography.LabelSm>
          <Typography.LabelSm color="text-default">
            {subject}
          </Typography.LabelSm>
        </div>

        <div className="flex gap-x-1">
          <Typography.LabelSm weight="semibold" color="text-default" as="div">
            {t([localeNamespace, "first_delivery"])}
          </Typography.LabelSm>
          {/** @todo: Switch over the field for first delivery */}
          <Typography.LabelSm color="text-default" as="div">
            {completedAt &&
              t([localeNamespace, "sent_on_date_time"], {
                datetime: formattedDateTime({
                  dateTime: new Date(completedAt),
                  format: dateFormats.short_date_at_short_time,
                }),
              })}
          </Typography.LabelSm>
        </div>
      </div>

      <div className="flex items-center">
        <ViewRecipientListButton
          recipient_type="all_recipients"
          className="text-dark inline-block decoration-current decoration-dashed underline-offset-2"
        >
          {typeof recipientCount === "number" && (
            <Typography.LabelSm weight="semibold" color="text-default">
              {toLocaleString(recipientCount)}
            </Typography.LabelSm>
          )}
          &nbsp;
          <Typography.LabelSm weight="semibold" color="text-very-dark">
            {t([localeNamespace, "recipients"])}
          </Typography.LabelSm>
        </ViewRecipientListButton>
      </div>
    </div>
  );
};

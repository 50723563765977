import { Container } from "../components/Container";
import { WorkflowTabs } from "../components/WorkflowTabs";
import { WorkflowsDataProvider } from "../context/WorkflowsDataProvider";
import type { WorkflowsDataProviderProp } from "../context/WorkflowsDataProvider";

export const AllWorkflows = (prop: Partial<WorkflowsDataProviderProp>) => (
  <WorkflowsDataProvider {...prop}>
    <Container>
      <WorkflowTabs />
    </Container>
  </WorkflowsDataProvider>
);

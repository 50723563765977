import { useEffect, useMemo } from "react";
import { t } from "i18n-js";
import { head } from "lodash";
import { useFormContext } from "react-hook-form";
import { FormTextSelect } from "@circle-react-shared/uikit/Form/FormTextSelect";
import { Form } from "@circle-react-uikit/Form";
import { ScheduleDateTimePicker } from "../../ScheduleDateTimePicker";
import { FormDatePicker } from "./FormDatePicker";

const i18nRoot = "settings.workflows.recurring_schedule_modal";
const NEVER = "never";
const ON = "on";
const endsOnOptions = [
  {
    label: t([i18nRoot, NEVER]),
    value: NEVER,
  },
  {
    label: t([i18nRoot, ON]),
    value: ON,
  },
] as const;

export function FirstLastRunPicker() {
  const { watch, setValue } = useFormContext();
  const firstRun = watch("first_run_at");
  const lastRun = watch("last_run_at");
  const endsOn = watch("ends_on");
  const hasScheduleRunBefore = watch("has_schedule_run_before");

  const lastRunMinDate = useMemo(() => {
    if (!firstRun) {
      return new Date();
    }

    return new Date(firstRun);
  }, [firstRun]);

  useEffect(() => {
    if (endsOn === NEVER) {
      setValue("last_run_at", null);
    }
  }, [endsOn, setValue]);

  return (
    <div className="flex flex-col">
      <Form.Item
        name="first_run_at"
        label={t([i18nRoot, "first_run"])}
        isDisabled={hasScheduleRunBefore}
        hideDescription
        hideBorder
      >
        <ScheduleDateTimePicker
          name="first_run_at"
          datePlaceholder={t([i18nRoot, "select_date"])}
          timePlaceholder={t([i18nRoot, "select_time"])}
          maxDate={lastRun ? new Date(lastRun) : undefined}
          disabled={hasScheduleRunBefore}
        />
      </Form.Item>
      <div className="flex gap-x-2">
        <Form.Item
          name="ends_on"
          label={t("settings.workflows.recurring_schedule_modal.ends")}
          hideDescription
          hideBorder
          className="flex-1"
        >
          <FormTextSelect name="ends_on" label={null} options={endsOnOptions} />
        </Form.Item>
        <Form.Item
          name="last_run_at"
          emptyLabel
          hideDescription
          hideBorder
          placeholder={t([i18nRoot, "select_date"])}
          className="flex-1"
        >
          <FormDatePicker
            name="last_run_at"
            minDate={lastRunMinDate}
            disabled={endsOn === head(endsOnOptions)?.value}
          />
        </Form.Item>
      </div>
    </div>
  );
}

import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export const SpaceGroupOptionsDropdown = ({
  onAddMembersClick,
  onEditSpaceGroupClick,
  seeMembersLink,
  seeSpacesLink,
  onToggle,
  canManage,
  ...rest
}) => {
  const POPPER_OPTIONS = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [4, 0],
        },
      },
    ],
  };

  const { isViewOnlyMasquerading } = usePunditUserContext();

  return (
    <Dropdown
      className="flex items-center"
      buttonClassName="flex py-0.5 w-5 h-5 rounded hover:bg-black/10 focus-visible:bg-black/10 transition-colors"
      menuButtonEl="button"
      buttonLabel={t("space_groups.view_space_group_options")}
      button={
        <Icon
          type="16-menu-dots-horizontal"
          size={16}
          useWithFillCurrentColor
        />
      }
      onToggle={onToggle}
      popperOptions={POPPER_OPTIONS}
      {...rest}
    >
      <Dropdown.ItemWithLink to={seeSpacesLink}>
        {t("space_groups.see_spaces")}
      </Dropdown.ItemWithLink>
      {canManage && (
        <>
          <Dropdown.ItemWithLink to={seeMembersLink}>
            {t("space_groups.see_members")}
          </Dropdown.ItemWithLink>
          {!isViewOnlyMasquerading && (
            <Dropdown.ItemWithLink
              onClick={() =>
                isFunction(onAddMembersClick) && onAddMembersClick()
              }
            >
              {t("space_groups.add_members")}
            </Dropdown.ItemWithLink>
          )}
          {!isViewOnlyMasquerading && (
            <Dropdown.ItemWithLink
              onClick={() =>
                isFunction(onEditSpaceGroupClick) && onEditSpaceGroupClick()
              }
            >
              {t("space_groups.edit_space_group")}
            </Dropdown.ItemWithLink>
          )}
        </>
      )}
    </Dropdown>
  );
};

SpaceGroupOptionsDropdown.propTypes = {
  onAddMembersClick: PropTypes.func,
  onEditSpaceGroupClick: PropTypes.func,
  seeMembersLink: PropTypes.string,
  seeSpacesLink: PropTypes.string,
  onToggle: PropTypes.func,
};

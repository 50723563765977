import { Suspense } from "react";
import { AdminMemberEditModalContextProvider } from "@circle-react/components/Modals/AdminMemberEditModal/AdminMemberEditModalContextProvider";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const AdminMemberEditComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "AdminMemberEditPage" */ "@circle-react/components/Modals/AdminMemberEditModal/AdminMemberEditModal"
    ),
);

export const AdminMemberEditPage = () => (
  <Suspense fallback={<PageLoader />}>
    <AdminMemberEditModalContextProvider>
      <AdminMemberEditComponent />
    </AdminMemberEditModalContextProvider>
  </Suspense>
);

import PropTypes from "prop-types";
import { isFunction } from "lodash";
import { Button } from "@circle-react-uikit/Button";

export const ContinueButton = ({ onClick, children, ...rest }) => (
  <Button
    large
    variant="circle"
    type="button"
    onClick={() => {
      isFunction(onClick) && onClick();
    }}
    {...rest}
  >
    {children}
  </Button>
);

ContinueButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

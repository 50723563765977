import { useEffect } from "react";
import { useHomePageApi } from "@circle-react/hooks/home_page/useHomePageApi";
import { Icon } from "@circle-react-shared/Icon";
import { BannerContent } from "./BannerContent";
import { DisplayMedia } from "./DisplayMedia";

export const WelcomeBanner = ({ community_member_id }) => {
  const {
    isWelcomeBannerVisible,
    bannerData,
    fetchBannerData,
    setWelcomeBannerPreference,
  } = useHomePageApi();

  const { is_video, media_url, title, description, allow_close } = bannerData;

  useEffect(() => {
    fetchBannerData();
  }, []);

  const handleClose = () => {
    setWelcomeBannerPreference({
      communityMemberId: community_member_id,
      display: false,
    });
  };

  if (!isWelcomeBannerVisible) return null;

  return (
    <div className="welcome-banner">
      <div className="welcome-banner__inside">
        {allow_close && (
          <button
            type="button"
            className="welcome-banner__close"
            onClick={() => handleClose()}
          >
            <Icon type="close" />
          </button>
        )}
        {media_url && (
          <div className="welcome-banner__feature">
            <DisplayMedia isVideo={is_video} mediaUrl={media_url} />
          </div>
        )}
        {(title || description) && (
          <BannerContent title={title} description={description} />
        )}
      </div>
    </div>
  );
};

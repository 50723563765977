import I18n from "i18n-js";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { ItemWithLink } from "@circle-react-shared/uikit/Dropdown/ItemWithLink";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";
import { usePricingTabContext } from "../usePricingTabContext";

export const PricingTabPriceListCellActions = ({
  row: { original: price },
}: any) => {
  const { contentPortalElement } = useModalData();
  const { getPrices, markPriceAsPreferred, removePrice } =
    usePricingTabContext();

  const prices = getPrices();
  const isLastPrice = prices.length === 1;

  if (isLastPrice && price.preferred) {
    return null;
  }

  return (
    <DataTable.Actions
      contentPortalElement={contentPortalElement ?? document.body}
    >
      {!price.preferred && (
        <ItemWithLink
          key="preferred"
          hasDanger={false}
          onClick={() => markPriceAsPreferred(price)}
          linkType="button"
        >
          {I18n.t(
            "settings.paywalls.modal.tabs.pricing.table.actions.preferred",
          )}
        </ItemWithLink>
      )}
      {!isLastPrice && (
        <ItemWithLink
          key="remove"
          hasDanger
          onClick={() => removePrice(price)}
          linkType="button"
        >
          {I18n.t("settings.paywalls.modal.tabs.pricing.table.actions.remove")}
        </ItemWithLink>
      )}
    </DataTable.Actions>
  );
};

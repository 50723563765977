import { isEmpty } from "lodash";
import { useMobileCommunitySwitcherModal } from "@/react/components/Modals/MobileCommunitySwitcherModal";
import { useMediaQuery } from "@/react/hooks/useMediaQuery";
import { useBackendProps } from "@/react/providers";
import { useIsWorkflowsEnabled } from "@circle-react-shared/Workflows";
import { LogoDropdown } from "./LogoDropdown";
import { LogoLink } from "./LogoLink";
import { useDropdownOptions } from "./useDropdownOptions";

export interface LogoTopSectionViewProps {
  communityName?: string;
  isAdmin?: boolean;
  isModerator?: boolean;
  logoType?: "logo" | "icon";
  logoUrl?: string | null;
  onLogoClick?: () => void;
  showToggleTheme?: boolean;
  showCustomizeTheme?: boolean;
  memberCount?: number;
  moderationCount?: number;
}

export const LogoTopSectionView = ({
  communityName = "",
  isAdmin = false,
  isModerator = false,
  logoType = "icon",
  logoUrl,
  onLogoClick,
  showToggleTheme = false,
  showCustomizeTheme = false,
  memberCount,
  moderationCount,
}: LogoTopSectionViewProps) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const mobileCommunitySwitcherModal = useMobileCommunitySwitcherModal();

  const { display_community_switcher: hasCommunitySwitcher } =
    useBackendProps();

  const isWorkflowsEnabled = useIsWorkflowsEnabled();

  const dropDownOptions = useDropdownOptions({
    isAdmin,
    isModerator,
    memberCount,
    moderationCount,
    showToggleTheme,
    showCustomizeTheme,
    isWorkflowsEnabled,
  });

  const shouldDisplayMobileCommunitySwitcher = isMobile && hasCommunitySwitcher;

  if (shouldDisplayMobileCommunitySwitcher) {
    return (
      <div className="flex w-full items-center px-4">
        <LogoLink
          communityName={communityName}
          logoType={logoType}
          logoUrl={logoUrl}
          onLogoClick={() => {
            void mobileCommunitySwitcherModal.show();
          }}
        />
        <LogoDropdown hasFullWidth={false} dropDownOptions={dropDownOptions} />
      </div>
    );
  }

  if (isEmpty(dropDownOptions)) {
    return (
      <div className="px-4">
        <LogoLink
          communityName={communityName}
          logoType={logoType}
          logoUrl={logoUrl}
          onLogoClick={() => {
            if (shouldDisplayMobileCommunitySwitcher) {
              return mobileCommunitySwitcherModal.show();
            }
            onLogoClick && onLogoClick();
            return undefined;
          }}
        />
      </div>
    );
  }

  return (
    <LogoDropdown dropDownOptions={dropDownOptions}>
      <LogoLink
        communityName={communityName}
        logoType={logoType}
        logoUrl={logoUrl}
        as="div"
      />
    </LogoDropdown>
  );
};

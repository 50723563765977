import { getQueryParam } from "@circle-react/helpers/urlHelpers";

const convertStringToBoolean = (text: string | null) => {
  if (text === "true") return true;
  if (text === "false") return false;
  return undefined;
};

export const useCustomizeCheckout = ({
  isPaywallDigitalWalletEnabledProp,
  paywall,
  price,
}: any) => {
  const isPaywallDigitalWalletEnabledQueryParam = getQueryParam(
    "customize_digital_wallet",
  );

  const customizeAmount = Number(
    getQueryParam("customize_amount") ?? price.amount,
  );
  const stripeOptions = {
    mode: "subscription",
    currency: paywall.currency_code,
    amount: isNaN(customizeAmount) ? price.amount : customizeAmount,
  };

  const isPaywallDigitalWalletEnabled =
    convertStringToBoolean(isPaywallDigitalWalletEnabledQueryParam) ??
    isPaywallDigitalWalletEnabledProp;

  const otherStripeElementsOptions = isPaywallDigitalWalletEnabled
    ? stripeOptions
    : {};

  return {
    isPaywallDigitalWalletEnabled,
    otherStripeElementsOptions,
  };
};

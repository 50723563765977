import { TABS } from "./slices/ui";
import type { LiveStore } from "./useLiveStore";

// Settings
export const selectSettings = (state: LiveStore) => state.settings || {};
export const selectAudioInputId = (state: LiveStore) =>
  state.settings?.audioInputId;
export const selectAudioOutputId = (state: LiveStore) =>
  state.settings?.audioOutputId;
export const selectVideoInputId = (state: LiveStore) =>
  state.settings?.videoInputId;
export const selectBlurEnabled = (state: LiveStore) =>
  state.settings?.blurEnabled;
export const selectMirrorEnabled = (state: LiveStore) =>
  state.settings?.mirrorEnabled;
export const selectMicrophoneEnabled = (state: LiveStore) =>
  state.settings?.microphoneEnabled;
export const selectCameraEnabled = (state: LiveStore) =>
  state.settings?.cameraEnabled;
export const selectNoiseSuppression = (state: LiveStore) =>
  state.settings?.noiseSuppression;
export const selectGridLayoutPageSize = (state: LiveStore) =>
  state.settings?.gridLayoutPageSize;
export const selectSpeakerLayoutPageSize = (state: LiveStore) =>
  state.settings?.speakerLayoutPageSize;

// UI
export const selectUI = (state: LiveStore) => state.ui;
export const selectIsSidebarOpen = (state: LiveStore) =>
  state.ui?.isSidebarOpen;
export const selectIsChatSelected = (state: LiveStore) =>
  state.ui?.currentTab === TABS.CHAT;
export const selectIsParticipantsSelected = (state: LiveStore) =>
  state.ui?.currentTab === TABS.PARTICIPANTS;

// Session
export const selectSession = (state: LiveStore) => state.session || {};
export const selectSpectators = (state: LiveStore) =>
  state.session?.spectators || {};

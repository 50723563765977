import PropTypes from "prop-types";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useMutation } from "react-query";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const CustomDndContext = ({
  sections,
  setSections,
  setActiveDragItem,
  children,
}) => {
  const { data: space } = useCurrentSpaceContext();
  const sensors = useSensors(useSensor(PointerSensor));
  const reorderMutation = useMutation(data =>
    reactQueryPut(internalApi.courses.reorder({ courseId: space.id }), data),
  );

  const syncPositions = () => {
    reorderMutation.mutate({
      new_order: sections.map(section => ({
        section_id: section.id,
        lesson_ids: section.lessons.map(lesson => lesson.id),
      })),
    });
    setActiveDragItem(null);
  };

  const getContainerSortId = item => {
    const itemType = item?.data?.current?.dragType;
    if (itemType === "section") {
      // This is sortId passed to the draggable item
      return item.id;
    }
    return item?.data?.current?.sectionSortId;
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => setActiveDragItem(active)}
      onDragEnd={syncPositions}
      // eslint-disable-next-line sonarjs/cognitive-complexity -- Added before disable comments where obligatory
      onDragOver={({ active, over }) => {
        const activeSortId = active?.id;
        const overSortId = over?.id;
        if (!activeSortId || !overSortId || activeSortId === overSortId) return;

        const activeType = active?.data?.current?.dragType;
        const overType = over?.data?.current?.dragType;
        if (!activeType || !overType) return;

        if (activeType == "section") {
          if (overType == "lesson") return;
          return setSections(currentSections => {
            // Handle reorder of sections
            const oldIndex = currentSections.findIndex(
              section => section.sortId == activeSortId,
            );
            const newIndex = currentSections.findIndex(
              section => section.sortId == overSortId,
            );
            return arrayMove(currentSections, oldIndex, newIndex);
          });
        }
        const overContainerSortId = getContainerSortId(over);
        const activeContainerSortId = getContainerSortId(active);

        const isMovingWithinSameSection =
          activeContainerSortId === overContainerSortId;
        const isMovingToAnotherSection =
          activeContainerSortId !== overContainerSortId;
        // The item being dragged
        const activeItem = sections
          .find(section => section.sortId == activeContainerSortId)
          .lessons.find(lesson => lesson.sortId == activeSortId);
        // Handle reorder of lessons
        return setSections(currentSections =>
          currentSections.map(currentSection => {
            if (
              isMovingWithinSameSection &&
              currentSection.sortId === overContainerSortId
            ) {
              const oldIndex = currentSection.lessons.findIndex(
                lesson => lesson.sortId === activeSortId,
              );
              const newIndex = currentSection.lessons.findIndex(
                lesson => lesson.sortId === overSortId,
              );
              // If moving above the first item in the same section, drop at top
              if (newIndex < 0) return currentSection;
              // If moving within the section, just reorder the lessons in that section
              return {
                ...currentSection,
                lessons: arrayMove(currentSection.lessons, oldIndex, newIndex),
              };
            }
            if (
              isMovingToAnotherSection &&
              currentSection.sortId === activeContainerSortId
            ) {
              // If moving to another section and the item is being dragged out of the section,
              // remove the item from the section
              return {
                ...currentSection,
                lessons: currentSection.lessons.filter(
                  lesson => lesson.sortId !== activeSortId,
                ),
              };
            }
            if (
              isMovingToAnotherSection &&
              currentSection.sortId === overContainerSortId
            ) {
              // If moving to another section and the item is being dragged into the section,
              // add the item to the section
              const afterIndex = currentSection.lessons.findIndex(
                lesson => lesson.sortId === overSortId,
              );
              return {
                ...currentSection,
                lessons: [
                  ...currentSection.lessons.slice(0, afterIndex),
                  activeItem,
                  ...currentSection.lessons.slice(afterIndex),
                ],
              };
            }
            return currentSection;
          }),
        );
      }}
    >
      {children}
    </DndContext>
  );
};

CustomDndContext.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSections: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

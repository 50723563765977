import { useEffect } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Button } from "@circle-react-uikit/Button";
import { HeaderForm } from "./Forms/HeaderForm";
import { PaywallForm } from "./Forms/PaywallForm";
import { PaywallPriceForm } from "./Forms/PaywallPriceForm";
import { ViewPriceToggle } from "./Forms/ViewPriceToggleForm";
import { usePaywallsEditFormContext } from "./PaywallsEditFormProvider";

export const PaywallsEditForm = () => {
  const {
    paywallsListQuery,
    shouldDisableAddPaywall,
    fieldArrayMethods,
    onClickAddPaywall: onClick,
    fieldNameRoot,
  } = usePaywallsEditFormContext();

  const { watch, reset } = useFormContext();
  const paywalls = watch(fieldNameRoot);

  // We need to reset the form state whenever the paywall information is changed,
  // otherwise we would always show the error on the PaywallForm if the form is not valid.
  // We want to show this when it is clicked on the submit button.
  // https://github.com/circleco/circle/pull/21538/files#r1708108748
  useEffect(() => {
    reset(undefined, {
      keepDirty: true,
      keepDirtyValues: true,
      keepValues: true,
      keepDefaultValues: true,
      keepTouched: true,
    });
  }, [paywalls, reset]);

  if (paywallsListQuery.isLoading) {
    return (
      <div className="flex flex-col">
        <ViewPriceToggle />
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-6">
      <ViewPriceToggle />

      <div className="flex flex-col divide-y">
        {fieldArrayMethods.fields &&
          fieldArrayMethods.fields.map((paywall: any, index: number) => (
            <div
              key={paywall._id}
              className="flex flex-col gap-y-4 py-6 first:pt-0 last:pb-0"
            >
              <HeaderForm index={index} />
              <div className={classNames("flex flex-col gap-y-4")}>
                <PaywallForm initialPaywallId={paywall.id} index={index} />
                <PaywallPriceForm index={index} />
              </div>
            </div>
          ))}
      </div>

      <Button
        variant="circle"
        large
        type="button"
        onClick={onClick}
        disabled={shouldDisableAddPaywall}
      >
        {t("paywalls.lock_screen.sidebar.paywall_edit_form.add_paywall")}
      </Button>
    </div>
  );
};

import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { Action } from "../../generated/WorkflowsApi.types";

interface ActionContextValue {
  action: Action;
  index: number;
  isSaved: boolean;
  paramsPath: string;
  path: string;
}

const CurrentActionContext = createContext<ActionContextValue | null>(null);
CurrentActionContext.displayName = "CurrentActionContext";

export const useCurrentAction = (): ActionContextValue => {
  const currentAction = useContext(CurrentActionContext);
  if (!currentAction) {
    throw new Error("useCurrentAction must be used within a Action");
  }
  return currentAction;
};

interface ActionProviderProps {
  action: Action;
  index: number;
  children: ReactNode;
}

export const ActionProvider = ({
  action,
  index,
  children,
}: ActionProviderProps) => {
  const path = `actions.${index}`;
  const paramsPath = `${path}.api_params`;
  const isSaved = Boolean(action?.id);

  return (
    <CurrentActionContext.Provider
      value={{ action, index, isSaved, paramsPath, path }}
    >
      {children}
    </CurrentActionContext.Provider>
  );
};

import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { communitySwitcherPath } from "@circle-react/helpers/urlHelpers";

// From app/views/community_switchers/index.json.jbuilder
export interface CommunitySwitcher {
  brand_color: string;
  light_brand_color: string;
  dark_brand_color: string;
  community_member_id: number;
  dark_icon_url: string | null;
  dark_logo_url: string | null;
  icon_attachment_url: string | null;
  id: number;
  is_current_community: boolean;
  light_icon_url: string | null;
  light_logo_url: string | null;
  logo_attachment_url: string | null;
  logo_image_url: string | null;
  logo_url: string | null;
  name: string;
  root_url: string;
}

export interface CommunitySwitcherData {
  communities: CommunitySwitcher[];
  token: string;
}

const fetchCommunitySwitcher = (): Promise<CommunitySwitcherData> =>
  reactQueryGet(communitySwitcherPath());

const queryKey = ["COMMUNITY_SWITCHER"];

export const useCommunitySwitcher = () => {
  const { data, isLoading, refetch } = useQuery(
    queryKey,
    fetchCommunitySwitcher,
  );

  const token = data?.token || "";
  const communities = data?.communities || [];

  return {
    communities,
    token,
    isLoadingCommunities: isLoading,
    refetchCommunities: refetch,
  };
};

import { t } from "i18n-js";
import { StandardLayoutHeader } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeader";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { usePunditUserContext } from "@circle-react/contexts";
import { useCommunityPaymentProcessorStatus } from "@circle-react/hooks/communityPaymentProcessor/useCommunityPaymentProcessorStatus";
import { PaywallsEmptyState } from "@circle-react-shared/Paywalls/PaywallsEmptyState";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { AnalyticsInfo } from "../AnalyticsInfo";
import { ChargesList } from "./ChargesList";
import { HeaderRightContent } from "./HeaderRightContent";
import { Headline } from "./Headline";
import { Stats } from "./Stats";
import { TabWithFilters } from "./TabWithFilters";
import { TABS } from "./constants";
import { usePaywallChargesAdmin } from "./hooks/usePaywallChargesAdmin";

export const PaywallChargesAdmin = () => {
  const {
    chargesDataByTab,
    activeFilterTab,
    setActiveFilterTab,
    setSearchKey,
    defaultValues,
    refetchAllCharges,
    resetAllChargesToFirstPage,
    showStats,
    isLoadingCharges,
  } = usePaywallChargesAdmin();

  const totalChargesCount = chargesDataByTab[TABS.ALL]?.totalCharges;
  const { currentCommunitySettings } = usePunditUserContext();

  const isPaywallSnapshotsEnabled =
    currentCommunitySettings?.paywall_snapshots_enabled;

  const { isPaymentProcessorEnabled } = useCommunityPaymentProcessorStatus();
  if (isLoadingCharges) {
    return (
      <div className="flex h-full">
        <Loader center />
      </div>
    );
  }

  if (
    !isLoadingCharges &&
    (totalChargesCount === 0 || !isPaymentProcessorEnabled)
  ) {
    return (
      <PaywallsEmptyState
        isPaymentProcessorEnabled={isPaymentProcessorEnabled}
        i18nBase="settings.paywalls_admin_dashboard.charges"
        redirectToPaywalls
      />
    );
  }

  return (
    <Form
      onSubmit={({ searchKey }) => setSearchKey(searchKey)}
      defaultValues={defaultValues}
    >
      <StandardLayoutHeader
        leftContent={
          <SpaceHeaderTitleText>
            {t("settings.paywalls_admin_dashboard.charges.title")}
          </SpaceHeaderTitleText>
        }
        rightContent={
          <HeaderRightContent totalChargesCount={totalChargesCount} />
        }
        filters={
          <TabWithFilters
            setActiveFilterTab={setActiveFilterTab}
            activeFilterTab={activeFilterTab}
            setSearchKey={setSearchKey}
            resetAllChargesToFirstPage={resetAllChargesToFirstPage}
          />
        }
        hasBorder={false}
        headline={
          <>
            {!isPaywallSnapshotsEnabled && <AnalyticsInfo />}
            <Headline totalChargesCount={totalChargesCount} />
          </>
        }
      />

      {isPaywallSnapshotsEnabled &&
        isPaymentProcessorEnabled &&
        showStats() && <Stats />}

      <ChargesList
        chargesData={chargesDataByTab[activeFilterTab]}
        refetchAllCharges={refetchAllCharges}
      />
    </Form>
  );
};

import type { Location } from "history";
import { t } from "i18n-js";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { posts } from "@/react/helpers/urlHelpers";
import { BookmarkButton } from "@circle-react-shared/Bookmarks/BookmarkButton";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Modal, useModalData } from "@circle-react-shared/uikit/ModalV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { PostShareOptions } from "../PostViewListItem/PostShareOptions";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";

interface LocationState {
  prevLocation?: Location;
  shouldFocusOnCommentsSection?: boolean;
}

export interface ModalHeaderProps {
  onChange: () => void;
  spaceId: any;
}

export const ModalHeader = ({ onChange, spaceId }: ModalHeaderProps) => {
  const { post, refetchPostDetails } = useCurrentPostHelpers();
  const queryClient = useQueryClient();
  const { contentPortalElement } = useModalData();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const prevLocation = location?.state?.prevLocation;

  const invalidateSpacePosts = () => {
    void queryClient.invalidateQueries(["posts", spaceId]);
  };

  return (
    <Modal.Header className="flex w-full items-center gap-4">
      <Typography.TitleMd>
        {post.name || t("post.post_modal.title")}
      </Typography.TitleMd>
      <Modal.HeaderActions className="mt-1">
        <div className="flex gap-5">
          <div className="flex gap-2">
            <TippyV2 content={t("post.post_modal.share_post_button_tooltip")}>
              <PostShareOptions
                onChange={() => {
                  onChange();
                  invalidateSpacePosts();
                }}
                appendTo={contentPortalElement ?? document.body}
                menuButtonEl="div"
                button={
                  <IconButton
                    name="20-share"
                    iconSize={20}
                    iconClassName="!text-default group-hover:!text-dark"
                    className="group"
                    buttonSize={24}
                    ariaLabel={t("post.post_modal.share_post_aria_label")}
                  />
                }
                shouldRenderCustomMenuButton
              />
            </TippyV2>
            <BookmarkButton
              bookmarkType="post"
              variant="minimal-post-modal"
              record={post}
              onSuccess={() => {
                refetchPostDetails?.();
                invalidateSpacePosts();
              }}
            />
          </div>
          <div className="bg-overlay-dark w-px" />
          <div className="flex gap-2">
            <TippyV2
              content={t(
                "post.post_modal.open_post_in_full_page_button_tooltip",
              )}
            >
              <IconButton
                name="20-expand"
                iconSize={20}
                iconClassName="!text-default group-hover:!text-dark"
                className="group"
                buttonSize={24}
                onClick={() => {
                  history.push(
                    posts.show({
                      slug: post.slug,
                      spaceSlug: post.space_slug,
                    }),
                  );
                }}
                ariaLabel={t(
                  "post.post_modal.open_post_in_full_page_aria_label",
                )}
              />
            </TippyV2>
            <TippyV2 content={t("post.post_modal.close_post_modal_tooltip")}>
              <IconButton
                name="20-close"
                iconSize={20}
                iconClassName="!text-default group-hover:!text-dark"
                className="group"
                buttonSize={24}
                onClick={() => {
                  if (prevLocation) {
                    history.push(prevLocation);
                  }
                }}
                ariaLabel={t("post.post_modal.close_button_aria_label")}
              />
            </TippyV2>
          </div>
        </div>
      </Modal.HeaderActions>
    </Modal.Header>
  );
};

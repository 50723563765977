import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import { PROFILE_PAGES } from "@/react/helpers/memberHelper";
import { useProfileInfo } from "@/react/hooks/profile/useProfileInfo";

interface AdminMemberEditModalContextValues {
  memberPublicId?: string;
  profileInfoQuery: Partial<UseQueryResult<any, unknown>>;
}

const params = {
  page_name: PROFILE_PAGES.EDIT_PROFILE,
};

const AdminMemberEditModalContext =
  createContext<AdminMemberEditModalContextValues>({
    profileInfoQuery: {
      data: { member: {} },
    },
  });
AdminMemberEditModalContext.displayName = "AdminMemberEditModalContext";

export interface AdminMemberEditModalContextProviderProps {
  children: ReactNode;
}

export const AdminMemberEditModalContextProvider = ({
  children,
}: AdminMemberEditModalContextProviderProps) => {
  const { memberPublicId }: { memberPublicId: string } = useParams();
  const { getProfileInfoQuery: profileInfoQuery } = useProfileInfo({
    memberPublicId,
    params,
  });

  return (
    <AdminMemberEditModalContext.Provider
      value={{ memberPublicId, profileInfoQuery }}
    >
      {children}
    </AdminMemberEditModalContext.Provider>
  );
};

export const useAdminMemberEditModalContext = () =>
  useContext(AdminMemberEditModalContext);

import PropTypes from "prop-types";
import { Content } from "@circle-react/components/Spaces/CourseSpace/Summary/Content";

export const LessonsBlock = ({ isMember = false, sections = [] }) => (
  <div className="bg-primary flex w-full flex-col items-center py-10">
    <div className="w-full max-w-[800px] px-3">
      <Content isMember={isMember} sections={sections} />
    </div>
  </div>
);

LessonsBlock.propTypes = {
  isMember: PropTypes.bool,
  sections: PropTypes.array,
};

import { useQuery } from "react-query";
import { workflowsApi } from "@/react/api";
import { WORKFLOW_STATUS, WORKFLOW_TYPE } from "../constants";

const fetchTotalCount = ({ filters = {} }) =>
  workflowsApi.list({
    params: {
      per_page: 1,
      ...filters,
    },
  });

export const useTotalArchivedWorkflowsCount = () =>
  useQuery("totalArchivedWorkflows", () =>
    fetchTotalCount({
      filters: {
        status: WORKFLOW_STATUS.ARCHIVED,
      },
    }),
  );

export const useTotalAutomationWorkflowsCount = () =>
  useQuery("totalAutomationWorkflows", () =>
    fetchTotalCount({
      filters: {
        workflow_type: WORKFLOW_TYPE.DYNAMIC,
        status: WORKFLOW_STATUS.ALL,
      },
    }),
  );

export const useTotalBulkActionWorkflowsCount = () =>
  useQuery("totalBulkActionWorkflows", () =>
    fetchTotalCount({
      filters: {
        workflow_type: WORKFLOW_TYPE.BULK_ACTION,
        status: WORKFLOW_STATUS.ALL,
      },
    }),
  );

export const useTotalScheduledWorkflowsCount = () =>
  useQuery("totalScheduledWorkflows", () =>
    fetchTotalCount({
      filters: {
        workflow_type: WORKFLOW_TYPE.SCHEDULED,
        status: WORKFLOW_STATUS.ALL,
      },
    }),
  );

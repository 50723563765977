import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";
import { WebhookTestResultDetail } from "./WebhookTestResultDetail";

interface WebhookTestResultContainerProps {
  testResult: {
    request_body: Record<string, unknown>;
    response_body: Record<string, unknown>;
    success: boolean;
    date_time: string;
    url: string;
  };
}

const WebhookTestResultContainer = ({
  testResult,
}: WebhookTestResultContainerProps) => {
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content size="lg">
        <WebhookTestResultDetail testResult={testResult} onClose={modal.hide} />
      </Modal.Content>
    </Modal>
  );
};

const WebhookTestResultModal =
  ModalManager.create<WebhookTestResultContainerProps>(props => (
    <WebhookTestResultContainer {...props} />
  ));

export const useWebhookTestResultModal = () => useModal(WebhookTestResultModal);

import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import {
  WORKFLOW_STATUS,
  WORKFLOW_TABS,
} from "@circle-react/components/SettingsApp/Workflows/constants";
import { serializeObjectToQueryParam } from "@circle-react/helpers/urlHelpers";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { useIsWorkflowExecutionEnabled } from "@circle-react-shared/Workflows/hooks/useIsWorkflowExecutionEnabled";
import {
  useTotalArchivedWorkflowsCount,
  useTotalAutomationWorkflowsCount,
  useTotalBulkActionWorkflowsCount,
  useTotalScheduledWorkflowsCount,
} from "../../hooks/useTotalArchivedWorkflowsCount";
import { useTotalWorkflowsCount } from "../../hooks/useTotalWorkflowsCount";

const TAB_QUERY_PARAMS = {
  WORKFLOW_TYPE: "workflow_type",
  STATUS: "status",
};

export const useWorkflowTabs = () => {
  const isWorkflowExecutionEnabled = useIsWorkflowExecutionEnabled();
  const queryParams = useRouterQueryParams();
  const history = useHistory();
  const [hasArchivedWorkflows, setHasArchivedWorkflows] = useState();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const {
    data: { total_count: totalWorkflows } = {},
    isLoading: isLoadingTotalCount,
  } = useTotalWorkflowsCount();

  const {
    data: { total_count: totalArchivedWorkflows } = {},
    isLoading: isLoadingTotalArchivedCount,
  } = useTotalArchivedWorkflowsCount();

  const { data: { total_count: totalDynamicWorkflows } = {} } =
    useTotalAutomationWorkflowsCount();

  const { data: { total_count: totalBulkActionWorkflows } = {} } =
    useTotalBulkActionWorkflowsCount();

  const { data: { total_count: totalScheduledWorkflows } = {} } =
    useTotalScheduledWorkflowsCount();

  const tabsCountersMap = {
    [WORKFLOW_TABS.DYNAMIC]: totalDynamicWorkflows,
    [WORKFLOW_TABS.BULK_ACTION]: totalBulkActionWorkflows,
    [WORKFLOW_TABS.SCHEDULED]: totalScheduledWorkflows,
    [WORKFLOW_TABS.ARCHIVED]: totalArchivedWorkflows,
  };

  const tabNames = [
    WORKFLOW_TABS.DYNAMIC,
    WORKFLOW_TABS.BULK_ACTION,
    WORKFLOW_TABS.SCHEDULED,
    WORKFLOW_TABS.ARCHIVED,
  ];

  const tabLabels = {
    [WORKFLOW_TABS.DYNAMIC]: t("settings.workflows.table.type.automations"),
    [WORKFLOW_TABS.BULK_ACTION]: t(
      "settings.workflows.table.type.bulk_actions",
    ),
    [WORKFLOW_TABS.SCHEDULED]: t("settings.workflows.table.type.scheduled"),
    [WORKFLOW_TABS.ARCHIVED]: t("settings.workflows.table.type.archived"),
  };

  const { status, workflow_type: workflowType } = queryParams;

  const handleTabChange = index => {
    setSelectedTabIndex(index);
    const isArchivedTab = index === tabNames.length - 1;
    const queryParams = {
      ...(isArchivedTab && {
        [TAB_QUERY_PARAMS.STATUS]: WORKFLOW_STATUS.ARCHIVED,
      }),
      ...(!isArchivedTab && {
        [TAB_QUERY_PARAMS.WORKFLOW_TYPE]: tabNames[index],
      }),
    };
    history.push({
      search: serializeObjectToQueryParam(queryParams),
    });
  };

  useEffect(() => {
    if (isLoadingTotalArchivedCount) {
      return;
    }
    const hasArchived = totalArchivedWorkflows > 0;
    const defaultSelectedTab =
      status === WORKFLOW_STATUS.ARCHIVED && hasArchived
        ? WORKFLOW_TABS.ARCHIVED
        : workflowType || WORKFLOW_TABS.DYNAMIC;

    const defaultSelectedIndex = tabNames.findIndex(
      tab => tab === defaultSelectedTab,
    );

    setHasArchivedWorkflows(hasArchived);
    setSelectedTabIndex(defaultSelectedIndex);
  }, [
    hasArchivedWorkflows,
    status,
    workflowType,
    isLoadingTotalArchivedCount,
    totalArchivedWorkflows,
  ]);

  return {
    isWorkflowExecutionEnabled,
    hasArchivedWorkflows,
    isLoading: isLoadingTotalCount || isLoadingTotalArchivedCount,
    totalWorkflows,
    tabsCountersMap,
    selectedTabIndex,
    handleTabChange,
    tabNames,
    tabLabels,
  };
};

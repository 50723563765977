import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNotificationWebSocket } from "@/react/hooks/notifications/useNotificationWebSocket";
import { useNotificationsApi } from "@/react/hooks/notifications/useNotificationsApi";
import { useChatNotification } from "@circle-react/hooks/chatsV2/useChatNotification";
import type { CommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import { useThemeContext } from "@circle-react/providers";
import { getAutomaticLoginRootUrl } from "../utils";
import { CommunitySectionPresentation } from "./CommunitySectionPresentation";

export interface CommunitySectionProps {
  community: CommunitySwitcher;
  token: string;
  index: number;
}

export const CommunitySection = ({
  community,
  token,
  index,
}: CommunitySectionProps) => {
  const { isDarkAppearance } = useThemeContext();

  const {
    id,
    is_current_community: isCurrentCommunity,
    root_url,
    name,
    brand_color,
    light_brand_color,
    dark_brand_color,
    community_member_id,
    dark_icon_url,
    light_icon_url,
  } = community;

  const darkUrl = dark_icon_url || light_icon_url;
  const lightUrl = light_icon_url || "";
  const url = isDarkAppearance ? darkUrl : lightUrl;

  const {
    newNotificationCount,
    fetchNewNotificationsCount,
    onNotificationEventReceive,
  } = useNotificationsApi();

  const { unreadChatCount } = useChatNotification({
    community: community,
  });

  useEffect(() => {
    void fetchNewNotificationsCount(id);
  }, []);

  useNotificationWebSocket(community_member_id, onNotificationEventReceive);

  useHotkeys(
    `alt+g+${index + 1}`,
    () => {
      window.location.href = getAutomaticLoginRootUrl(root_url, token);
    },
    {
      enabled: index + 1 < 9,
    },
  );

  return (
    <CommunitySectionPresentation
      isActive={isCurrentCommunity}
      href={getAutomaticLoginRootUrl(root_url, token)}
      icon_attachment_url={url}
      includeName={false}
      brand_color={brand_color}
      light_brand_color={light_brand_color}
      dark_brand_color={dark_brand_color}
      name={name}
      notificationCount={
        isCurrentCommunity ? 0 : newNotificationCount + unreadChatCount
      }
      index={index}
    />
  );
};

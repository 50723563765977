import { useEffect } from "react";
import { useLivekitContext } from "../../VideoProvider/LivekitProvider";
import { selectSettings, useLiveStore } from "../../store";

export const useLivekitDevicesListener = isHost => {
  const { room, localParticipant, isConnected } = useLivekitContext();

  const {
    videoInputId,
    audioInputId,
    audioOutputId,
    microphoneEnabled,
    cameraEnabled,
  } = useLiveStore(selectSettings);

  // Device changes
  useEffect(() => {
    (async () => {
      if (videoInputId && isHost && room && isConnected) {
        try {
          await room.switchActiveDevice("videoinput", videoInputId);
        } catch (e) {
          console.error("Could not set video device id: ", e.message);
        }
      }
    })();
  }, [isConnected, isHost, room, videoInputId]);

  useEffect(() => {
    (async () => {
      if (audioInputId && isHost && room && isConnected) {
        try {
          await room.switchActiveDevice("audioinput", audioInputId);
        } catch (e) {
          console.error("Could not set microphone device id: ", e.message);
        }
      }
    })();
  }, [audioInputId, isConnected, isHost, room]);

  useEffect(() => {
    if (audioOutputId && isHost && room && isConnected) {
      (async () => {
        try {
          await room.switchActiveDevice("audiooutput", audioOutputId);
        } catch (e) {
          console.error("Could not set speaker device id: ", e.message);
        }
      })();
    }
  }, [audioOutputId, isConnected, isHost, room]);

  // Toggle for camera and mic
  useEffect(() => {
    (async () => {
      if (isHost && localParticipant && isConnected) {
        try {
          const options = {};
          if (audioInputId) {
            options.deviceId = audioInputId;
          }
          await localParticipant.setMicrophoneEnabled(
            microphoneEnabled,
            options,
          );
        } catch (e) {
          console.error("Could not toggle microphone: ", e.message);
        }
      }
    })();
  }, [isConnected, isHost, localParticipant, microphoneEnabled, audioInputId]);

  useEffect(() => {
    (async () => {
      if (isHost && localParticipant && isConnected) {
        try {
          const options = {};
          if (videoInputId) {
            options.deviceId = videoInputId;
          }
          await localParticipant.setCameraEnabled(cameraEnabled, options);
        } catch (e) {
          console.error("Could not toggle camera: ", e.message);
        }
      }
    })();
  }, [cameraEnabled, isConnected, isHost, localParticipant, videoInputId]);
};

import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useLessonSelector } from "@circle-react/components/SettingsApp/Workflows/hooks/useLessonSelector";
import { FormTextSelect } from "@circle-react/components/shared/uikit/Form/FormTextSelect";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.form";

export const LessonSelector = () => {
  const {
    formState: { touchedFields },
    register,
    getValues,
  } = useFormContext();
  const isResourceTypeTouched = touchedFields.trigger?.resource_type;

  const selectedLessonId = isResourceTypeTouched
    ? null
    : getValues("trigger.resource_id");

  const { lessonOptions, handleLessonTitleChange } = useLessonSelector({
    selectedLessonId,
  });

  return (
    <>
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.resource_type")}
      />
      <FormTextSelect
        name="trigger.resource_id"
        label={
          <Typography.LabelSm weight="semibold">
            {t([i18nRoot, "choose_lesson"])}
          </Typography.LabelSm>
        }
        options={lessonOptions}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        emptyMessage={t("settings.workflows.form.errors.lessons.not_found")}
        onSearchChange={handleLessonTitleChange}
      />
    </>
  );
};

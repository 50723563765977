import PropTypes from "prop-types";
import { Typography } from "@circle-react-uikit/Typography";

export const ButtonContent = ({ title, description }) => (
  <div className="flex w-full flex-col gap-1">
    <div>
      <Typography.LabelSm color="text-default">
        {description}
      </Typography.LabelSm>
    </div>
    <div>
      <Typography.TitleSm weight="semibold">{title}</Typography.TitleSm>
    </div>
  </div>
);

ButtonContent.propTypes = {
  title: PropTypes.ReactNode,
  description: PropTypes.ReactNode,
};

import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { Typography } from "@circle-react-uikit/Typography";
import { AskBotBar } from "../../AskBotBar";
import { PinnedPost } from "../../PinnedPost";
import { SalesAgentButton } from "../../SalesAgentButton";
import { TutorialPostsContainer } from "../../TutorialPostsContainer";
import { useDriftLink } from "../../hooks/useDriftLink";
import circleIcon32 from "./circle-icon-32.png";
import handWaveIcon24 from "./hand-wave-24.png";

export const HomeView = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const { shouldShowDriftLink } = useDriftLink();

  return (
    <>
      <div className="bg-circle h-[220px] rounded-lg  rounded-b-none pb-[140px] pt-7">
        <div className="pl-8">
          <div className="flex flex-row items-center justify-between">
            <img
              loading="lazy"
              src={circleIcon32}
              alt={t("support_widget.circle_logo_alt_text")}
              className="h-10 w-10 rounded-md border-2 dark:border-white"
            />
          </div>
          <div className="mt-4 flex items-center text-white">
            <Typography.TitleMd color="text-white">
              {t("support_widget.greeting", {
                first_name:
                  currentCommunityMember?.first_name ||
                  t("support_widget.default_greeting_name"),
              })}
            </Typography.TitleMd>
            <img
              loading="lazy"
              src={handWaveIcon24}
              alt={t("support_widget.hand_wave_alt_text")}
              className="ml-2 h-6 w-6"
            />
          </div>
          <div className="mt-1">
            <Typography.BodySm color="text-white">
              {t("support_widget.greeting_question_1")}
            </Typography.BodySm>
          </div>
        </div>
      </div>
      <div className="px-4 pb-4">
        <div className="border-primary bg-primary mb-4 mt-[-64px] rounded-lg border pt-4">
          <div className="ml-6">
            <span className="flex items-center gap-3">
              <Typography.LabelMd weight="semibold" color="text-dark">
                {t("support_widget.chatbot.i_need_help")}
              </Typography.LabelMd>
            </span>
          </div>
          <div className="px-6 py-4">
            <AskBotBar />
          </div>
        </div>
        <div className="border-primary bg-primary mb-4 rounded-lg border pt-4">
          <div className="mb-3 ml-6">
            <a
              href="https://community.circle.so/c/tutorials"
              target="_blank"
              rel="noreferrer"
            >
              <Typography.LabelMd
                weight="semibold"
                color="text-dark hover:text-circle"
              >
                {t("support_widget.tutorial_posts")}
              </Typography.LabelMd>
            </a>
          </div>
          <TutorialPostsContainer />
        </div>
        {shouldShowDriftLink && <SalesAgentButton />}
        <PinnedPost />
      </div>
    </>
  );
};

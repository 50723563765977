import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { SEND_TO_WEBHOOK_ELIGIBLE_PLAN_TIERS } from "../../../constants";

export const TitleSendToWebhook = () => {
  const { currentCommunity = {} } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;
  const isEligiblePlanTier =
    SEND_TO_WEBHOOK_ELIGIBLE_PLAN_TIERS.includes(currentPlanTier);

  return (
    <div className="relative w-full">
      {t("settings.workflows.form.actions.send_to_webhook")}
      {!isEligiblePlanTier && (
        <div className="absolute right-0 inline pl-6">
          <BadgeV2 label={t("upgrade")} square />
        </div>
      )}
    </div>
  );
};

import classNames from "classnames";
import { NavLink } from "react-router-dom";
import type { IconType } from "@circle-react/components/shared/Icon";
import { Icon } from "@circle-react/components/shared/Icon";
import { useGetSidebarLinkClass } from "../CommunityMenu/SpaceList/useGetSidebarLinkClass";
import { SidebarBadge } from "./SidebarBadge";

export interface PlatformMenuItemLinkProps {
  badgeText?: string;
  isGrayBadge?: boolean;
  dataTestId?: string;
  iconName: IconType;
  label: string;
  linkTo: string;
  disabled?: boolean;
  isActive?: boolean;
}

export const PlatformMenuItemLink = ({
  badgeText,
  isGrayBadge = false,
  dataTestId,
  iconName,
  label,
  linkTo,
  disabled = false,
  isActive: isActiveProp = false,
}: PlatformMenuItemLinkProps) => {
  const { getActiveInactiveClasses } = useGetSidebarLinkClass();

  return (
    <NavLink
      data-testid={dataTestId}
      to={linkTo}
      className={isActive =>
        classNames(
          "my-px flex w-full cursor-pointer items-center px-2 py-1.5 text-sm",
          getActiveInactiveClasses(isActive || isActiveProp),
        )
      }
      onClick={event => {
        if (disabled) {
          event.preventDefault();
        }
      }}
      title={label}
    >
      <Icon type={iconName} size={16} aria-hidden />
      <div className="ml-2 flex-1">
        <h3 className="max-w-full truncate text-sm font-normal text-current">
          {label}
        </h3>
      </div>
      {badgeText && (
        <SidebarBadge isGray={isGrayBadge}>{badgeText}</SidebarBadge>
      )}
    </NavLink>
  );
};

import { useUpdateBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useUpdateBroadcast";
import { BroadcastDataProvider } from "@circle-react-shared/Emails/BroadcastDataContext";
import { EmailEditor } from "@circle-react-shared/Emails/EmailEditorModal";

interface FormEmailContentProps {
  broadcastId: number;
  isOpen: boolean;
  onToggle: () => void;
}

export function FormEmailContent({
  broadcastId,
  isOpen,
  onToggle,
}: FormEmailContentProps) {
  const updateBroadcastProps = useUpdateBroadcast({
    id: Number(broadcastId),
  });

  return (
    <BroadcastDataProvider id={Number(broadcastId)}>
      <EmailEditor
        isOpen={isOpen}
        update={updateBroadcastProps}
        onClose={onToggle}
      />
    </BroadcastDataProvider>
  );
}

import { useEffect } from "react";
import { usePaywallSubscriptionDetailsModal } from "@circle-react-shared/Paywalls/Modals/PaywallSubscriptionDetailsModal";
import { defaultColumnsToRender } from "@circle-react-shared/Paywalls/PaywallSubscriptionList/constants";

export const usePaywallSubscriptions = ({ subscriptionData }) => {
  useEffect(() => {
    if (
      subscriptionData?.subscriptions?.length === 0 &&
      subscriptionData?.totalSubscriptions > 0
    ) {
      subscriptionData?.setPage(1);
      subscriptionData?.refetchSubscriptions();
    }
  });

  const onClickPagination = currentPage => {
    subscriptionData?.setPage(currentPage);
    subscriptionData?.refetchSubscriptions();
  };

  const subscriptionDetailModal = usePaywallSubscriptionDetailsModal();
  const onClickColumn = cellContext => {
    const {
      cell: {
        column: { id: columnName },
        row: { original: subscription },
      },
    } = cellContext;

    if (columnName !== "customer") {
      void subscriptionDetailModal.show({
        subscription,
        shouldOpenAdminMemberEdit: true,
      });
    }
  };

  const columns = defaultColumnsToRender.map(column => ({
    ...column,
    onClick: onClickColumn,
  }));

  return {
    onClickPagination,
    columnsToRender: columns,
  };
};

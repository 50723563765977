import { useEffect } from "react";
import {
  selectIsLocalAudioEnabled,
  selectIsLocalVideoDisplayEnabled,
  selectLocalPeer,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { t } from "i18n-js";
import { PARTICIPANT_ROLES } from "@circle-react/helpers/liveStreamRoomHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { selectSettings, useLiveStore } from "../../store";
import { useHMSVirtualBackground } from "./useHMSVirtualBackground";

const OUTPUT_DEVICE_ASSIGNMENT_TIMEOUT_MILLISECONDS = 1000;
const OUTPUT_DEVICE_ASSIGNMENT_MAX_RETRIES = 3;

export const useHMSDevicesListener = (isHost: boolean) => {
  const { error } = useToast();
  const isLocalVideoDisplayEnabled = useHMSStore(
    selectIsLocalVideoDisplayEnabled,
  );
  const isLocalAudioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const hmsActions = useHMSActions();
  const {
    videoInputId,
    audioInputId,
    audioOutputId,
    microphoneEnabled: isMicrophoneEnabled,
    cameraEnabled: isCameraEnabled,
    blurEnabled: isBlurEnabled,
  } = useLiveStore(selectSettings);
  const localPeer = useHMSStore(selectLocalPeer);
  const isModerator = localPeer?.roleName === PARTICIPANT_ROLES.MODERATOR;
  const {
    addBlur,
    removeBlur,
    isEnabled: isVirtualBackgroundEnabled,
    errorMessage: virtualBackgroundErrorMessage,
  } = useHMSVirtualBackground();

  // Dummy tracks are added to local peer
  // even if not captured yet, we know audio / video is ready
  // based on dummy tracks being available. 100ms will fix this
  // and we can remove this check afterwards.
  const isLocalAudioReady = !!localPeer?.audioTrack;
  const isLocalVideoReady = !!localPeer?.videoTrack;

  // Device changes
  useEffect(() => {
    void (async () => {
      if (
        videoInputId &&
        isLocalVideoDisplayEnabled &&
        isHost &&
        isLocalVideoReady
      ) {
        try {
          await hmsActions.setVideoSettings({
            deviceId: videoInputId,
          });
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : "";
          console.error("Could not set video device id: ", errorMessage);
        }
      }
    })();
  }, [
    videoInputId,
    isLocalVideoDisplayEnabled,
    isHost,
    hmsActions,
    isLocalVideoReady,
  ]);

  useEffect(() => {
    void (async () => {
      if (audioInputId && isLocalAudioEnabled && isHost && isLocalAudioReady) {
        try {
          await hmsActions.setAudioSettings({
            deviceId: audioInputId,
          });
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : "";
          console.error("Could not set microphone device id: ", errorMessage);
        }
      }
    })();
  }, [
    audioInputId,
    isLocalAudioEnabled,
    isHost,
    hmsActions,
    isLocalAudioReady,
  ]);

  useEffect(() => {
    let retryTimeout: NodeJS.Timeout;
    let retryCount = 0;

    const assignOutputDevice = async () => {
      const canSetOutputDevice = Boolean(
        isHost && audioOutputId && isLocalAudioReady,
      );

      if (!canSetOutputDevice) return;

      try {
        if (audioOutputId) {
          await hmsActions.setAudioOutputDevice(audioOutputId);
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "";
        console.error("Could not set output device id: ", errorMessage);
        if (retryCount < OUTPUT_DEVICE_ASSIGNMENT_MAX_RETRIES) {
          console.error(
            `Retrying output assignment in ${OUTPUT_DEVICE_ASSIGNMENT_TIMEOUT_MILLISECONDS} milliseconds`,
          );
          retryTimeout = setTimeout(() => {
            retryCount += 1;
            void assignOutputDevice();
          }, OUTPUT_DEVICE_ASSIGNMENT_TIMEOUT_MILLISECONDS);
        } else {
          console.error(
            "Max retries for output device assignment reached, could not assign output device.",
          );
        }
      }
    };

    void assignOutputDevice();

    return () => clearTimeout(retryTimeout);
  }, [audioOutputId, hmsActions, isHost, isLocalAudioReady]);

  // Toggle for camera and mic
  useEffect(() => {
    void (async () => {
      if (isHost && isLocalAudioReady) {
        try {
          await hmsActions.setLocalAudioEnabled(isMicrophoneEnabled);
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : "";
          console.error("Could not toggle microphone: ", errorMessage);
        }
      }
    })();
  }, [isMicrophoneEnabled, isHost, isLocalAudioReady, hmsActions]);

  useEffect(() => {
    void (async () => {
      if (isHost && isLocalVideoReady) {
        try {
          await hmsActions.setLocalVideoEnabled(isCameraEnabled);
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : "";
          console.error("Could not toggle camera: ", errorMessage);
        }
      }
    })();
  }, [isCameraEnabled, hmsActions, isHost, isLocalVideoReady]);

  // Mute moderators
  useEffect(() => {
    void (async () => {
      if (isModerator && isLocalAudioReady) {
        try {
          await hmsActions.setLocalAudioEnabled(false);
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : "";
          console.error("Could not mute moderator: ", errorMessage);
        }
      }
    })();
  }, [isModerator, isLocalAudioReady, hmsActions]);

  // Virtual background effects
  useEffect(() => {
    if (isHost && isVirtualBackgroundEnabled) {
      if (isBlurEnabled) {
        addBlur();
      } else {
        removeBlur();
      }
    }
  }, [isBlurEnabled, removeBlur, addBlur, isHost, isVirtualBackgroundEnabled]);

  useEffect(() => {
    if (isBlurEnabled && isLocalVideoReady && virtualBackgroundErrorMessage) {
      error(
        t("live_streams.room.blur_error", {
          message: virtualBackgroundErrorMessage,
        }),
      );
    }
  }, [isBlurEnabled, virtualBackgroundErrorMessage, isLocalVideoReady]);
};

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useConfirmDeleteActionModal } from "@circle-workflows/components/ActionRule/ConfirmDeleteActionModal";
import { useIsWorkflowActiveOrInProgress } from "@circle-workflows/components/EditWorkflowInfoBanner/useIsWorkflowActiveOrInProgress";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useIsWorkflowInDraftMode } from "../../hooks/useIsWorkflowInDraftMode";
import { useWebhookTestManagement } from "../../hooks/useWebhookTestManagement";
import { availableActions } from "../Actions";
import { sendToWebhook } from "../Actions/Webhook";
import { WebhookTestButton } from "../Actions/Webhook/WebhookTestButton";
import { WebhookTestInfo } from "../Actions/Webhook/WebhookTestInfo";
import { ContinueButton } from "../WorkflowForm/ContinueButton";
import { useResourceStatus } from "../WorkflowForm/useResourceStatus";
import { ActionBlock } from "./ActionBlock";
import { useCurrentAction } from "./ActionContext";
import { ActionSelector } from "./ActionSelector";
import { useActions } from "./useActions";

const getTitleByActionType = ({ actionType, apiParams }) => {
  const actionItem = availableActions.find(
    availableAction => availableAction.value === actionType,
  );
  if (actionItem && "getActionTitle" in actionItem) {
    return actionItem.getActionTitle(apiParams);
  }
  return t(`settings.workflows.view.actions.${actionType}.title`, {
    defaultValue: t("settings.workflows.form.add_an_action"),
  });
};

export const Action = ({
  hasBottomConnection = true,
  onAddClick,
  onDeleteClick,
}) => {
  const { saveAction, isUpdatingAction, saveActions, doesActionhasServerId } =
    useActions();
  const { index, action, path } = useCurrentAction();
  const { isCompleted, hasError, statusMessage, revalidate, hasValue } =
    useResourceStatus(path);
  const isWorkflowInDraftMode = useIsWorkflowInDraftMode();
  const { result: isWorkflowActiveOrInProgress } =
    useIsWorkflowActiveOrInProgress();
  const confirmDeleteActionModal = useConfirmDeleteActionModal();
  const [title, setTitle] = useState("");

  const actionType = action?.action_type;

  const buttonMessage = isUpdatingAction
    ? t("settings.workflows.form.saving")
    : !hasValue
    ? t("settings.workflows.form.fill_all_fields_to_continue")
    : t("settings.workflows.form.continue");

  const { getValues } = useFormContext();
  const triggerType = getValues("trigger.trigger_type");

  const requestBody = {
    webhook_test: {
      url: action?.api_params?.url,
      trigger_type: triggerType,
    },
  };

  const {
    isTestingWebhook,
    isWebhookTestSuccessful,
    isWebhookTested,
    isWebhookUrlChanged,
    testWebhookResult,
    handleTestWebhook,
  } = useWebhookTestManagement(action, revalidate);

  useEffect(() => {
    // Update title when selected action type changes
    setTitle(
      getTitleByActionType({
        actionType,
        apiParams: action?.api_params,
      }),
    );
  }, [action?.api_params, actionType]);

  const handleContinue = async () => {
    const isValid = await revalidate();
    if (!isValid) return;
    // While editing workflows we cannot call saveAction since it requires position.
    // Altering position is technically harder to implement and might create side effects on ongoing workflows
    // thus calling saveAction(s)
    if (!doesActionhasServerId(action) && !isWorkflowInDraftMode) {
      await saveActions();
    } else {
      await saveAction({ path, action });
    }

    setTitle(
      getTitleByActionType({
        actionType,
        apiParams: action?.api_params,
      }),
    );
  };

  const handleDeleteClick = async index => {
    if (!isWorkflowActiveOrInProgress) {
      await onDeleteClick(index);
      return;
    }

    await confirmDeleteActionModal.show({
      onConfirm: async () => {
        void confirmDeleteActionModal.hide();
        void onDeleteClick(index);
      },
      onCancel: () => {
        void confirmDeleteActionModal.hide();
      },
    });
  };

  return (
    <ActionBlock
      id={action?.id || index + 1}
      key={action.id}
      actionType={actionType}
      hasBottomConnection={hasBottomConnection}
      onAddClick={async () => {
        const isValid = await revalidate();
        if (!isValid) return;
        onAddClick(index);
      }}
      onDeleteClick={() => {
        void handleDeleteClick(index);
      }}
      hasError={hasError}
      hasSuccess={isCompleted}
      statusMessage={statusMessage}
      title={title}
    >
      <ActionSelector index={index} />
      {isWebhookTested && testWebhookResult && (
        <WebhookTestInfo
          isWebhookTestSuccessful={isWebhookTestSuccessful}
          webhookTestResult={testWebhookResult}
        />
      )}
      {actionType === sendToWebhook.value &&
      (!isWebhookTestSuccessful || isWebhookUrlChanged) ? (
        <WebhookTestButton
          handleTestWebhook={() => handleTestWebhook(requestBody)}
          isDisabled={
            isUpdatingAction ||
            !hasValue ||
            isTestingWebhook ||
            !triggerType ||
            triggerType === ""
          }
          isTestingWebhook={isTestingWebhook}
          isWebhookTestSuccessful={isWebhookTestSuccessful}
          isWebhookUrlChanged={isWebhookUrlChanged}
          isWebhookTested={isWebhookTested}
          triggerType={triggerType}
        />
      ) : (
        <ContinueButton
          disabled={isUpdatingAction || !hasValue}
          onClick={handleContinue}
        >
          {buttonMessage}
        </ContinueButton>
      )}
    </ActionBlock>
  );
};

Action.propTypes = {
  hasBottomConnection: PropTypes.bool,
  onAddClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

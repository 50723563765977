import { createContext, useContext, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RailsFlashMessages } from "@circle-react/components/RailsFlashMessages";
import type { SignInResponse } from "@circle-react/hooks/useVerifyOtp";
import { ThemeProvider, applyV2Colors } from "@circle-react/providers/Theme";
import { AuthenticationRoutes } from "@circle-react/routes/Authentication";
import type { ThemeObject } from "@circle-react/types/Theme";
import { ToastProvider } from "@circle-react-uikit/ToastV2";

const CurrentCommunityContext = createContext<any>({});
CurrentCommunityContext.displayName = "CurrentCommunityContext";

export const useCurrentCommunityContext = () =>
  useContext(CurrentCommunityContext);

const queryClient = new QueryClient();

export interface AuthenticationAppProps {
  alert: string;
  authProviderAuthorizationUrl: string;
  authProviderName: string;
  authProviderSignInWithCircleLinkLabel: string;
  brandColor: any;
  brandTextColor: any;
  can_current_community_support_custom_login: boolean;
  communityThemeSettings: any;
  current_user: any;
  currentCommunity: any;
  currentCommunityPolicies: any;
  errors: any;
  invitationToken: string;
  is_signup_password_matching: boolean;
  notice: string;
  promotion_details: any;
  suggestedLanguage: string;
  signUpOptions: any;
  user_email: string;
}

// Props come from app/views/shared/_authentication_app.html.erb
export const AuthenticationApp = ({
  currentCommunity,
  currentCommunityPolicies,
  communityThemeSettings = {},
  brandColor = {
    light: "",
    dark: "",
  },
  brandTextColor = {
    light: "",
    dark: "",
  },
  invitationToken,
  signUpOptions,
  notice,
  alert,
  errors,
  user_email: userEmail,
  current_user: currentUser,
  is_signup_password_matching: isSignupPasswordMatching,
  can_current_community_support_custom_login:
    canCurrentCommunitySupportCustomLogin,
  authProviderAuthorizationUrl,
  authProviderName,
  authProviderSignInWithCircleLinkLabel,
  promotion_details,
  suggestedLanguage,
}: AuthenticationAppProps) => {
  const theme: ThemeObject = {
    ...communityThemeSettings,
    brand_color: brandColor,
    brand_text_color: brandTextColor,
    current_theme: "default",
    is_custom: false,
  };

  applyV2Colors();

  const [otpSignInResponse, setOtpSignInResponse] =
    useState<SignInResponse | null>(null);

  return (
    <QueryClientProvider client={queryClient}>
      <CurrentCommunityContext.Provider
        value={{
          currentCommunity,
          currentCommunityPolicies,
          invitationToken,
          signUpOptions,
          errors,
          currentUser,
          userEmail,
          isSignupPasswordMatching,
          canCurrentCommunitySupportCustomLogin,
          authProviderAuthorizationUrl,
          authProviderName,
          suggestedLanguage,
          authProviderSignInWithCircleLinkLabel,
          promotion_details,
        }}
      >
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <RailsFlashMessages notice={notice} alert={alert} />
            <AuthenticationRoutes
              hasEmail={Boolean(errors?.email && userEmail)}
              otpSignInResponse={otpSignInResponse}
              setOtpSignInResponse={setOtpSignInResponse}
            />
          </ToastProvider>
        </ThemeProvider>
      </CurrentCommunityContext.Provider>
    </QueryClientProvider>
  );
};

import { useFormContext } from "react-hook-form";
import { BROADCAST_TYPES } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/constants";
import { useBroadcastCreate } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/useBroadcastCreate";
import { useGetBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import { useUpdateBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useUpdateBroadcast";
import { useCurrentAction } from "../../../ActionRule";

export const useUpsertBroadcast = (existingBroadcastId: number | null) => {
  const { paramsPath } = useCurrentAction();
  const { getValues, setValue } = useFormContext();
  const { data, mutateAsync: createBroadcast } = useBroadcastCreate();
  const broadcastId = existingBroadcastId ?? data?.id;
  useGetBroadcast({
    /** @todo Hack to pass in ID here. We will call this only when ID is available */
    id: existingBroadcastId ?? 0,
    enabled: !!existingBroadcastId,
    onSuccess: data => {
      setValue(`${paramsPath}.internal_name`, data.internal_name);
      setValue(`${paramsPath}.subject`, data.broadcast_content?.subject);
      setValue(
        `${paramsPath}.preview_text`,
        data.broadcast_content?.preview_text,
      );
      setValue(
        `${paramsPath}.email_content`,
        data.broadcast_content?.email_content,
      );
    },
  });

  /** @todo Hack to pass in ID here. We will call this only when ID is available */
  const { mutateAsync: updateBroadcast } = useUpdateBroadcast({
    id: broadcastId ?? 0,
  });

  const handleUpsert = () => {
    const [internalName, subject, previewText] = getValues([
      `${paramsPath}.internal_name`,
      `${paramsPath}.subject`,
      `${paramsPath}.preview_text`,
    ]);
    const payload = {
      internal_name: internalName,
      broadcast_type: BROADCAST_TYPES.workflow,
      broadcast_content_attributes: {
        subject,
        preview_text: previewText,
      },
    } as const;
    if (broadcastId) {
      return updateBroadcast({
        body: payload,
      });
    }
    /* Currently supports only internal_name */
    return createBroadcast(payload);
  };

  return {
    onUpsert: handleUpsert,
    broadcastId,
  };
};

import PropTypes from "prop-types";
import { t } from "i18n-js";
import { formatDate } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/Cells";

export const Datetime = ({ date, shouldIncludeTime }) => {
  const dateFormatWithTime = `MMM dd, yyyy '${t("time_format.at")}' HH:mm`;

  return (
    <span className="break-normal">
      {shouldIncludeTime
        ? formatDate(date, dateFormatWithTime)
        : formatDate(date)}
    </span>
  );
};

Datetime.propTypes = {
  date: PropTypes.string,
  shouldIncludeTime: PropTypes.bool,
};

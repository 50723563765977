import { useEffect, useMemo } from "react";
import { useLiveStreamContextActions } from "@circle-react/contexts/LiveStreams";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";
import { selectSpectators, useLiveStore } from "../store";
import { useProviderSpectators } from "./provider/useProviderSpectators";

const REFETCH_DEBOUNCE_MILLISECONDS = 1000;
const SPECTATOR_POLL_INTERVAL = 3000;

export const useParticipantsWatch = hosts => {
  const {
    peers: providerSpectators,
    loadPeers: providerLoadFn,
    loadMorePeers: providerLoadMoreFn,
    hasNext,
    total,
  } = useProviderSpectators();
  const {
    setLoadFn,
    load,
    loadFn,
    setHasNext,
    setLoadMoreFn,
    setTotal,
    setData,
    data: spectators,
    shouldPoll,
  } = useLiveStore(selectSpectators);
  const peers = useMemo(
    () => [...(hosts || []), ...(spectators || [])],
    [hosts, spectators],
  );
  const { refetchParticipants } = useLiveStreamContextActions();
  const strPeerIds = peers?.reduce(
    (prevValue, peer) => `${prevValue}-${peer.id}-${peer.roleName}`,
    "",
  );
  const debouncedStrPeerIds = useDebouncedValue(
    strPeerIds,
    REFETCH_DEBOUNCE_MILLISECONDS,
  );

  useEffect(() => {
    if (debouncedStrPeerIds?.length) {
      refetchParticipants();
    }
  }, [debouncedStrPeerIds, refetchParticipants]);

  // Setup spectators polling and store the functions in the store
  // We are using this hook as a proxy between usePaginatedParticipants and the store
  useEffect(() => {
    setLoadFn(providerLoadFn);
    setLoadMoreFn(providerLoadMoreFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- 100ms sdk is not defining these functions as callbacks
  }, []);

  useEffect(() => {
    setTotal(total);
  }, [setTotal, total]);

  // Temporary workaround for detecting changes in spectators
  const concatenatedSpectatorIds = providerSpectators?.reduce(
    (prevValue, peer) => `${prevValue}-${peer.id}-${peer.roleName}-`,
    "",
  );
  useEffect(() => {
    setData(providerSpectators);
    setHasNext(hasNext());
    // eslint-disable-next-line react-hooks/exhaustive-deps -- 100ms sdk is not defining spectators as memo and it's always changing
  }, [concatenatedSpectatorIds]);

  // Temporary workaround for detecting changes in load fn
  const isLoadFnSet = !!loadFn;
  useEffect(() => {
    if (!isLoadFnSet) {
      return;
    }
    load();
    if (!shouldPoll) {
      return;
    }
    const interval = setInterval(() => {
      load();
    }, SPECTATOR_POLL_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [load, isLoadFnSet, shouldPoll]);
};

import type { ComponentPropsWithoutRef } from "react";
import { t } from "i18n-js";
import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import type { ShowBroadcastParams } from "@circle-react/api/broadcastsApi";
import { broadcastsApi } from "@circle-react/api/broadcastsApi";
import type { BroadcastStatusValue } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/constants";
import type {
  BaseFilter,
  ProfileFieldBaseFilter,
} from "@circle-react-shared/MemberFilters/types";
import type { TipTapBlockEditor } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

type TipTapBlockEditorProps = ComponentPropsWithoutRef<
  typeof TipTapBlockEditor
>;

export interface BroadcastContentAttributes {
  subject: string;
  preview_text: string;
  email_content_html: string;
  email_content: {
    body: TipTapBlockEditorProps["value"];
    sgids_to_object_map: TipTapBlockEditorProps["sgidToObjectMap"];
    inline_attachments: TipTapBlockEditorProps["inlineAttachments"];
  };
}

export type RuleType = "all_members" | "custom_filters" | "selected_members";

export interface BroadcastAudienceAttributes {
  id: number;
  filter_rules: {
    rule_type: RuleType;
    rules: Array<BaseFilter | ProfileFieldBaseFilter>;
  };
  audience_count: number;
}

export type ScheduleOptions = "send_now" | "schedule_for_later";

export interface BroadcastBuilderFormFields {
  id: number;
  status: BroadcastStatusValue;
  internal_name: string;
  broadcast_content?: BroadcastContentAttributes;
  broadcast_audience?: BroadcastAudienceAttributes;
  scheduled_at: string | null;
  send_type: ScheduleOptions | "";
}

interface ResponseData extends BroadcastBuilderFormFields {
  id: number;
  status: BroadcastStatusValue;
}

export const getBroadcastQueryKey = (id: number) => ["broadcasts", "show", id];

export const useGetBroadcast = ({
  id,
  ...queryOptions
}: ShowBroadcastParams & UseQueryOptions<ResponseData, unknown>) => {
  const toastr = useToast();

  return useQuery<ResponseData>({
    queryKey: getBroadcastQueryKey(id),
    queryFn: () => broadcastsApi.show({ id }),
    onError: () => toastr.error(t("error")),
    ...queryOptions,
  });
};

import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { CommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import { CommunitiesModal } from "./Modal";

export interface MyCommunitiesModalProps {
  communities: CommunitySwitcher[];
  token: string;
}

const MyCommunitiesModal = ModalManager.create(
  ({ communities, token }: MyCommunitiesModalProps) => (
    <CommunitiesModal communities={communities} token={token} />
  ),
);

export const useSeeMyCommunitiesModal = () => useModal(MyCommunitiesModal);

import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows";

export const Header = ({
  title,
  showAddNewButton = true,
  showBottomBorder = true,
}) => {
  const history = useHistory();

  const navigateToNewWorkflows = () => {
    history.push(workflowsSettingsPaths.new());
  };

  return (
    <div
      className={classNames(
        "bg-primary border-primary flex h-16 w-full items-center justify-between px-6 py-4",
        {
          "border-primary border-b": showBottomBorder,
        },
      )}
    >
      <Typography.TitleSm weight="semibold">{title}</Typography.TitleSm>
      {showAddNewButton && (
        <Button onClick={navigateToNewWorkflows} variant="circle">
          {t([i18nRoot, "new_workflow"])}
        </Button>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  showAddNewButton: PropTypes.bool,
  showBottomBorder: PropTypes.bool,
};

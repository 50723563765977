import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallsEditFormContext } from "../PaywallsEditFormProvider";

interface HeaderFormProp {
  index: number;
}

export const HeaderForm = ({ index }: HeaderFormProp) => {
  const { setValue, watch, unregister } = useFormContext();
  const { fieldArrayMethods, fieldNameRoot } = usePaywallsEditFormContext();
  const isPaywallHighlighted = watch(
    `${fieldNameRoot}.${index}.paywall_highlighted`,
  );
  const isValidToRemove = fieldArrayMethods.fields.length > 1;
  const hasActions = !isPaywallHighlighted || isValidToRemove;

  const removePaywallOnClick = () => {
    unregister(`${fieldNameRoot}.${index}.id`);
    fieldArrayMethods.remove(index);
  };

  const highlightPriceOnClick = () => {
    fieldArrayMethods.fields.forEach((_: any, paywallIndex: number) => {
      setValue(
        `${fieldNameRoot}.${paywallIndex}.paywall_highlighted`,
        paywallIndex === index,
      );
    });
  };

  const removeHighlightPriceOnClick = () => {
    setValue(`${fieldNameRoot}.${index}.paywall_highlighted`, false);
  };

  return (
    <div className="flex flex-row justify-between">
      <Typography.LabelLg weight="semibold">
        {t("paywalls.lock_screen.sidebar.paywall_edit_form.paywall_title", {
          index: index + 1,
        })}
      </Typography.LabelLg>
      {hasActions && (
        <Dropdown
          button={
            <IconButton
              name="16-menu-dots-horizontal"
              iconClassName="!text-dark"
              ariaLabel={t("community_member.actions")}
            />
          }
        >
          {isPaywallHighlighted && (
            <Dropdown.ItemWithLink
              onClick={removeHighlightPriceOnClick}
              linkType="button"
            >
              {t(
                "paywalls.lock_screen.sidebar.paywall_edit_form.remove_highlight",
              )}
            </Dropdown.ItemWithLink>
          )}
          {!isPaywallHighlighted && (
            <Dropdown.ItemWithLink
              onClick={highlightPriceOnClick}
              linkType="button"
            >
              {t(
                "paywalls.lock_screen.sidebar.paywall_edit_form.highlight_price",
              )}
            </Dropdown.ItemWithLink>
          )}
          {isValidToRemove && (
            <Dropdown.ItemWithLink
              onClick={removePaywallOnClick}
              linkType="button"
              hasDanger
            >
              {t("delete")}
            </Dropdown.ItemWithLink>
          )}
        </Dropdown>
      )}
    </div>
  );
};

import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { usePaywallOptions } from "./usePaywallOptions";
import { usePaywallPriceOptions } from "./usePaywallPriceOptions";

export const usePaywallForm = (blockDataPath: string) => {
  const fieldNameRoot = `${blockDataPath}.paywalls`;
  const fieldArrayMethods = useFieldArray({
    name: fieldNameRoot,
    keyName: "_id",
  });

  const { watch } = useFormContext();

  useEffect(() => {
    if (fieldArrayMethods.fields.length === 0) {
      fieldArrayMethods.append({ id: undefined, paywall_highlighted: false });
    }
  }, [fieldArrayMethods.fields.length]);

  const { paywallsListQuery, allPaywallOptions } = usePaywallOptions();

  const {
    allPaywallPrices,
    allInAppPaywallPriceOptions,
    allWebPaywallPriceOptions,
  } = usePaywallPriceOptions(paywallsListQuery);

  const selectedPaywalls = watch(fieldNameRoot);
  const selectedPaywallIds = selectedPaywalls.map((paywall: any) => paywall.id);

  const onClickAddPaywall = () => {
    fieldArrayMethods.append({ id: undefined, paywall_highlighted: false });
  };

  const shouldDisableAddPaywall =
    allPaywallOptions.filter(
      (paywall: any) => !selectedPaywallIds.includes(paywall.value),
    ).length === 0
      ? true
      : selectedPaywalls.some(
          (paywall: any) =>
            !paywall.id || !paywall?.web_paywall_price?.processor_id,
        );

  return {
    fieldArrayMethods,

    paywallsListQuery,
    selectedPaywallIds,
    allPaywallOptions,
    allPaywallPrices,
    allInAppPaywallPriceOptions,
    allWebPaywallPriceOptions,

    shouldDisableAddPaywall,
    onClickAddPaywall,

    fieldNameRoot,
  };
};

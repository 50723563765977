import type { PropsWithChildren } from "react";
import type { Workflow } from "../../generated/WorkflowsApi.types";
import { Form } from "./Form";
import { WorkflowFormProvider } from "./WorkflowFormProvider";

interface WorkflowFormProps {
  workflow: Workflow;
  formRef?: React.Ref<HTMLFormElement>;
  isRerun?: boolean;
}

export const WorkflowForm = ({
  workflow,
  formRef,
  children,
  isRerun,
}: PropsWithChildren<WorkflowFormProps>) => (
  <WorkflowFormProvider workflow={workflow}>
    <Form ref={formRef} isRerun={isRerun}>
      {children}
    </Form>
  </WorkflowFormProvider>
);

import { useState } from "react";
import type { RerunFormData } from "@circle-workflows/hooks/useRerunWorkflow";
import { endOfDay } from "date-fns";
import { useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import invariant from "tiny-invariant";
import { workflowsApi } from "@/react/api";
import { t } from "@circle-react/custom_i18n";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { BigLoader } from "@circle-react-shared/BigLoader";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ConfirmSchedule } from "./ConfirmSchedule";
import type { ScheduleFormData } from "./ModalCreateSchedule";
import { ModalCreateSchedule } from "./ModalCreateSchedule";
import { ModalRerun } from "./ModalRerun";

interface RecurringScheduleProps {
  workflowId: string;
  onCancel: () => void;
  schedule: {
    isReschedule: boolean;
    first_run_at: string;
    last_run_at: string;
    next_run_at: string;
    frequency: string;
    days_of_week: string;
    do_not_skip_tasks: boolean;
    has_run_but_not_completed: boolean;
    id: string;
  };
  onPublish: () => void;
  isLoading?: boolean;
}

export function RecurringSchedule({
  workflowId,
  onCancel,
  schedule,
  onPublish,
  isLoading,
}: RecurringScheduleProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const workflowFormContext = useFormContext();
  const { id, isReschedule } = schedule;
  const [isRerun, setIsRerun] = useState(false);
  const [formData, setFormData] = useState<ScheduleFormData | null>(null);
  const [rerunParams, setRerunParams] = useState<RerunFormData | null>(null);
  const [isConfirm, toggleIsConfirm] = useBoolean(false);

  const upsertRecurringSchedule = (workflowId: string, data: any) => {
    const lastRunAt = data.last_run_at
      ? endOfDay(new Date(data.last_run_at)).toISOString()
      : null;
    const firstRunAt = new Date(data.first_run_at).toISOString();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { frequency, days_of_week, do_not_skip_tasks } = data;

    if (id) {
      if (isReschedule) {
        return workflowsApi.reschedule({
          workflowId,
          id,
          body: {
            last_run_at: lastRunAt,
            first_run_at: firstRunAt,
            frequency,
            days_of_week,
            schedule_type: "recurring",
            do_not_skip_tasks,
            tz,
          },
        });
      }

      return workflowsApi.updateRecurringSchedule({
        workflowId,
        id,
        body: {
          last_run_at: lastRunAt,
          first_run_at: firstRunAt,
          frequency,
          days_of_week,
          do_not_skip_tasks,
          tz,
        },
      });
    }

    return workflowsApi.createRecurringSchedule({
      workflowId,
      body: {
        last_run_at: lastRunAt,
        first_run_at: firstRunAt,
        frequency,
        days_of_week,
        do_not_skip_tasks,
        tz,
      },
    });
  };

  const { mutate: callRecurringSchedule } = useMutation(
    (data: RerunFormData & ScheduleFormData) =>
      upsertRecurringSchedule(workflowId, data),
    {
      onSuccess: data => {
        toggleIsConfirm();
        workflowFormContext.setValue("schedule", data);
        void queryClient.invalidateQueries(["workflows", workflowId]);
      },
      onError: (error: Error) => {
        if ("message" in error) {
          toast.error(error?.message);
        }
      },
    },
  );

  const handleCreateScheduleSubmit = (data: ScheduleFormData) => {
    setFormData(data);
    setIsRerun(true);
  };

  const handleRerunSubmit = (data: RerunFormData) => {
    setRerunParams(data);
    invariant(formData, "formData should be defined on rerun submit");
    callRecurringSchedule({ ...formData, ...data });
  };

  if (isLoading) {
    return (
      <Modal.Content>
        <Modal.Body className="w-full !px-10 !py-16">
          <BigLoader
            title={t("settings.workflows.publishing_your_workflow")}
            subtitle={t("settings.workflows.we_are_preparing")}
          />
        </Modal.Body>
      </Modal.Content>
    );
  }

  if (isConfirm && rerunParams && formData) {
    return (
      <ConfirmSchedule
        onCancel={onCancel}
        onBack={toggleIsConfirm}
        onPublish={onPublish}
        data={{ ...formData, ...rerunParams }}
      />
    );
  }

  if (isRerun && formData) {
    return (
      <ModalRerun
        doNotSkipTasks={
          rerunParams?.do_not_skip_tasks ??
          schedule.do_not_skip_tasks?.toString() ??
          "false"
        }
        onCancel={onCancel}
        onSubmit={handleRerunSubmit}
        onBack={() => {
          setIsRerun(false);
          setRerunParams(null);
        }}
      />
    );
  }

  return (
    <ModalCreateSchedule
      schedule={schedule}
      formState={formData}
      onCancel={onCancel}
      onSubmit={handleCreateScheduleSubmit}
    />
  );
}

import { StandardLayoutHeaderWithPortal } from "@circle-react/components/Layout/StandardLayoutV2/StandardLayoutHeader/StandardLayoutHeaderWithPortal";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { t } from "@circle-react/custom_i18n";

export const LeaderboardHeader = () => (
  <StandardLayoutHeaderWithPortal
    leftContent={
      <SpaceHeaderTitleText>{t("leaderboard.title")}</SpaceHeaderTitleText>
    }
  />
);

import type { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { CheckoutFormFieldWrapper } from "../CheckoutFormFieldWrapper";
import { CheckoutFormCouponBadge } from "./CheckoutFormCouponBadge";
import { CheckoutFormCouponForm } from "./CheckoutFormCouponForm";

export const CheckoutFormCouponField = () => {
  const { watch } = useFormContext();
  const { areCouponsEnabled } = usePaywallCheckoutContext();

  if (!areCouponsEnabled) {
    return null;
  }

  const { coupon_code_applied } = watch();

  let content: ReactNode;
  if (coupon_code_applied) {
    content = <CheckoutFormCouponBadge />;
  } else {
    content = <CheckoutFormCouponForm />;
  }

  return (
    <CheckoutFormFieldWrapper name="coupon_code" optional>
      {content}
    </CheckoutFormFieldWrapper>
  );
};

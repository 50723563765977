import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { Filter, RadioFilter, useFilterUrl } from "@circle-react-shared/Filter";

const localeNamespace = "settings.workflows";

const statusOptions = [
  { label: t([localeNamespace, "table.result.success"]), value: "success" },
  { label: t([localeNamespace, "table.result.failed"]), value: "failed" },
  {
    label: t([localeNamespace, "table.result.partial_success"]),
    value: "partial_success",
  },
  {
    label: t([localeNamespace, "table.result.skipped"]),
    value: "skipped",
  },
  {
    label: t([localeNamespace, "table.result.in_progress"]),
    value: "in_progress",
  },
  {
    label: t([localeNamespace, "table.result.duplicate_skipped"]),
    value: "duplicate_skipped",
  },
  {
    label: t([localeNamespace, "table.result.cancelled"]),
    value: "cancelled",
  },
];

export const StatusFilter = ({ onApply }) => {
  const { queryParams, updateUrl } = useFilterUrl();
  const getSelectedText = ({ selectedText: selectedStatus }) => {
    if (!selectedStatus) {
      return null;
    }
    const selectedOption = statusOptions.find(
      statusOption => statusOption.value === selectedStatus,
    );
    return selectedOption?.label ?? selectedStatus;
  };

  return (
    <Filter
      chip={t([localeNamespace, "history.filters.status"])}
      title={t([localeNamespace, "history.filters.status"])}
      selectedText={queryParams.status}
      renderSelectedText={getSelectedText}
      onApply={value => {
        updateUrl("status", value);
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
    >
      <RadioFilter
        options={statusOptions}
        legend={t([localeNamespace, "history.filters.status_legend"])}
        name="status-filter"
      />
    </Filter>
  );
};

StatusFilter.propTypes = {
  onApply: PropTypes.func,
};

import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useHistory } from "react-router-dom";
import { useLargeScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { DMsDropdown } from "@circle-react/components/Chat/MessagingV2/DMs/DMsDropdown";
import { NotificationsList } from "@circle-react/components/Notifications/NotificationsList";
import { memberDirectoryPath } from "@circle-react/helpers/routerPathHelpers";
import {
  bookmarksPath,
  chatRoomsPath,
  chatThreadsPath,
  getRouteWithPrevLocation,
  notificationsPath,
} from "@circle-react/helpers/urlHelpers";
import { useDmsV3Enabled } from "@circle-react/hooks/useDmsV3Enabled";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import { BookmarksList } from "@circle-react-shared/uikit/HeaderV3/RightActionBlock/Bookmarks/BookmarksList";
import { PopoverPortal } from "@circle-react-uikit/PopoverPortal";
import { PlatformMenuItem } from "./PlatformMenuItem";
import { PlatformMenuItemLink } from "./PlatformMenuItemLink";

export interface PlatformMenuViewProps {
  allDMsBadgeText?: string;
  hasCurrentCommunityMember?: boolean;
  isHomePageEnabled?: boolean;
  isMessagingEnabled?: boolean;
  isOnboardingActive?: boolean;
  notificationBadgeText?: string;
  onGettingStartedClick?: () => void;
  onGettingStartedClose?: () => void;
  onSearchClick?: () => void;
  shouldShowMemberDirectory?: boolean;
  shouldShowOnboarding?: boolean;
  shouldShowSpaceDirectory?: boolean;
  shouldShowCoursesDirectory?: boolean;
  onNotificationClick?: () => void;
  resetNewNotificationsCount?: () => void;
  disabled?: boolean;
  isActive?: boolean;
  shouldShowChatThreadsTab?: boolean;
  shouldShowActiveHomeLink?: boolean;
  unreadThreadsCount?: number;
  shouldShowAllEventsPage?: boolean;
  shouldShowLeaderboard?: boolean;
}

export const PlatformMenuView = ({
  allDMsBadgeText = "",
  hasCurrentCommunityMember = false,
  isHomePageEnabled = false,
  isMessagingEnabled = false,
  isOnboardingActive = false,
  notificationBadgeText = "",
  onGettingStartedClick,
  onGettingStartedClose,
  onSearchClick,
  shouldShowMemberDirectory = false,
  shouldShowOnboarding = false,
  shouldShowSpaceDirectory = false,
  shouldShowCoursesDirectory = false,
  onNotificationClick,
  resetNewNotificationsCount,
  disabled = false,
  shouldShowChatThreadsTab = false,
  shouldShowActiveHomeLink = false,
  unreadThreadsCount,
  shouldShowAllEventsPage = false,
  shouldShowLeaderboard = false,
}: PlatformMenuViewProps) => {
  const history = useHistory();
  const isLgScreen = useLargeScreenMediaQuery();
  const isMessagingActive = history.location.pathname.includes(chatRoomsPath());
  const isChatThreadsActive = history.location.pathname.includes(
    chatThreadsPath(),
  );
  const isNotificationsActive = history.location.pathname.includes(
    notificationsPath(),
  );
  const isBookmarksActive = history.location.pathname.includes(bookmarksPath());
  const { isDmsV3Enabled } = useDmsV3Enabled();
  const isLgScreenTw = useTailwindLgScreenMediaQuery();

  const shouldRenderChatThreadsPlatformMenuItem = !isLgScreenTw;
  return (
    <div className="mt-2.5 px-3">
      {!isLgScreenTw && (
        <PlatformMenuItem
          label={t("search_item")}
          onClick={() => isFunction(onSearchClick) && onSearchClick()}
          iconName="16-search"
        />
      )}
      {shouldShowOnboarding && (
        <PlatformMenuItem
          label={t("getting_started")}
          iconName="lightning"
          isActive={isOnboardingActive}
          onClick={() => {
            if (isFunction(onGettingStartedClick) && !disabled) {
              onGettingStartedClick();
            }
          }}
          rightSideContent={
            <button
              type="button"
              className="flex h-5 w-5 rounded py-0.5 transition-colors hover:bg-black/10 focus:bg-black/10"
              onClick={() => {
                if (isFunction(onGettingStartedClose) && !disabled) {
                  onGettingStartedClose();
                }
              }}
              aria-label={t("close")}
            >
              <Icon type="16-close" size={16} />
            </button>
          }
        />
      )}
      {isHomePageEnabled && (
        <PlatformMenuItemLink
          label={t("home_page.title")}
          linkTo="/home"
          iconName="home-v2"
          disabled={disabled}
          isActive={shouldShowActiveHomeLink}
        />
      )}
      {hasCurrentCommunityMember && (
        <>
          {!isLgScreenTw && (
            <PopoverPortal
              button={
                <PlatformMenuItem
                  dataTestId="notifications-menu"
                  label={t("notifications.page_title")}
                  iconName="sidebar-bell"
                  badgeText={notificationBadgeText}
                  ariaLabel={t("community_sidebar.unread_notifications", {
                    count: Number(notificationBadgeText),
                  })}
                  onClick={() => {
                    if (
                      isLgScreen &&
                      isFunction(onNotificationClick) &&
                      !disabled
                    ) {
                      onNotificationClick();
                    }
                  }}
                  isActive={isNotificationsActive}
                />
              }
              onOpen={resetNewNotificationsCount}
              disabled={disabled || !isFunction(onNotificationClick)}
            >
              <div
                className="flex h-full w-full flex-col"
                data-testid="notifications-drawer"
              >
                <NotificationsList containerElement="dropdown" isDropdown />
              </div>
            </PopoverPortal>
          )}
          {isMessagingEnabled &&
            !isLgScreenTw &&
            (isDmsV3Enabled ? (
              <PlatformMenuItem
                dataTestId="chat-menu"
                label={t("sidebar_all_dms")}
                iconName="16-messages"
                badgeText={allDMsBadgeText}
                ariaLabel={t("community_sidebar.unread_messages", {
                  count: Number(allDMsBadgeText),
                })}
                onClick={() =>
                  history.push(
                    getRouteWithPrevLocation({ url: chatRoomsPath() }),
                  )
                }
                isActive={isMessagingActive}
              />
            ) : (
              <PopoverPortal
                button={
                  <PlatformMenuItem
                    dataTestId="chat-menu"
                    label={t("sidebar_all_dms")}
                    iconName="16-messages"
                    badgeText={allDMsBadgeText}
                    ariaLabel={t("community_sidebar.unread_messages", {
                      count: Number(allDMsBadgeText),
                    })}
                    onClick={() => isLgScreen && history.push(chatRoomsPath())}
                    isActive={isMessagingActive}
                  />
                }
                disabled={disabled}
              >
                <DMsDropdown />
              </PopoverPortal>
            ))}
          {shouldShowChatThreadsTab &&
            shouldRenderChatThreadsPlatformMenuItem && (
              <PlatformMenuItem
                dataTestId="chat-threads-menu"
                label={t("chat_threads")}
                iconName="16-chat-threads"
                onClick={() => history.push(chatThreadsPath())}
                isActive={isChatThreadsActive}
                badgeText={String(unreadThreadsCount)}
                ariaLabel={t("community_sidebar.unread_messages", {
                  count: Number(unreadThreadsCount),
                })}
              />
            )}
          {isLgScreen && (
            <PopoverPortal
              button={
                <PlatformMenuItem
                  dataTestId="bookmarks-menu"
                  label={t("header_v3.bookmarks")}
                  iconName="16-bookmark"
                  onClick={() => history.push(bookmarksPath())}
                  isActive={isBookmarksActive}
                />
              }
              disabled={disabled}
            >
              <BookmarksList />
            </PopoverPortal>
          )}
        </>
      )}
      {shouldShowSpaceDirectory && (
        <PlatformMenuItemLink
          label={t("sidebar_spaces")}
          linkTo="/spaces"
          iconName="all-spaces"
          disabled={disabled}
        />
      )}
      {shouldShowMemberDirectory && (
        <PlatformMenuItemLink
          label={t("members_directory.menu.title")}
          linkTo={memberDirectoryPath()}
          iconName="members-v2"
          disabled={disabled}
        />
      )}
      {shouldShowAllEventsPage && (
        <PlatformMenuItemLink
          label={t("events.title")}
          linkTo="/events"
          iconName="16-calendar"
          disabled={disabled}
        />
      )}
      {shouldShowCoursesDirectory && (
        <PlatformMenuItemLink
          label={t("courses.directory.title")}
          linkTo="/courses"
          iconName="16-book"
          disabled={disabled}
          isGrayBadge
        />
      )}
      {shouldShowLeaderboard && (
        <PlatformMenuItemLink
          label={t("leaderboard.title")}
          linkTo="/leaderboard?period=7_days"
          iconName="16-analytics"
          disabled={disabled}
          isGrayBadge
        />
      )}
    </div>
  );
};

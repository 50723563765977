import { useState } from "react";
import PropTypes from "prop-types";
import { DragOverlay } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { isDrippedCourseSpace } from "@/react/helpers/courseHelpers";
import { Table } from "@circle-react/components/shared/uikit/Table";
import { CustomDndContext } from "./CustomDndContext";
import { DraggableSectionItem } from "./DraggableSectionItem";
import { Head } from "./Head";
import { LessonItem } from "./LessonItem";
import { SectionItem } from "./SectionItem";

export const LessonTable = ({
  space,
  sections,
  setSections,
  dispatchSectionsNameEditor,
  sectionsNameEditor,
}) => {
  const [activeDragItem, setActiveDragItem] = useState(null);
  const isDraggingALesson =
    activeDragItem?.data?.current?.dragType === "lesson";
  const isDraggingASection =
    activeDragItem?.data?.current?.dragType === "section";

  const activeDragLesson =
    isDraggingALesson && activeDragItem?.data?.current?.item;
  const activeDragSection =
    isDraggingASection && activeDragItem?.data?.current?.item;

  return (
    <CustomDndContext
      sections={sections}
      setSections={setSections}
      setActiveDragItem={setActiveDragItem}
    >
      <Table
        variant="no-border"
        className="border-primary w-full overflow-hidden rounded-lg border"
        tableClassName="table-fixed w-full"
        dataTestId="sections-lessons-table"
      >
        <colgroup>
          <col />
          {isDrippedCourseSpace(space) && (
            <>
              <col className="w-72" />
              <col className="w-24" />
            </>
          )}
          <col className="w-72" />
        </colgroup>
        <Head
          space={space}
          sections={sections}
          dispatchSectionsNameEditor={dispatchSectionsNameEditor}
        />
        <SortableContext
          id="sections-list"
          items={sections.map(section => section.sortId)}
          strategy={verticalListSortingStrategy}
        >
          {sections.map(section => (
            <DraggableSectionItem
              setSections={setSections}
              key={section.id}
              space={space}
              section={section}
              isNameEditable={sectionsNameEditor.get(section.id)}
              setIsNameEditable={value =>
                dispatchSectionsNameEditor({
                  id: section.id,
                  value,
                })
              }
            />
          ))}
        </SortableContext>
      </Table>
      {/* TODO: Add drag overlay for sections */}
      {/* DragOverlay should only be rendered once, hence it has to be outside the sections loop */}
      <DragOverlay wrapperElement="table">
        {activeDragLesson && <LessonItem lesson={activeDragLesson} isOverlay />}
        {activeDragSection && (
          <SectionItem space={space} section={activeDragSection} isOverlay />
        )}
      </DragOverlay>
    </CustomDndContext>
  );
};

LessonTable.propTypes = {
  space: PropTypes.object.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSections: PropTypes.func.isRequired,
  dispatchSectionsNameEditor: PropTypes.func.isRequired,
  sectionsNameEditor: PropTypes.object.isRequired,
};

import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { workflowsApi } from "@/react/api";

const deleteAction = (workflowId, id) =>
  workflowsApi.deleteAction({
    workflowId,
    id,
  });

export const useDeleteAction = (options = {}) => {
  const { watch } = useFormContext();
  const workflowId = watch("id");

  return useMutation(id => deleteAction(workflowId, id), options);
};

import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

export interface PaywallCouponBadgeProps {
  couponCode: string;
  onRemoveCoupon: (event: any) => void;
}

export const PaywallCouponBadge = ({
  couponCode,
  onRemoveCoupon,
}: PaywallCouponBadgeProps) => (
  <div className="bg-tertiary flex w-fit flex-row items-center  gap-x-2 rounded-lg px-4 py-2">
    <Typography.LabelSm weight="semibold">{couponCode}</Typography.LabelSm>
    <Icon
      type="20-close"
      size={20}
      className="cursor-pointer"
      onClick={onRemoveCoupon}
    />
  </div>
);

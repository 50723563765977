import {
  PaywallChargesList,
  PaywallChargesListContextProvider,
} from "@circle-react-shared/Paywalls/PaywallChargesList";
import { EmptyPage } from "../../Filters/EmptyPage";
import { usePaywallChargesList } from "./hooks/usePaywallChargesList";

export const ChargesList = ({
  chargesData,
  activeFilterTab,
  refetchAllCharges,
}) => {
  const { onClickPagination, columnsToRender } = usePaywallChargesList({
    chargesData,
  });

  if (
    chargesData.hasFilters &&
    chargesData.totalCharges === 0 &&
    !chargesData.isLoadingCharges
  ) {
    return <EmptyPage />;
  }

  return (
    <PaywallChargesListContextProvider
      shouldOpenAdminMemberEdit
      refetchAllCharges={refetchAllCharges}
    >
      <PaywallChargesList
        key={activeFilterTab}
        columnsToRender={columnsToRender}
        chargesData={chargesData}
        onClickPagination={onClickPagination}
        className="px-6"
      />
    </PaywallChargesListContextProvider>
  );
};

import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import I18n from "i18n-js";
import { PageMetaData } from "@/react/components/Layout/PageMetaData";
import { useActiveView } from "@/react/hooks/utils/useActiveView";
import { CommunityMembers, Filters } from "@circle-react-shared/MembersList";
import { VIEWS } from "@circle-react-shared/MembersList/constants";
import { StandardLayout } from "../Layout/StandardLayout";
import { ViewTypes } from "../shared/MembersList/CommunityMembers/ListView/CommunityMember/MemberActions";
import { Header } from "./Header";

const bodyClassNames = "view-members-directory";

export const MemberDirectoryContent = ({ memberDirectorySettings }) => {
  const [memberCount, setMemberCount] = useState(0);
  const [activeView, setActiveView] = useActiveView({
    defaultCommunityView: memberDirectorySettings.view,
    views: VIEWS,
  });
  const [
    shouldRenderMobileFiltersTransition,
    setShouldRenderMobileFiltersTransition,
  ] = useState(false);

  return (
    <>
      <Header
        memberCount={memberCount}
        activeView={activeView}
        setActiveView={setActiveView}
        setShouldRenderMobileFiltersTransition={
          setShouldRenderMobileFiltersTransition
        }
      />
      <StandardLayout.Body hasPaddingTopMobile={false}>
        <PageMetaData
          title={I18n.t("members_directory.title")}
          bodyClassNames={bodyClassNames}
        />

        <StandardLayout.Content
          className={classNames("md:!min-w-0", {
            "!m-0 !max-w-none": activeView === VIEWS.GRID,
          })}
        >
          <Filters
            shouldRenderMobileFiltersTransition={
              shouldRenderMobileFiltersTransition
            }
            shouldBeHiddenOnMd
            shouldShowAdminFilters
          />
          <CommunityMembers
            setMemberCount={setMemberCount}
            scopeResults={memberDirectorySettings.scope}
            defaultSort={memberDirectorySettings.defaultSort}
            shouldShowHiddenMemberTooltip
            viewType={ViewTypes.MEMBER_DIRECTORY}
            activeView={activeView}
          />
        </StandardLayout.Content>
      </StandardLayout.Body>
    </>
  );
};

MemberDirectoryContent.propTypes = {
  memberDirectorySettings: PropTypes.shape({
    scope: PropTypes.object,
    defaultSort: PropTypes.string,
    view: PropTypes.oneOf(Object.values(VIEWS)),
  }).isRequired,
};

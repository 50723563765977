import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../../types";
import { FormSendDM } from "./FormSendDM";
import type { HistoryDetailViewDMProps } from "./HistoryDetailViewDM";
import { HistoryDetailViewDM } from "./HistoryDetailViewDM";
import { ViewDM } from "./ViewDM";

export const sendDM: Readonly<ActionOption<HistoryDetailViewDMProps>> = {
  label: t("settings.workflows.form.actions.send_dm"),
  value: "send_dm",
  group: t("settings.workflows.form.action_groups.communication"),
  leftAddon: <Icon type="16-messages" size={16} className="!h-4 !w-4" />,
  formComponent: FormSendDM,
  viewComponent: ViewDM,
  historyDetailViewComponent: HistoryDetailViewDM,
  icon: "chat",
} as const;

import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { usePricingTabContext } from "../../usePricingTabContext";
import { PricingTabAddPriceModalNoticeBox } from "./NoticeBox";
import { noticeScenarios } from "./NoticeScenarios";

export const PricingTabAddPriceModalNotice = () => {
  const { currency, priceBuildOptions, getHasTrialPeriod, getTrialPeriodDays } =
    usePricingTabContext();

  const trialPeriodDays = getTrialPeriodDays();
  const hasTrialPeriod = getHasTrialPeriod();

  const { watch } = useFormContext();

  const priceType = watch("price_type");
  const buildOptions = priceBuildOptions[priceType];

  const hasUpfrontPayment = Boolean(watch("upfront_payment_enabled"));
  const isUpfrontPaymentRequiredForTrial = Boolean(
    watch("trial_requires_upfront_payment"),
  );

  const scenario = noticeScenarios.find(({ matcher }) =>
    matcher({
      priceType,
      hasUpfrontPayment,
      hasTrialPeriod,
      isUpfrontPaymentRequiredForTrial,
    }),
  );
  if (!scenario) {
    return null;
  }

  const validateState = scenario.stateValidator;
  if (!validateState({ watch })) {
    return null;
  }

  const resolveMessageProps = scenario.messagePropsResolver;
  const [messageKey, messageProps] = resolveMessageProps({
    buildOptions,
    currency,
    trialPeriodDays,
    watch,
  });

  return (
    <PricingTabAddPriceModalNoticeBox>
      {t(
        [
          "settings.paywalls.modal.tabs.pricing.add_price_modal.notice",
          messageKey,
        ],
        messageProps,
      )}
    </PricingTabAddPriceModalNoticeBox>
  );
};

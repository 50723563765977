import { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { useCurrentSpaceContext } from "@circle-react/contexts";
import { reactQueryPut } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { getCourseSectionsKey } from "@circle-react/hooks/courses/useCoursesApi";
import { useToast } from "@circle-react-uikit/ToastV2";
import { NameEditor } from "./NameEditor";

export const LessonNameEditor = ({ lesson, section, onClose }) => {
  const { data: space } = useCurrentSpaceContext();
  const [name, setName] = useState(lesson.name);
  const queryClient = useQueryClient();
  const toast = useToast();

  const renameMutation = useMutation(
    data =>
      reactQueryPut(
        internalApi.courses.updateLesson({
          courseId: space.id,
          sectionId: section.id,
          lessonId: lesson.id,
        }),
        data,
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getCourseSectionsKey(space.id));
        onClose();
      },
      onError: e => {
        toast.error(e.errorDetails);
      },
    },
  );
  const onSubmit = () =>
    renameMutation.mutate({
      course_lesson: { name },
    });

  return (
    <NameEditor
      name={name}
      setName={setName}
      isLoading={renameMutation.isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
      dataTestId="lesson-title"
    />
  );
};

LessonNameEditor.propTypes = {
  lesson: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  setSections: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { AddPriceButton } from "./AddPriceButton";

export const PricingTabEmptyState = () => (
  <div className="mt-4 flex flex-col items-center justify-center gap-4 rounded-lg border py-20">
    <Typography.TitleSm weight="semibold">
      {t("settings.paywalls.modal.tabs.pricing.title")}
    </Typography.TitleSm>
    <div className="flex-row px-14">
      <Typography.TitleSm weight="bold" textAlign="text-center">
        {t("settings.paywalls.modal.tabs.pricing.empty_state.title")}
      </Typography.TitleSm>
      <Typography.BodyMd textAlign="text-center">
        {t("settings.paywalls.modal.tabs.pricing.empty_state.description")}
      </Typography.BodyMd>
    </div>
    <AddPriceButton variant="circle" />
  </div>
);

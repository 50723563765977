import PropTypes from "prop-types";
import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";

export const MobileNotSupportedPanel = ({ title, description, className }) => (
  <div className={classNames("flex h-full", className)}>
    <div className="mx-auto flex flex-col gap-2 self-center p-6 text-center">
      {title && (
        <div>
          <Typography.TitleSm weight="semibold">{title}</Typography.TitleSm>
        </div>
      )}
      {description && (
        <div>
          <Typography.BodySm color="text-default">
            {description}
          </Typography.BodySm>
        </div>
      )}
    </div>
  </div>
);

MobileNotSupportedPanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};

import { useState } from "react";
import type { ReactNode } from "react";
import classNames from "classnames";
import { canCreateSpaces } from "@circle-react/helpers/spaceGroupHelpers";
import { spaceGroupPaths } from "@circle-react/helpers/urlHelpers";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { SpaceGroup } from "@circle-react/types/SpaceGroup";
import { AddOptions } from "./AddOptions";
import { SpaceGroupName } from "./SpaceGroupName";
import { SpaceGroupOptionsDropdown } from "./SpaceGroupOptionsDropdown";
import { SpaceGroupViewWrapper } from "./SpaceGroupViewWrapper";

export interface SpaceGroupViewProps {
  canManage?: boolean;
  children: ReactNode;
  id: string;
  isAdmin?: boolean;
  isCollapsed?: boolean;
  isDragging?: boolean;
  listId: string;
  onAddMembersClick?: () => void;
  onCollapseToggle?: () => void;
  onCreateSpaceClick?: () => void;
  onCreateSpaceGroupClick?: () => void;
  onEditSpaceGroupClick?: () => void;
  position?: number;
  shouldDisplayOptions?: boolean;
  spaceGroup: SpaceGroup;
}

export const SpaceGroupView = ({
  canManage = false,
  children,
  id,
  isAdmin = false,
  isCollapsed = false,
  isDragging = false,
  listId,
  onAddMembersClick = () => {},
  onCollapseToggle = () => {},
  onCreateSpaceClick = () => {},
  onCreateSpaceGroupClick = () => {},
  onEditSpaceGroupClick = () => {},
  position,
  shouldDisplayOptions = true,
  spaceGroup,
}: SpaceGroupViewProps) => {
  const canHover = useAnyHoverMediaQuery();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div draggable="false" id={id}>
      <SpaceGroupViewWrapper
        onCollapseToggle={onCollapseToggle}
        isDragging={isDragging}
        isCollapsed={isCollapsed}
        listId={listId}
      >
        <SpaceGroupName
          isDropdownOpen={isDropdownOpen}
          canManage={canManage}
          spaceGroup={spaceGroup}
          isCollapsed={isCollapsed}
        />
        {shouldDisplayOptions && (
          <button
            type="button"
            className={classNames("ml-1 flex gap-1", {
              "hidden group-hover:flex group-focus-visible:flex":
                canHover && !isDropdownOpen,
            })}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <SpaceGroupOptionsDropdown
              onAddMembersClick={onAddMembersClick}
              onEditSpaceGroupClick={onEditSpaceGroupClick}
              seeMembersLink={spaceGroupPaths.members({
                spaceGroupSlug: spaceGroup.slug,
              })}
              seeSpacesLink={spaceGroupPaths.show({
                spaceGroupSlug: spaceGroup.slug,
              })}
              onToggle={setIsDropdownOpen}
              canManage={canManage}
            />
            {(canCreateSpaces({ spaceGroup }) || isAdmin) && (
              <AddOptions
                position={position}
                isAdmin={isAdmin}
                onCreateSpace={onCreateSpaceClick}
                onCreateSpaceGroup={onCreateSpaceGroupClick}
                canCreateSpaces={canCreateSpaces({ spaceGroup })}
                onToggle={setIsDropdownOpen}
              />
            )}
          </button>
        )}
      </SpaceGroupViewWrapper>
      {!isCollapsed && children}
    </div>
  );
};

import {
  findFrequencyByIntervalAndCount,
  isSubscriptionPrice,
} from "@circle-react/helpers/paywallPriceHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { usePricingTabContext } from "../usePricingTabContext";
import type { PricingTabPriceListCellProps } from "./types";

export const PricingTabPriceListCellPaymentType = ({
  row: { original: price },
}: PricingTabPriceListCellProps) => {
  const {
    price_type,
    interval_count,
    interval,
    preferred: isPreferred,
  } = price;

  const { priceBuildOptions } = usePricingTabContext();

  const buildOptions = priceBuildOptions[price_type];

  let paymentType: string;
  if (isSubscriptionPrice(price)) {
    const buildOptions = priceBuildOptions[price_type];
    const frequency = findFrequencyByIntervalAndCount(
      buildOptions,
      interval,
      interval_count,
    );
    paymentType = frequency?.as_adjective || buildOptions.name;
  } else {
    paymentType = buildOptions.name;
  }

  return (
    <div className="flex flex-row items-center gap-2">
      {paymentType}
      {isPreferred && <Icon type="16-check-circle" size={16} />}
    </div>
  );
};

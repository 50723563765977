import type { Location } from "history";
import { useHistory, useLocation } from "react-router-dom";
import {
  CurrentPostContextProvider,
  CurrentSpaceContextProvider,
} from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { useShowPost } from "../Show/useShowPost";
import { ModalBody } from "./ModalBody";
import { ModalHeader } from "./ModalHeader";

interface LocationState {
  prevLocation?: Location;
}

export const PostModalComponent = () => {
  const history = useHistory();
  const location = useLocation<LocationState | undefined>();
  const { isLoading, postResource, perPagePostIds, space } = useShowPost();
  const { state } = location;
  const { prevLocation } = state ?? {};
  const wasInFeedPageBefore = prevLocation?.pathname === "/feed";

  if (isLoading) {
    return null;
  }

  return (
    <CurrentSpaceContextProvider spaceId={space?.id}>
      <PostsDetailsContextProvider perPagePostIds={perPagePostIds}>
        <CurrentPostContextProvider
          post={postResource.data}
          usedIn="minimal-post-modal"
        >
          <Modal
            isOpen
            onClose={() => {
              if (prevLocation) {
                history.push(prevLocation);
              }
            }}
            contentPortalId="post-modal-portal"
          >
            <Modal.Overlay />
            <Modal.Content size="xl" className="sm:!my-[5vh] sm:!max-h-[90vh]">
              <ModalHeader
                spaceId={space.id}
                onChange={postResource?.onPostChange}
              />
              <ModalBody
                isRenderedOutsideTheSpace={wasInFeedPageBefore}
                spaceId={space.id}
                post={postResource.data}
                onPostResourceChange={postResource?.onPostChange}
                onPostDestroy={() => {
                  if (prevLocation) {
                    history.push(prevLocation);
                  }
                }}
              />
            </Modal.Content>
          </Modal>
        </CurrentPostContextProvider>
      </PostsDetailsContextProvider>
    </CurrentSpaceContextProvider>
  );
};

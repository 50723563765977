import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SectionItem } from "./SectionItem";

export const DraggableSectionItem = ({ section, space, ...rest }) => {
  const {
    attributes,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: section.sortId,
    data: {
      dragType: "section",
      item: section,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SectionItem
      isDraggable
      isDragging={isDragging}
      ref={setNodeRef}
      section={section}
      space={space}
      tableBodyProps={{
        style,
        ...attributes,
      }}
      dragHandleProps={{
        listeners,
        setActivatorNodeRef,
      }}
      {...rest}
    />
  );
};

DraggableSectionItem.propTypes = {
  section: PropTypes.object.isRequired,
  space: PropTypes.object.isRequired,
};

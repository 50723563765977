import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useFormContext } from "react-hook-form";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { canPurchase } from "@circle-react/helpers/paywallCheckoutHelpers";
import { buildPayButtonLabel } from "../helpers";
import { useOnSubmit } from "./useOnSubmit";

export const usePayment = () => {
  const { watch } = useFormContext();
  const {
    currentUser,
    hasAlreadyPurchased,
    loginUrl,
    communityRootUrl,
    paywall,
    getSelectedPrice,
    previewMutation,
    paymentError,
    isCardInfoValid,
    isPaywallActive,
    isCardInfoComplete,
    setIsCardInfoComplete,
    isTaxIdMandatory,
    shouldShowTaxIdForm,
    setStripePaymentMethodType,
  } = usePaywallCheckoutContext();
  const stripe = useStripe();
  const elements = useElements();
  const selectedPrice = getSelectedPrice();

  const {
    name,
    email,
    password,
    termsOfService,
    community_member_billing_info_address_attributes_complete:
      isMemberAddressComplete,
    community_member_billing_info_attributes: { tax_id_value, tax_id_type },
  } = watch();

  const isSubmitDisabled =
    !isMemberAddressComplete ||
    (!currentUser && (!name || !email || !password || !termsOfService)) ||
    !isCardInfoValid ||
    !isPaywallActive ||
    paymentError.disablePayButton ||
    canPurchase(hasAlreadyPurchased, paywall) ||
    previewMutation.isLoading ||
    previewMutation.isError ||
    (isTaxIdMandatory &&
      shouldShowTaxIdForm &&
      (!tax_id_value || !tax_id_type));

  const { data: preview } = previewMutation;
  const payButtonLabel = buildPayButtonLabel({
    isPaywallActive,
    selectedPrice,
    preview,
  });

  const { onSubmit } = useOnSubmit({ stripe, elements });

  // let's check if credit card details are complete
  const handleOnChangeCard = (e: any) => {
    setStripePaymentMethodType(e.value.type);
    if (e.complete) setIsCardInfoComplete(true);
    else if (isCardInfoComplete) setIsCardInfoComplete(false);
  };

  const handleOnReadyCard = (e: any) => currentUser && e.focus();

  return {
    paymentError,
    isSubmitDisabled,
    selectedPrice,
    currentUser,
    stripe,
    loginUrl,
    communityRootUrl,
    payButtonLabel,
    hasAlreadyPurchased,
    handleOnChangeCard,
    handleOnReadyCard,
    onSubmit,
  };
};

export const LoadingState = () => (
  <>
    {Array.from(Array(9).keys()).map(id => (
      <div key={`loading-state-${id}`} className="flex items-center space-x-2">
        <div className="bg-tertiary h-8 w-8 animate-pulse rounded-full " />
        <div className="space-y-1 py-0.5">
          <div className="bg-tertiary h-4 w-12 animate-pulse rounded" />
          <div className="bg-tertiary h-3 w-20 animate-pulse rounded" />
        </div>
      </div>
    ))}
  </>
);

import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../usePricingTabContext";
import { PricingTabAddPriceModalFieldsUpfrontPaymentAmountField } from "./UpfrontPaymentAmountField";

export const PricingTabAddPriceModalFieldsUpfrontPaymentFields = () => {
  const { getHasTrialPeriod } = usePricingTabContext();
  const hasTrialPeriod = getHasTrialPeriod();

  const { watch, setValue } = useFormContext();
  const hasUpfrontPayment = watch("upfront_payment_enabled");

  useEffect(() => {
    if (!hasUpfrontPayment) {
      setValue("upfront_payment_amount", "");
    }
  }, [hasUpfrontPayment, setValue]);

  return (
    <div className="flex flex-col">
      <Form.Item
        className="!p-0"
        labelClassName="!font-normal"
        name="upfront_payment_enabled"
        translationRoot="settings.paywalls.modal.tabs.pricing"
        tooltipText={t(
          "settings.paywalls.modal.tabs.pricing.upfront_payment_enabled_tooltip",
        )}
        fullWidth
        hidePlaceholder
        hideDescription
        hideBorder
        inlineReverse
      >
        <Form.ToggleSwitch name="upfront_payment_enabled" variant="small" />
      </Form.Item>
      {hasUpfrontPayment && (
        <div className="flex flex-col gap-y-2">
          <PricingTabAddPriceModalFieldsUpfrontPaymentAmountField />
          {hasTrialPeriod && (
            <Form.Item
              className="!py-0"
              labelClassName="!font-normal"
              name="trial_requires_upfront_payment"
              translationRoot="settings.paywalls.modal.tabs.pricing"
              fullWidth
              hideBorder
              hideDescription
              hidePlaceholder
              inlineReverse
            >
              <Form.ToggleSwitch
                name="trial_requires_upfront_payment"
                variant="small"
              />
            </Form.Item>
          )}
        </div>
      )}
    </div>
  );
};

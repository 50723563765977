import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classNames from "classnames";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface StandardLayoutHeaderProps
  extends ComponentPropsWithoutRef<"div"> {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  filters?: ReactNode;
  headline?: ReactNode;
  hasBorder?: boolean;
}

export const StandardLayoutHeader = ({
  leftContent,
  rightContent,
  filters,
  headline,
  hasBorder = true,
  className,
  ...rest
}: StandardLayoutHeaderProps) => {
  const hasFilters = Boolean(filters);
  const hasHeadline = Boolean(headline);
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { isV3Enabled } = useIsV3();

  return (
    <div
      {...rest}
      className={classNames(
        "bg-primary w-full lg:sticky lg:top-0 lg:z-10",
        className,
      )}
    >
      <div
        className={classNames("flex items-center px-6", {
          "border-primary border-b": hasBorder,
          "!cursor-not-allowed": isViewOnlyMasquerading,
          "h-16 lg:px-6": !isV3Enabled,
          "h-18 lg:px-9": isV3Enabled,
        })}
        data-testid="space-header"
      >
        <div
          className={classNames("flex-1", {
            "!pointer-events-none": isViewOnlyMasquerading,
          })}
          data-testid="space-settings"
        >
          {leftContent}
        </div>
        <div
          className={classNames("relative flex", {
            "!pointer-events-none": isViewOnlyMasquerading,
          })}
        >
          {rightContent}
        </div>
      </div>
      {hasFilters && filters}
      {hasHeadline && headline}
    </div>
  );
};

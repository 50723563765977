import { useEffect, useState } from "react";
import { t } from "i18n-js";
import { CookiesProvider, useCookies } from "react-cookie";
import {
  CIRCLE_DOMAIN,
  GDPR_COOKIE_NAME,
} from "@circle-react/components/constants";
import { staticPages } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { getExpirationDate } from "./helpers";
import { REQUIRED_COUNTRIES } from "./requiredCountries";

export const GDPRCookieBanner = () => {
  const isInsideMobileWebView =
    !!window.webview?.isComingFromReactNativeWebview ||
    !!window.webview?.isInsideMobileWebview;
  const [cookies, setCookie] = useCookies([GDPR_COOKIE_NAME]);
  const [hideBanner, setHideBanner] = useState(true);
  const [countryCode, setCountryCode] = useState(
    window.CLOUDFLARE_COUNTRY_CODE,
  );
  const { [GDPR_COOKIE_NAME]: acceptedCookies } = cookies;

  const getCurrentUrlDomain = () => {
    if (window.location.hostname.includes(CIRCLE_DOMAIN)) {
      return CIRCLE_DOMAIN;
    }
    return window.location.hostname;
  };

  const isSecureCookie = () =>
    ["production", "staging"].includes(window.RAILS_ENV);

  const shouldShowBanner = () =>
    ["production", "staging"].includes(window.RAILS_ENV);

  const handleClick = accepted => {
    setCookie(GDPR_COOKIE_NAME, accepted, {
      expires: getExpirationDate(),
      domain: getCurrentUrlDomain(),
      path: "/",
      sameSite: "None",
      secure: isSecureCookie(),
    });
  };

  const getCountryCode = () => {
    // We are saving a cookie called "cookies_enabled" to validate 3th party cookies are enabled,
    // when that cookie is not present we assume that cookies are disabled and we don't show the
    // cookie banner to the user
    if (!acceptedCookies && !countryCode?.length) {
      fetch("https://cloudflare-quic.com/b/headers")
        .then(res => res.json())
        .then(data => setCountryCode(data.headers["Cf-Ipcountry"]))
        .catch(error => {
          console.error(error);
        });
    }
  };

  const setBannerIfRequiredCountry = () => {
    const countryCodes = Object.keys(REQUIRED_COUNTRIES);
    setHideBanner(!countryCodes.includes(countryCode));
  };

  useEffect(() => {
    getCountryCode();
  });

  useEffect(() => {
    setBannerIfRequiredCountry();
  }, [countryCode]);

  if (
    !document.cookie ||
    hideBanner ||
    acceptedCookies ||
    isInsideMobileWebView
  ) {
    return null;
  }

  if (!shouldShowBanner()) {
    return null;
  }

  return (
    <CookiesProvider>
      <div className="border-primary bg-primary fixed bottom-0 z-[1060] w-full gap-4 border p-4 shadow-lg md:bottom-8 md:right-8 md:flex md:w-auto md:max-w-3xl md:rounded">
        <div className="mb-4 leading-4 md:mb-0">
          <p className="text-dark text-sm">
            {t(["gdpr.cookie_banner.description"])}
            <br />
            {t("gdpr.cookie_banner.privacy_policy.prefix")}
            <a
              href={staticPages.privacy_policy()}
              target="_blank"
              rel="noreferrer"
            >
              {t("gdpr.cookie_banner.privacy_policy.link")}
            </a>
            {t("gdpr.cookie_banner.privacy_policy.suffix")}
          </p>
        </div>
        <div className="flex flex-row items-center gap-4">
          <Button onClick={() => handleClick(false)} variant="secondary">
            {t("gdpr.cookie_banner.decline_button")}
          </Button>
          <Button onClick={() => handleClick(true)} variant="primary">
            {t("gdpr.cookie_banner.accept_button")}
          </Button>
        </div>
      </div>
    </CookiesProvider>
  );
};

import classNames from "classnames";
import { Icon } from "@circle-react/components/shared/Icon";
import { Tippy } from "@circle-react/components/shared/Tippy";
import { Typography } from "@circle-react-uikit/Typography";

export interface CheckoutPreviewEntryProps {
  className?: string;
  label: string;
  labelSize?: "sm" | "md";
  labelWeight?: "normal" | "medium" | "semibold";
  tooltip?: string;
  value: string;
  valueSize?: "sm" | "md" | "lg";
  valueColor?: "text-green-600" | "text-primary";
  valueWeight?: "normal" | "medium" | "semibold";
  dataTestId?: string;
}

export const CheckoutPreviewEntry = ({
  className,
  label,
  labelSize = "sm",
  labelWeight = "normal",
  tooltip,
  value,
  valueSize = "sm",
  valueColor = "text-primary",
  valueWeight = "normal",
  dataTestId,
}: CheckoutPreviewEntryProps) => {
  const Label = labelSize === "sm" ? Typography.LabelSm : Typography.LabelMd;
  const Value =
    valueSize === "sm"
      ? Typography.LabelSm
      : valueSize === "md"
      ? Typography.LabelMd
      : Typography.LabelLg;

  return (
    <div
      className={classNames(
        className,
        "flex flex-row items-center justify-between space-x-2 whitespace-nowrap md:space-x-3",
      )}
      data-testid={dataTestId}
    >
      <div className="flex flex-row gap-x-2 whitespace-normal">
        <Label weight={labelWeight}>{label}</Label>
        {tooltip && (
          <Tippy
            dataTestId="checkout-form-preview-entry-tooltip"
            className="inline-flex"
            content={tooltip}
          >
            <Icon type="16-question-mark" size={16} color="text-primary" />
          </Tippy>
        )}
      </div>
      <Value weight={valueWeight} color={valueColor}>
        {value}
      </Value>
    </div>
  );
};

import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import classnames from "classnames";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { MemoryRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { supportWidgetApi } from "@circle-react/api/supportWidgetApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import { FloatingActionButton } from "../FloatingActionButton";
import { OnboardingPopup } from "../OnboardingPopup";
import { RecentLocationWrapper } from "../RecentLocationWrapper";
import { SupportWidgetContainer } from "../SupportWidgetContainer";
import { useSupportWidgetContext } from "../SupportWidgetContext";
import { SUPPORT_WIDGET_DEFAULT_TRANSITIONS } from "../constants";
import { computeInsetWithScrollbar } from "../utils";
import { getLocalStorage } from "../utils";
import { ChatBotView } from "../views/ChatBotView";
import { ConversationViewV2 } from "../views/ConversationViewV2";
import { ConversationsListView } from "../views/ConversationsListView";
import { CreateConversationView } from "../views/CreateConversationView";
import { EventsListView } from "../views/EventsListView";
import { HelpView } from "../views/HelpView";
import { HomeView } from "../views/HomeView";
import { NewsListView } from "../views/NewsListView";
import { NewsView } from "../views/NewsView";
import { PostView } from "../views/PostView";

export const SupportWidgetPortalBody = () => {
  const { isOpen, displayOnboarding, toggleWidget } = useSupportWidgetContext();
  const [launcherInset, setLauncherInset] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isFabVisible, setIsFabVisible] = useState(true);
  const [isOnboardingVisible, setIsOnboardingVisible] =
    useState(displayOnboarding);

  const initialEntries = ["/"];
  const storedLocation = getLocalStorage()?.location;
  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();

  if (storedLocation && storedLocation != "/") {
    initialEntries.push(storedLocation);
  }

  const { mutate: completeOnboardingQuery } = useMutation(
    () => supportWidgetApi.completeOnboarding(),
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );

  useEffect(() => {
    setLauncherInset(computeInsetWithScrollbar());
  }, []);

  const completeOnboarding = () => {
    if (isOnboardingVisible) {
      completeOnboardingQuery();
    }
    setIsOnboardingVisible(false);
  };
  const onMouseOver = () => setIsHovered(true);
  const onMouseOut = () => setIsHovered(false);
  const showFab = () => setIsFabVisible(true);
  const hideFab = () => setIsFabVisible(false);
  const hideOnboarding = () => completeOnboarding();
  const onFabClick = () => {
    if (isFabVisible) {
      completeOnboarding();
      toggleWidget({ shouldTriggerOnWidgetClose: true });
    }
  };

  const shouldRenderOnboarding = !isOpen && isFabVisible && isOnboardingVisible;

  return (
    <div
      className="fixed bottom-4 z-[60] flex flex-col"
      style={{ right: launcherInset }}
    >
      <Transition show={isOpen} {...SUPPORT_WIDGET_DEFAULT_TRANSITIONS}>
        <Router
          initialEntries={initialEntries}
          initialIndex={initialEntries.length - 1}
        >
          {currentCommunitySettings?.support_widget_analytics_enabled && (
            <Route
              render={({ location }) => {
                if (typeof window.gtag === "function") {
                  window.gtag("event", "page_view", {
                    page_location: window.location.href,
                    page_path: location.pathname + location.search,
                    page_title: t("support_widget.support_widget"),
                    community_name: currentCommunity?.name,
                    community_id: currentCommunity?.id,
                  });
                }
                return null;
              }}
            />
          )}
          <SupportWidgetContainer>
            <RecentLocationWrapper>
              <Switch>
                <Route exact path="/" key="path" component={HomeView} />
                <Route exact path="/ask" key="path" component={ChatBotView} />
                <Route
                  exact
                  path="/form"
                  key="path"
                  component={CreateConversationView}
                />
                <Route
                  exact
                  path="/space_groups"
                  key="path"
                  component={HelpView}
                />
                <Route exact path="/news" key="path" component={NewsListView} />
                <Route
                  exact
                  path="/spaces/:spaceId/posts"
                  key="path"
                  component={HelpView}
                />
                <Route
                  exact
                  path="/conversations"
                  key="path"
                  component={ConversationsListView}
                />
                <Route
                  exact
                  path="/conversations/:id"
                  key="path"
                  component={ConversationViewV2}
                />
                <Route exact path="/news/:id" key="path" component={NewsView} />
                {/* Had to extract this one route here to pass `key` prop. We want to unmount previous post and mount new one instead of updating values because of refs */}
                <Route
                  exact
                  path="/posts/:id"
                  key="path"
                  render={({ match }) => (
                    <PostView id={match.params.id} key={match.params.id} />
                  )}
                />
                <Route
                  exact
                  path="/events"
                  key="path"
                  component={EventsListView}
                />
              </Switch>
            </RecentLocationWrapper>
          </SupportWidgetContainer>
        </Router>
      </Transition>

      {shouldRenderOnboarding && <OnboardingPopup onClick={hideOnboarding} />}

      <div
        className="-mr-5 mt-5 flex justify-end align-baseline"
        onMouseOver={onMouseOver}
        onFocus={onMouseOver}
        onMouseOut={onMouseOut}
        onBlur={onMouseOut}
      >
        <div
          className={classnames(
            "transition-[width,transform] duration-300 ease-linear",
            {
              "translate-x-30 w-0": !isFabVisible,
            },
          )}
        >
          <FloatingActionButton isOpen={isOpen} onClick={onFabClick} />
        </div>
        <Icon
          type={isFabVisible ? "12-chevron-right" : "12-chevron-left"}
          size={12}
          className={classnames("cursor-pointer text-gray-400", {
            invisible:
              isOnboardingVisible || isOpen || (isFabVisible && !isHovered),
          })}
          onClick={isFabVisible ? hideFab : showFab}
        />
      </div>
    </div>
  );
};

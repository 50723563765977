import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useGetSidebarLinkClass } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/useGetSidebarLinkClass";
import { communitiesPaths } from "@circle-react/helpers/routerPathHelpers";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";

export interface PlatformMenuItemLinkV3Props {
  text: string;
  linkTo: string;
  icon: IconType;
  isActive?: boolean;
  isDisabled?: boolean;
  onClose?: () => void;
}

interface LocationState {
  prevLocation?: Location;
}

export const PlatformMenuItemLinkV3 = ({
  text,
  linkTo,
  icon,
  isActive: isActiveProp = false,
  isDisabled = false,
  onClose,
}: PlatformMenuItemLinkV3Props) => {
  const { getActiveInactiveClasses } = useGetSidebarLinkClass();
  const location = useLocation<LocationState | undefined>();
  const { state } = location;
  const { prevLocation } = state ?? {};
  const isInvokedFromPostModalAndFromFeed =
    Boolean(prevLocation) && !prevLocation?.pathname?.startsWith("/c");
  const isFeedPlatformItem = linkTo.includes(communitiesPaths.feed());

  return (
    <>
      <NavLink
        to={linkTo}
        title={text}
        className={isActive =>
          classNames(
            "h-8.5 flex w-full items-center gap-2 !rounded-lg px-4 py-1.5 transition-colors duration-200",
            getActiveInactiveClasses(
              isActive || isActiveProp,
              isInvokedFromPostModalAndFromFeed,
              isFeedPlatformItem,
            ),
          )
        }
        onClick={event => {
          if (isDisabled) {
            event.preventDefault();
          }
        }}
        aria-disabled={isDisabled}
      >
        <Icon type={icon} size={20} />
        <h4 className="text-xs-plus max-w-full truncate font-medium text-current">
          {text}
        </h4>
      </NavLink>
      {onClose && (
        <button
          className="text-brand-button hover:bg-secondary focus:bg-secondary hover:text-dark focus:text-dark focus-visible:outline-secondary absolute right-2 top-1/2 flex h-5 w-5 -translate-y-1/2 rounded focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          type="button"
          onClick={onClose}
        >
          <Icon type="16-close" size={16} />
        </button>
      )}
    </>
  );
};

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { markChatThreadsAsRead } from "@circle-react/api";
import { CHAT_THREADS_BANNER_LOCAL_STORAGE_KEY } from "@circle-react/components/constants";
import {
  useCurrentCommunityMember,
  useSpacesContext,
} from "@circle-react/contexts";
import {
  useChatThreadsItem,
  useChatThreadsList,
  useUnreadChatThreads,
} from "@circle-react/hooks/chatsV2";
import { isElementInViewPort } from "@circle-react/hooks/chatsV2/helper";
import { useLocalStorageState } from "@circle-react/hooks/utils/useLocalStorageState";
import { Loader } from "@circle-react-uikit/Loader";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { ChatThreadsBanner } from "./ChatThreadsBanner";
import { EmptyChatThreads } from "./EmptyChatThreads";
import { ThreadItem } from "./ThreadItem";
import { UnreadThreadIndicatorButton } from "./UnreadThreadIndicatorButton";
import { getSeparateSortedUnreadThreadIds } from "./helpers";

export const ChatThreadsList = ({ isRenderedOnHeader = false }) => {
  const currentCommunityMember = useCurrentCommunityMember();
  const [shouldShowChatThreadsBanner, setShouldShowChatThreadsBanner] =
    useLocalStorageState(CHAT_THREADS_BANNER_LOCAL_STORAGE_KEY, true);
  const [selectedReply, setSelectedReply] = useState({
    parentMessage: null,
    threadMessageId: null,
  });
  const [newBadgeIds, setNewBadgeIds] = useState(new Set());
  const { unreadChatThreadIds } = useUnreadChatThreads();
  const {
    chatThreads,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isChatThreadsAvailable,
    onEventReceive,
    handleReactionFromWebsocket,
    updateChatThreads,
  } = useChatThreadsList();

  const { threadMessages = [] } = useChatThreadsItem({
    parentMessage: selectedReply.parentMessage,
    threadMessageId: selectedReply.threadMessageId,
    successCallback: () => {
      setSelectedReply({
        parentMessage: null,
        threadMessageId: null,
      });
    },
  });

  const { records: spaces, isLoading: isSpacesContextLoading } =
    useSpacesContext();

  useEffect(() => {
    setNewBadgeIds(prev => new Set([...prev, ...unreadChatThreadIds]));
  }, [unreadChatThreadIds]);

  useEffect(() => {
    threadMessages.length > 0 && updateChatThreads(threadMessages);
  }, [threadMessages]);

  useWebSocket(
    {
      channel: "Chats::CommunityMemberThreadsChannel",
      onMessageReceive: data => {
        if (data.event === "newMessage") {
          markThreadAsReadIfInView(data.json_message);
        }
        onEventReceive(data);
      },
      community_member_id: currentCommunityMember?.id,
      canCreateConnection: Boolean(currentCommunityMember?.id),
    },
    [],
  );

  useWebSocket(
    {
      channel: "ChatRoomChannel",
      onMessageReceive: data => {
        if (
          data.event === "createReaction" ||
          data.event === "destroyReaction"
        ) {
          handleReactionFromWebsocket(data);
        }
      },
      community_member_id: currentCommunityMember?.id,
      canCreateConnection: Boolean(currentCommunityMember?.id),
    },
    [currentCommunityMember?.id],
  );

  const markThreadAsReadIfInView = message => {
    const threadId = message.chat_thread_id;
    if (!threadId) return;
    const threadElement = document.getElementById(threadId);
    if (isElementInViewPort(threadElement) && !message.unread_flow) {
      markChatThreadsAsRead(threadId);
    }
  };

  const handleWhenThreadInViewPort = (inView, thread) => {
    if (!inView) return;
    const { parent_message: parentMessage } = thread;
    if (unreadChatThreadIds.includes(parentMessage.chat_thread_id)) {
      markChatThreadsAsRead(parentMessage.chat_thread_id);
    }
  };

  if ((isLoading || isSpacesContextLoading) && !isChatThreadsAvailable) {
    return (
      <SkeletonLoader
        variant={
          isRenderedOnHeader
            ? "chat-threads-list-header"
            : "chat-threads-list-page"
        }
      />
    );
  }

  if (!isChatThreadsAvailable) {
    return <EmptyChatThreads />;
  }

  const { unreadThreadIdsAtTop, unreadThreadIdsAtBottom } =
    getSeparateSortedUnreadThreadIds(unreadChatThreadIds, chatThreads);

  return (
    <div className="w-full" id="threads-scroll-view">
      <UnreadThreadIndicatorButton
        unreadChatThreadIds={unreadThreadIdsAtTop}
        isTopButton
      />
      <ChatThreadsBanner
        shouldShowChatThreadsBanner={
          shouldShowChatThreadsBanner && !isRenderedOnHeader
        }
        hideBanner={() => setShouldShowChatThreadsBanner(false)}
      />
      <InfiniteScroll
        style={{
          overflow: "auto",
        }}
        scrollThreshold={0.8}
        next={fetchNextPage}
        hasMore={hasNextPage}
        dataLength={chatThreads.length}
        className={classNames({
          "mb-14 px-3 py-6 md:px-6 lg:mb-0": !isRenderedOnHeader,
          "mb-4 flex flex-col gap-y-2": isRenderedOnHeader,
        })}
        scrollableTarget={isRenderedOnHeader ? "chat-threads-header" : "panel"}
        loader={<Loader center />}
      >
        {chatThreads.map(thread => {
          const {
            parent_message: parentMessage,
            current_participant,
            chat_room,
          } = thread;

          const {
            embedded_space_id: embeddedSpaceId,
            name: chatRoomName,
            kind: chatRoomKind,
            lesson,
          } = chat_room;

          const { chat_room_uuid: chatRoomUuid, id: parentMessageId } =
            parentMessage;

          const space = spaces.find(item => item.id === embeddedSpaceId);
          const roomName = space ? space.name : chatRoomName;

          const chatRoomData = {
            uuid: chatRoomUuid,
            current_participant,
            chat_room_kind: chatRoomKind,
            embedded_space: space,
            roomName,
            lesson,
          };

          return (
            <ThreadItem
              key={parentMessageId}
              chatRoomData={chatRoomData}
              newBadgeIds={newBadgeIds}
              handleWhenThreadInViewPort={handleWhenThreadInViewPort}
              thread={thread}
              space={space}
              setSelectedReply={setSelectedReply}
              isRenderedOnHeader={isRenderedOnHeader}
            />
          );
        })}
      </InfiniteScroll>
      <UnreadThreadIndicatorButton
        unreadChatThreadIds={unreadThreadIdsAtBottom}
      />
    </div>
  );
};

ChatThreadsList.propTypes = {
  isRenderedOnHeader: PropTypes.bool,
};

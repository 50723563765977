import { useState } from "react";
import { t } from "i18n-js";
import { useMutation, useQuery } from "react-query";
import {
  reactQueryGet,
  reactQueryPatch,
} from "@/react/helpers/backendRequestHelpers";
import {
  homePageBannerPath,
  homePageMemberActivityPath,
  homePagePostsPath,
  homePageSpacesPath,
  homePageUpcomingEventsPath,
  internalApi,
} from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { useToast } from "@circle-react-uikit/ToastV2";

export const useHomePageApi = () => {
  const toast = useToast();
  const {
    data: { display_welcome_banner: displayWelcomeBanner },
  } = usePunditUserContext();
  const [posts, setPosts] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [spacesHasNextPage, setSpacesHasNextPage] = useState(false);
  const [participatingSpacesIds, setParticipatingSpacesIds] = useState(
    new Set(),
  );
  const [bannerData, setBannerData] = useState({});
  const [memberActivityData, setMemberActivityData] = useState([]);
  const [isWelcomeBannerVisible, setIsWelcomeBannerVisible] =
    useState(displayWelcomeBanner);

  const { refetch: fetchBannerData, isLoading: isBannerDataLoading } = useQuery(
    "BANNER_DATA",
    () => reactQueryGet(homePageBannerPath()),
    {
      onSuccess: data => {
        setBannerData(data.banner);
      },
      onError: err => {
        console.error(err);
      },
      enabled: false,
    },
  );

  const defaultFetchOptions = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "same-origin",
  };

  const fetchPosts = async () => {
    const { posts } = await reactQueryGet(homePagePostsPath());
    setPosts(posts);
    return posts;
  };

  const fetchSpaceSuggestions = fetchParams => {
    const { page, perPage = 9 } = fetchParams;

    fetch(
      homePageSpacesPath() + `?page=${page}&per_page=${perPage}`,
      defaultFetchOptions,
    )
      .then(r => r.json())
      .then(data => {
        if (page === 1) {
          setSpaces(data.spaces);
        } else {
          setSpaces([...spaces, ...data.spaces]);
        }
        setSpacesHasNextPage(data.has_next_page);
        setParticipatingSpacesIds(new Set(data.participating_space_ids));
      })
      .catch(error => {
        console.error(error);
      });
  };

  const fetchCommunityMemberActivityData = () => {
    fetch(homePageMemberActivityPath(), defaultFetchOptions)
      .then(r => r.json())
      .then(data => {
        setMemberActivityData(data.activity);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const { mutateAsync: setWelcomeBannerPreference } = useMutation(
    ({ communityMemberId, display }) =>
      reactQueryPatch(
        internalApi.communityMembers.preference({ id: communityMemberId }),
        {
          display_home_welcome_banner: display,
        },
      ),
    {
      onSuccess: () => {
        setIsWelcomeBannerVisible(false);
      },
      onError: () => {
        toast.error(t("request_failure_message"));
      },
    },
  );

  const fetchUpcomingEvents = async () => {
    const { events } = await reactQueryGet(homePageUpcomingEventsPath());
    return events;
  };

  return {
    posts,
    spaces,
    bannerData,
    memberActivityData,
    spacesHasNextPage,
    participatingSpacesIds,
    fetchPosts,
    fetchSpaceSuggestions,
    fetchBannerData,
    fetchCommunityMemberActivityData,
    fetchUpcomingEvents,
    isWelcomeBannerVisible,
    setWelcomeBannerPreference,
    setParticipatingSpacesIds,
    isBannerDataLoading,
  };
};

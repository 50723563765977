import type { StateCreator } from "zustand";
import { immer } from "zustand/middleware/immer";

interface Session {
  spectators: {
    shouldPoll: boolean;
    setShouldPoll: (shouldPoll: boolean) => void;
    isLoading: boolean;
    isFetching: boolean;
    loadFn: null | (() => Promise<void>);
    setLoadFn: (loadFn: () => Promise<void>) => void;
    load: () => void;
    hasNext: boolean;
    setHasNext: (hasNext: boolean) => void;
    loadMoreFn: null | (() => Promise<void>);
    setLoadMoreFn: (loadMoreFn: () => Promise<void>) => void;
    loadMore: () => void;
    total: number;
    setTotal: (total: number) => void;
    data: Array<any>;
    setData: (data: Array<any>) => void;
  };
}

export interface SessionSlice {
  session: Session;
}

export const createSessionSlice: StateCreator<
  SessionSlice,
  [],
  [["zustand/immer", never]],
  SessionSlice
> = immer(set => ({
  session: {
    spectators: {
      shouldPoll: true,
      setShouldPoll: (shouldPoll: boolean) =>
        set(state => {
          state.session.spectators.shouldPoll = shouldPoll;
        }),
      isLoading: false,
      isFetching: false,
      loadFn: null,
      setLoadFn: (loadFn: () => Promise<void>) =>
        set(state => {
          state.session.spectators.loadFn = loadFn;
        }),
      load: () =>
        set(state => {
          state.session.spectators.isLoading = true;
          const loadFn =
            state.session.spectators.loadFn ?? (() => Promise.resolve());
          void loadFn().finally(() => {
            state.session.spectators.isLoading = false;
          });
        }),
      hasNext: false,
      setHasNext: (hasNext: boolean) =>
        set(state => {
          state.session.spectators.hasNext = hasNext;
        }),
      loadMoreFn: null,
      setLoadMoreFn: (loadMoreFn: () => Promise<void>) =>
        set(state => {
          state.session.spectators.loadMoreFn = loadMoreFn;
        }),
      loadMore: () =>
        set(state => {
          state.session.spectators.isFetching = true;
          const loadMoreFn =
            state.session.spectators.loadMoreFn ?? (() => Promise.resolve());
          void loadMoreFn().finally(() => {
            state.session.spectators.isFetching = false;
          });
        }),
      total: 0,
      setTotal: (total: number) =>
        set(state => {
          state.session.spectators.total = total;
        }),
      data: [],
      setData: (data: Array<any>) =>
        set(state => {
          state.session.spectators.data = data;
        }),
    },
  },
}));

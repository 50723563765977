import {
  PaywallSubscriptionList,
  PaywallSubscriptionListContextProvider,
} from "@circle-react-shared/Paywalls/PaywallSubscriptionList";
import { EmptyPage } from "../../Filters/EmptyPage";
import { usePaywallSubscriptions } from "./hooks/usePaywallSubscriptions";

export const SubscriptionList = ({
  activeFilterTab,
  subscriptionsDataByTab,
  refetchAllSubscriptions,
}) => {
  const subscriptionData = subscriptionsDataByTab[activeFilterTab];
  const { onClickPagination, columnsToRender } = usePaywallSubscriptions({
    subscriptionData,
  });

  if (
    subscriptionData.hasFilters &&
    subscriptionData.totalSubscriptions === 0 &&
    !subscriptionData.isLoadingSubscriptions
  ) {
    return <EmptyPage />;
  }

  return (
    <PaywallSubscriptionListContextProvider
      shouldOpenAdminMemberEdit
      refetchAllSubscriptions={refetchAllSubscriptions}
    >
      <PaywallSubscriptionList
        key={activeFilterTab}
        columnsToRender={columnsToRender}
        subscriptionData={subscriptionData}
        onClickPagination={onClickPagination}
        className="px-6"
      />
    </PaywallSubscriptionListContextProvider>
  );
};

import { t } from "i18n-js";
import { FormTextSelect } from "@circle-react-shared/uikit/Form/FormTextSelect";
import type { PricingTabSelectOption } from "../types";

export interface PricingTabPriceModalSelectFieldProps {
  name: string;
  labelKey?: string;
  options: Array<PricingTabSelectOption>;
  required?: boolean;
}
export const PricingTabPriceModalSelectField = ({
  name,
  labelKey,
  options,
  required = false,
}: PricingTabPriceModalSelectFieldProps) => (
  <div className="grow leading-5">
    <FormTextSelect
      name={name}
      rules={{ required: required }}
      label={
        <>
          {t(
            `settings.paywalls.modal.tabs.pricing.add_price_modal.field_labels.${
              labelKey || name
            }`,
          )}
          {required && <span className="text-red-500">*</span>}
        </>
      }
      options={options}
    />
  </div>
);

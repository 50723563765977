import { createContext, useContext, useRef, useState } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { getLocalStorage, setLocalStorage } from "../utils";

export const SupportWidgetContext = createContext<any>({});
SupportWidgetContext.displayName = "SupportWidgetContext";

export const useSupportWidgetContext = () => {
  const context = useContext(SupportWidgetContext);
  if (!context) {
    throw new Error(
      "useSupportWidgetContext must be used within a SupportWidgetContextProvider",
    );
  }
  return context;
};

export const SupportWidgetContextProvider = ({
  children,
  has_active_conversations,
  unread_conversations,
  live_chat_enabled,
  support_widget_v2_enabled,
  display_onboarding,
}: any) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const [currentSpaceProperties, setCurrentSpaceProperties] = useState({
    name: "",
    emoji: "",
    custom_emoji_url: "",
    custom_emoji_dark_url: "",
  });

  const [isExpanded, setIsExpanded] = useState(
    () => getLocalStorage()?.isExpanded || false,
  );
  const expandWidget = () => {
    setLocalStorage({ ...getLocalStorage(), isExpanded: true });
    setIsExpanded(true);
  };
  const collapseWidget = () => {
    setLocalStorage({ ...getLocalStorage(), isExpanded: false });
    setIsExpanded(false);
  };
  const onWidgetClose = useRef<(() => void) | null>(null);
  const setOnWidgetClose = (callback: any) =>
    (onWidgetClose.current = callback);

  const [isOpen, setIsOpen] = useState(() =>
    isViewOnlyMasquerading ? false : getLocalStorage()?.isOpen || false,
  );
  const toggleWidget = ({ shouldTriggerOnWidgetClose = false } = {}) => {
    if (isViewOnlyMasquerading) {
      return;
    }

    if (
      shouldTriggerOnWidgetClose &&
      isOpen &&
      onWidgetClose.current !== null
    ) {
      onWidgetClose.current();
    } else {
      setLocalStorage({ ...getLocalStorage(), isOpen: !isOpen });
      setIsOpen(!isOpen);
    }
  };

  const formValues = getLocalStorage()?.formValues;
  const { text, type, area, is_important } = formValues || {};
  const [isDraftConversationPresent, setIsDraftConversationPresent] = useState(
    text || type || area || is_important,
  );

  const [showMessages, setShowMessages] = useState(has_active_conversations);
  const [unreadConversations, setUnreadConversations] =
    useState(unread_conversations);

  return (
    <SupportWidgetContext.Provider
      value={{
        isExpanded,
        expandWidget,
        collapseWidget,
        isOpen,
        toggleWidget,
        isDraftConversationPresent,
        setIsDraftConversationPresent,
        currentSpaceProperties,
        setCurrentSpaceProperties,
        showMessages,
        setShowMessages,
        unreadConversations,
        setUnreadConversations,
        supportWidgetV2Enabled: support_widget_v2_enabled,
        liveChatEnabled: live_chat_enabled,
        displayOnboarding: display_onboarding,
        setOnWidgetClose,
      }}
    >
      {children}
    </SupportWidgetContext.Provider>
  );
};

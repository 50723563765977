import PropTypes from "prop-types";
import classnames from "classnames";
import { useTrialBanner } from "@circle-react/components/Layout/TrialBanner/useTrialBanner";

export const TableContainer = ({ className, children }) => {
  const { shouldShowTrialBanner } = useTrialBanner();

  return (
    <div
      className={classnames("overflow-auto", className, {
        "lg:max-h-[calc(100vh-16rem)]": !shouldShowTrialBanner,
        "lg:max-h-[calc(100vh-19rem)]": shouldShowTrialBanner,
      })}
    >
      {children}
    </div>
  );
};

TableContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

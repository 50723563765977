import {
  selectPeerScreenSharing,
  selectScreenShareByPeerID,
  useHMSStore,
  useVideo,
} from "@100mslive/react-sdk";

export const useHMSScreenShareVideo = () => {
  const peer = useHMSStore(selectPeerScreenSharing);
  const videoTrack = useHMSStore(selectScreenShareByPeerID(peer?.id));
  const { videoRef } = useVideo({ trackId: videoTrack?.id });

  return {
    peer,
    videoRef,
    hasVideo: !!videoTrack?.enabled,
  };
};

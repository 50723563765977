import classNames from "classnames";
import { SpaceEmoji } from "@circle-react/components/Spaces/SpaceEmoji";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { useActiveSpaceMembersCount } from "@circle-react/contexts/useActiveSpaceMembersCount";
import { isMembersSpace } from "@circle-react/helpers/spaceHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { Space } from "@circle-react/types";
import { SpaceHeaderTitleText } from "./SpaceHeaderTitleText";

interface SpaceTitleWithEmojiProps {
  space: Space;
  shouldShowMembersCount?: boolean;
}

export const SpaceTitleWithEmoji = ({
  space,
  shouldShowMembersCount = false,
}: SpaceTitleWithEmojiProps) => {
  const { count, isLoading } = useActiveSpaceMembersCount({
    spaceId: space.id,
    enabled: shouldShowMembersCount,
  });
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames("flex items-center", {
        "gap-4": !isV3Enabled,
        "gap-2": isV3Enabled,
      })}
    >
      <SpaceEmoji
        className={classNames({
          "text-2xl": !isV3Enabled,
          "justify-center text-sm": isV3Enabled,
        })}
        space={space}
      />
      <div className="flex">
        <SpaceHeaderTitleText truncate isMembersSpace={isMembersSpace(space)}>
          {space.name}
        </SpaceHeaderTitleText>
        {shouldShowMembersCount && (
          <div className="ml-1">
            <Typography.BodyLg weight="semibold" tracking="tracking-normal">
              {!isLoading && `(${count})`}
            </Typography.BodyLg>
          </div>
        )}
      </div>
    </div>
  );
};

import { t } from "i18n-js";
import { useQuery } from "react-query";
import type { ShowBroadcastParams } from "@circle-react/api/broadcastsApi";
import { broadcastsApi } from "@circle-react/api/broadcastsApi";
import type { LinkClickRow } from "@circle-react-shared/Emails/BroadcastReport/LinkClicksStats/LinkClickStatsTable";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import type { BroadcastStatusValue } from "../components/Broadcasts/constants";
import { REFETCH_INTERVAL } from "../components/Broadcasts/constants";

interface ResponseData {
  id: number;
  internal_name: string;
  subject: string;
  status: BroadcastStatusValue;
  completed_at: string;
  sent: number;
  delivered: number;
  opened: number;
  clicked: number;
  unsubscribed: number;
  hard_bounced: number;
  complained: number;
  link_clicks: Array<LinkClickRow>;
  recipient_count: number;
}

export const getBroadcastReportQueryKey = (id: number) => [
  "broadcasts",
  id,
  "report",
];

export const useGetBroadcastReport = ({ id }: ShowBroadcastParams) => {
  const toastr = useToast();

  return useQuery<ResponseData>({
    queryKey: getBroadcastReportQueryKey(id),
    queryFn: () => broadcastsApi.report({ id }),
    onError: () => toastr.error(t("error")),
    refetchInterval: REFETCH_INTERVAL,
  });
};

import { useCallback, useEffect } from "react";
import { MobileAppBar } from "@/react/components/Layout/AppBar/MobileAppBar";
import { rewriteLoginRoutes } from "@/react/helpers/loginHelpers";
import { useIsLoadingAppData } from "@/react/hooks/useIsLoadingAppData";
import { useSpaceSlugFromUrl } from "@/react/hooks/useSpaceSlugFromUrl";
import { useSpacesContext } from "@circle-react/contexts";
import {
  isBasicPostsSpace,
  isEventsSpace,
} from "@circle-react/helpers/spaceHelpers";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { useIsAppBarVisible } from "../useIsAppBarVisible";

export const AppBar = () => {
  const { href } = window.location;
  const ref = useCallback(node => {
    if (node !== null) {
      rewriteLoginRoutes(node);
    }
  });

  useEffect(() => {
    rewriteLoginRoutes(ref.current);
  }, [href]);

  const slug = useSpaceSlugFromUrl();
  const spacesContext = useSpacesContext();

  const space = spacesContext.helpers.findBy({ slug });
  const isAppBarVisible = useIsAppBarVisible();
  const isLoadingAppData = useIsLoadingAppData();

  if (!isAppBarVisible) {
    return null;
  }

  if (isLoadingAppData) {
    return (
      <header className="header">
        <SkeletonLoader
          variant="app-bar-v2"
          showMemberAvatarsRow={
            space ? isEventsSpace(space) || isBasicPostsSpace(space) : false
          }
        />
      </header>
    );
  }

  return <MobileAppBar />;
};

import type { PropsWithChildren } from "react";
import classNames from "classnames";
import { Typography } from "@circle-react/components/shared/uikit/Typography";

interface SpaceHeaderTitleTextProps {
  truncate?: boolean;
  isMembersSpace?: boolean;
}

export const SpaceHeaderTitleText = ({
  children,
  truncate = false,
  isMembersSpace = false,
}: PropsWithChildren<SpaceHeaderTitleTextProps>) => (
  <div className="text-dark">
    <Typography.TitleSm
      as="h1"
      data-testid="space-title-name"
      weight="semibold"
    >
      {/* TODO Provisory until getting a better full width solution */}
      <span
        className={classNames({
          "block truncate md:max-w-[260px] lg:max-w-[200px] xl:max-w-[300px] 2xl:max-w-none":
            truncate,
          "max-w-[12ch]": isMembersSpace && truncate,
          "max-w-[200px] sm:max-w-[400px]": !isMembersSpace && truncate,
        })}
      >
        {children}
      </span>
    </Typography.TitleSm>
  </div>
);

import classNames from "classnames";
import { useQueryClient } from "react-query";
import { useRouteMatch } from "react-router-dom";
import { HOME_PAGE_POST_REQUEST_KEY } from "@circle-react/components/HomePageV2/usePostList";
import { useCurrentPostContext } from "@circle-react/contexts";
import { usePostList } from "@circle-react/hooks/posts/usePostList";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { CommentsSection } from "../PostViewListItem/CommentsSection";
import { EngagementActions } from "../PostViewListItem/EngagementActions";
import { PostBody } from "../PostViewListItem/PostBody";
import { PostCoverImage } from "../PostViewListItem/PostCoverImage";
import { PostHeader } from "../PostViewListItem/PostHeader";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";
import { useCommentFocus } from "./useCommentFocus";

export interface ModalBodyProps {
  autofocusReplyForm?: boolean;
  isRenderedOutsideTheSpace?: boolean;
  onPostDestroy: () => void;
  onPostResourceChange: (post: any, options: any) => void;
  post: any;
  shouldOpenCommentPage?: boolean;
  spaceId: any;
}

export const ModalBody = ({
  autofocusReplyForm = true,
  isRenderedOutsideTheSpace = false,
  onPostDestroy,
  onPostResourceChange,
  post,
  shouldOpenCommentPage = false,
  spaceId,
}: ModalBodyProps) => {
  const {
    record: { is_comments_enabled },
  } = useCurrentPostContext();
  const { isPostBodyEmpty } = useCurrentPostHelpers();
  const { refetch: refetchPosts } = usePostList();
  const queryClient = useQueryClient();
  const isHomePage = useRouteMatch("/home")?.isExact;
  const postListQueryKey = isHomePage
    ? [HOME_PAGE_POST_REQUEST_KEY]
    : ["posts", spaceId];
  const doesPostHaveCoverImage = Boolean(post?.cover_image_url);

  const {
    commentsFormRef,
    showCommentsAndFocusOnForm,
    toggleComment,
    shouldShowComments,
    shouldAutofocusReplyForm,
  } = useCommentFocus({
    initialFocus: autofocusReplyForm,
  });

  return (
    <Modal.Body className="!px-0 !pt-0">
      <div className="flex flex-col gap-5">
        <PostCoverImage post={post} className="md:!rounded-none" />
        <div
          className={classNames("px-5 md:px-8", {
            "pt-3": !doesPostHaveCoverImage,
          })}
        >
          <PostHeader
            post={post}
            onChange={(post: any, options: any) => {
              void onPostResourceChange(post, options);
              void queryClient.invalidateQueries(postListQueryKey);
            }}
            refetchPosts={refetchPosts}
            hideShareLink
            hideBookmarkButton
            hidePinnedToTopIcon
            onPostDestroy={onPostDestroy}
            hideAuthorHeadline={false}
            tagsLimit={1}
            tagsShowMoreLabelVariant="numbers"
            metaInfoWrapperClassName=""
            isRenderedOutsideTheSpace={isRenderedOutsideTheSpace}
          />
          <div
            className={classNames("space-y-5", {
              "mt-5": isPostBodyEmpty,
            })}
          >
            <PostBody />
            <div>
              <EngagementActions
                className="!px-0"
                toggleComment={toggleComment}
                showCommentAndFocusCommentForm={showCommentsAndFocusOnForm}
                shouldOpenCommentPage={shouldOpenCommentPage}
              />
              {is_comments_enabled && shouldShowComments && (
                <CommentsSection
                  commentsFormRef={commentsFormRef}
                  autofocusReplyForm={shouldAutofocusReplyForm}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

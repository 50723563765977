import { useMemo } from "react";
import { useCommunitySwitcher } from "@circle-react/hooks/community_switcher/useCommunitySwitcherApi";
import { CommunitySection } from "./CommunitySection";
import { CommunitySwitcherPresentationV3 } from "./CommunitySwitcherPresentationV3";
import { useSeeMyCommunitiesModal } from "./MyCommunitiesModal";

export const MAX_COMMUNITIES_TO_DISPLAY = 5;

export interface CommunitySwitcherContentV3Props {
  isLoadingAppData?: boolean;
}

export const CommunitySwitcherContentV3 = ({
  isLoadingAppData = false,
}: CommunitySwitcherContentV3Props) => {
  const { communities, token, isLoadingCommunities } = useCommunitySwitcher();
  const myCommunitiesModal = useSeeMyCommunitiesModal();

  const isLoading = isLoadingAppData || isLoadingCommunities;
  const shouldShowShowMoreButton =
    communities.length > MAX_COMMUNITIES_TO_DISPLAY;

  const onClickSeeMyCommunities = () => {
    void myCommunitiesModal.show({
      communities,
      token,
    });
  };

  const orderedCommunities = useMemo(() => {
    const currentCommunityIndex = communities.findIndex(
      community => community.is_current_community,
    );

    if (currentCommunityIndex > MAX_COMMUNITIES_TO_DISPLAY) {
      const currentCommunity = communities[currentCommunityIndex];
      communities.splice(currentCommunityIndex, 1);
      communities.unshift(currentCommunity);
    }
    return communities;
  }, [communities]);

  return (
    <CommunitySwitcherPresentationV3
      id="community-switcher"
      isLoading={isLoading}
      shouldShowShowMoreButton={shouldShowShowMoreButton}
      onClickSeeMyCommunities={onClickSeeMyCommunities}
    >
      {orderedCommunities
        .slice(0, MAX_COMMUNITIES_TO_DISPLAY)
        .map((community, index) => (
          <CommunitySection
            key={community.id}
            community={community}
            token={token}
            index={index}
          />
        ))}
    </CommunitySwitcherPresentationV3>
  );
};

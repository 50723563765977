import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { PricingTabAddPriceModal } from "./AddPriceModal";
import { PricingTabEmptyState } from "./EmptyState";
import { PricingTabPriceList } from "./PriceList";
import { PricingSettings } from "./PricingSettings";
import { usePricingTabContext } from "./usePricingTabContext";

export const PricingTabContent = () => {
  const { getPrices, isShowingAddPriceModal } = usePricingTabContext();
  const prices = getPrices();

  return (
    <div className="flex h-[60vh] flex-col gap-x-5 gap-y-4 overflow-auto px-4">
      {!prices || !prices.length ? (
        <PricingTabEmptyState />
      ) : (
        <div className="flex flex-col gap-y-8 pt-7">
          <Typography.TitleSm weight="semibold">
            {t("settings.paywalls.modal.tabs.pricing.title")}
          </Typography.TitleSm>
          <PricingTabPriceList />
          <PricingSettings />
        </div>
      )}
      {isShowingAddPriceModal() && <PricingTabAddPriceModal />}
    </div>
  );
};

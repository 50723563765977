import { useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { usePaywallGroupApi } from "@circle-react/components/PaywallGroups/Admin/hooks/usePaywallGroupApi";
import { usePricingTabContext } from "../usePricingTabContext";

export const useSubscriptionGroup = currency => {
  const { setValue } = useFormContext();
  const { hasRecurringPrices } = usePricingTabContext();
  const { useListPaywallGroups } = usePaywallGroupApi({});
  const { data: paywallGroups } = useListPaywallGroups();

  const eligiblePaywallGroups = (paywallGroups || []).filter(
    group => group.currency.id === currency.id,
  );
  const canShowChooser = eligiblePaywallGroups.length > 0;
  const canEnableChooser = hasRecurringPrices();
  const defaultPaywallGroupId =
    eligiblePaywallGroups.length > 0 ? eligiblePaywallGroups[0].id : "";
  const paywallGroupSelectOptions = eligiblePaywallGroups.map(group => ({
    value: `${group.id}`,
    label: group.name,
  }));

  const isPaywallEditingFlow = useWatch({ name: "id" });
  const enableSubscriptionGroup = useWatch(
    {
      name: "enable_subscription_group",
    },
    false,
  );

  const enableSubscriptionGroupPreviousState = useRef(enableSubscriptionGroup);

  // This useEffect updates the Subscription Group Toggle in the following conditions:
  // PR: #9081
  //
  // When creating a paywall:
  //  - The previous state for this toggle is off;
  //  - Has subscription prices;
  //  - Updates toggle;
  //
  // When editing a paywall:
  //  - When there is not subscription prices, disable toggle;
  useEffect(() => {
    const currentState = enableSubscriptionGroupPreviousState.current;
    const creationFlow =
      !isPaywallEditingFlow && !currentState && hasRecurringPrices();

    if (creationFlow || !hasRecurringPrices()) {
      setValue("enable_subscription_group", hasRecurringPrices());
    }

    // Set default group id when it is disabled and enabled again
    if (!currentState && currentState !== enableSubscriptionGroup) {
      setValue("paywall_group_id", defaultPaywallGroupId);
    }

    enableSubscriptionGroupPreviousState.current = enableSubscriptionGroup;
  }, [
    defaultPaywallGroupId,
    enableSubscriptionGroup,
    hasRecurringPrices(),
    isPaywallEditingFlow,
    setValue,
  ]);

  return {
    canShowChooser,
    canEnableChooser,
    enableSubscriptionGroup,
    paywallGroupSelectOptions,
  };
};

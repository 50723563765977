import type { StateCreator } from "zustand";
import { immer } from "zustand/middleware/immer";

export const TABS = Object.freeze({
  CHAT: "CHAT",
  PARTICIPANTS: "PARTICIPANTS",
});

export interface UISlice {
  ui: {
    isSidebarOpen: boolean;
    setIsSidebarOpen: (isSidebarOpen: boolean) => void;
    hideSidebar: () => void;
    currentTab: string;
    setCurrentTab: (tab: string) => void;
    displayChat: () => void;
    toggleChat: () => void;
    displayParticipants: () => void;
    toggleParticipants: () => void;
    isCohostsModalOpen: boolean;
    setIsCohostsModalOpen: (isOpen: boolean) => void;
    displayCohostsModal: () => void;
    hideCohostsModal: () => void;
    isMuteParticipantsModalOpen: boolean;
    setIsMuteParticipantsModalOpen: (isOpen: boolean) => void;
    displayMuteParticipantsModal: () => void;
    hideMuteParticipantsModal: () => void;
    hasUnseenChatMessages: boolean;
    setHasUnseenChatMessages: (hasUnseenChatMessages: boolean) => void;
    onNewChatMessage: () => void;
    isRoomInfoOpen: boolean;
    setIsRoomInfoOpen: (isOpen: boolean) => void;
    toggleIsRoomInfoOpen: () => void;
    isFullscreen: boolean;
    setIsFullscreen: (isFullscreen: boolean) => void;
    toggleFullscreen: () => void;
    isPublicStreamNotificationVisible: boolean;
    isPublicStreamNotificationDismissed: boolean;
    setPublicStreamNotificationVisible: (isVisible: boolean) => void;
    hidePublicStreamNotification: () => void;
  };
}

export const createUISlice: StateCreator<
  UISlice,
  [],
  [["zustand/immer", never]],
  UISlice
> = immer((set, get) => ({
  ui: {
    isSidebarOpen: false,
    setIsSidebarOpen: isSidebarOpen =>
      set(state => {
        state.ui.isSidebarOpen = isSidebarOpen;
      }),
    hideSidebar: () =>
      set(state => {
        state.ui.isSidebarOpen = false;
      }),
    currentTab: TABS.CHAT,
    setCurrentTab: tab =>
      set(state => {
        state.ui.currentTab = tab;
      }),
    displayChat: () =>
      set(state => {
        state.ui.isSidebarOpen = true;
        state.ui.currentTab = TABS.CHAT;
        state.ui.hasUnseenChatMessages = false;
      }),
    toggleChat: () => {
      if (!get().ui.isSidebarOpen || get().ui.currentTab !== TABS.CHAT) {
        get().ui.displayChat();
      } else {
        get().ui.hideSidebar();
      }
    },
    displayParticipants: () =>
      set(state => {
        state.ui.isSidebarOpen = true;
        state.ui.currentTab = TABS.PARTICIPANTS;
        state.ui.hasUnseenChatMessages = false;
      }),
    toggleParticipants: () => {
      if (
        !get().ui.isSidebarOpen ||
        get().ui.currentTab !== TABS.PARTICIPANTS
      ) {
        get().ui.displayParticipants();
      } else {
        get().ui.hideSidebar();
      }
    },
    isCohostsModalOpen: false,
    setIsCohostsModalOpen: isOpen =>
      set(state => {
        state.ui.isCohostsModalOpen = isOpen;
      }),
    displayCohostsModal: () => {
      get().ui.displayParticipants();
      get().ui.setIsCohostsModalOpen(true);
    },
    hideCohostsModal: () => {
      get().ui.setIsCohostsModalOpen(false);
    },
    isMuteParticipantsModalOpen: false,
    setIsMuteParticipantsModalOpen: isOpen =>
      set(state => {
        state.ui.isMuteParticipantsModalOpen = isOpen;
      }),
    displayMuteParticipantsModal: () => {
      get().ui.setIsMuteParticipantsModalOpen(true);
    },
    hideMuteParticipantsModal: () => {
      get().ui.setIsMuteParticipantsModalOpen(false);
    },
    hasUnseenChatMessages: false,
    setHasUnseenChatMessages: hasUnseenChatMessages =>
      set(state => {
        state.ui.hasUnseenChatMessages = hasUnseenChatMessages;
      }),
    onNewChatMessage: () => {
      if (!get().ui.isSidebarOpen || get().ui.currentTab !== TABS.CHAT) {
        get().ui.setHasUnseenChatMessages(true);
      }
    },
    isRoomInfoOpen: false,
    setIsRoomInfoOpen: isOpen =>
      set(state => {
        state.ui.isRoomInfoOpen = isOpen;
      }),
    toggleIsRoomInfoOpen: () =>
      set(state => {
        state.ui.isRoomInfoOpen = !state.ui.isRoomInfoOpen;
      }),
    isFullscreen: false,
    setIsFullscreen: isFullscreen =>
      set(state => {
        state.ui.isFullscreen = isFullscreen;
      }),
    toggleFullscreen: () =>
      set(state => {
        if (state.ui.isFullscreen) {
          void document.exitFullscreen();
        } else {
          void document.documentElement.requestFullscreen();
        }
      }),
    isPublicStreamNotificationVisible: false,
    isPublicStreamNotificationDismissed: false,
    setPublicStreamNotificationVisible: isVisible =>
      set(state => {
        state.ui.isPublicStreamNotificationVisible = isVisible;
      }),
    hidePublicStreamNotification: () =>
      set(state => {
        state.ui.isPublicStreamNotificationVisible = false;
        state.ui.isPublicStreamNotificationDismissed = true;
      }),
  },
}));

import { useFormContext } from "react-hook-form";
import { useGetPaywallsCurrencies } from "@circle-react/hooks/paywalls/useGetPaywallsCurrencies";
import { Loader } from "@circle-react-uikit/Loader";
import { PricingTabContent } from "./Content";
import { PricingTabContextProvider } from "./usePricingTabContext";

export const PricingTab = () => {
  const { watch } = useFormContext();

  const { data: paywallsCurrencies, isLoading: isLoadingPaywallsCurrencies } =
    useGetPaywallsCurrencies();
  if (isLoadingPaywallsCurrencies) {
    return <Loader />;
  }

  const currencyId = watch("currency_id");
  const currency = paywallsCurrencies.find(
    currency => currency.id === parseInt(currencyId),
  );

  return (
    <PricingTabContextProvider currency={currency}>
      <PricingTabContent />
    </PricingTabContextProvider>
  );
};

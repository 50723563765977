import qs from "qs";
import {
  fetchRequest,
  reactQueryPost,
  serializeJsonResponse,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import type { PaymentMethodType } from "@circle-react/helpers/paywallCheckoutHelpers";

export interface PrepareRequestEndpoint {
  name: string;
  email: string;
  payment_method_type: keyof typeof PaymentMethodType;
  paywall_price_id: number;
  community_id: number;
  coupon_code?: string;
  community_member_billing_info_attributes: {
    tax_id_type: string;
    tax_id_value: string;
  };
}

export interface PrepareResponseEndpoint {
  setup_intent: string;
}

export interface PixPrepareResponseEndpoint {
  payment_intent_id: string;
  customer_id: string;
  payment_intent_client_secret: string;
}

const show = (paywallCheckoutPath: any) =>
  fetchRequest(
    internalApi.paywallCheckout.show({
      paywallCheckoutPath,
    }),
    {
      method: "GET",
    },
  );

const preview = async ({
  queryParams,
  shouldIgnoreCommunityMember,
  locale,
}: any) => {
  const previewUrl = `${internalApi.paywallCheckout.preview()}${qs.stringify({
    checkout: queryParams,
    ...(locale ? { locale } : {}),
  })}`;

  const response = await fetch(previewUrl, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: shouldIgnoreCommunityMember ? "omit" : "same-origin",
  });

  return serializeJsonResponse(response);
};

const prepare = (
  params: PrepareRequestEndpoint,
): Promise<PixPrepareResponseEndpoint> =>
  reactQueryPost(internalApi.paywallCheckout.prepare(), {
    checkout: params,
  });

const create = ({ formData }: any) =>
  reactQueryPost(internalApi.paywallCheckout.create(), {
    checkout: formData,
  });

const prepare_DEPRECATED = (communityId: string) =>
  fetchRequest(internalApi.paywallCheckout.prepare(), {
    method: "POST",
    body: JSON.stringify({ checkout: { community_id: communityId } }),
  });

const create_DEPRECATED = ({ formData }: any) =>
  fetchRequest(internalApi.paywallCheckout.create(), {
    method: "POST",
    body: JSON.stringify({ checkout: formData }),
  });

export const paywallCheckoutApi = {
  show,
  preview,
  prepare,
  create,
  prepare_DEPRECATED,
  create_DEPRECATED,
};

import { useFetchMemberTag } from "@circle-workflows/hooks/useFetchMemberTag";
import { t } from "i18n-js";
import isEmpty from "lodash/isEmpty";
import { BaseResource } from "../../BaseResource";

const i18nRoot = "settings.workflows.view";

export const MemberTagResource = ({ resourceId }: { resourceId: number }) => {
  const { data: memberTag = {}, isLoading } = useFetchMemberTag({
    id: resourceId,
  });

  if (!memberTag) {
    return null;
  }

  const badgeText = isEmpty(memberTag)
    ? t([i18nRoot, "deleted_member_tag"])
    : memberTag.name;

  return (
    <BaseResource
      label={t([i18nRoot, "resources.member_tag"])}
      value={badgeText}
      isLoading={isLoading}
      shouldRepresentAsBadge
    />
  );
};

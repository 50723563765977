import type { SetStateAction } from "react";
import { useEffect, useState } from "react";
import { useModal } from "@ebay/nice-modal-react";
import { t } from "i18n-js";
import { BookmarkForm } from "@circle-react/components/Spaces/Bookmarks/BookmarkForm";
import type { Space } from "@circle-react/types";
import type { Option } from "@circle-react-shared/RadioButtonGroup";
import { RadioButtonGroup } from "@circle-react-shared/RadioButtonGroup";
import { Button } from "@circle-react-shared/uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { BookmarkFormFooter } from "./BookmarkFormFooter";

const SPACE_LINKS_OPTIONS: Option[] = [
  {
    label: t("add_a_link"),
    value: "link",
    icon: "16-link",
    iconSize: 16,
  },
  {
    label: t("add_a_folder"),
    value: "folder",
    icon: "16-folder",
    iconSize: 16,
  },
];

export interface AddSpaceLinksModalProps {
  space: Space;
}

export const SpaceLinksModal = ({ space }: AddSpaceLinksModalProps) => {
  const modal = useModal();
  const [bookmarkType, setBookmarkType] = useState<"link" | "folder">("link");
  const [isBookmarksFormVisible, setIsBookmarksFormVisible] = useState(false);

  const onRadioChangeHandler = (value: SetStateAction<"link" | "folder">) => {
    setBookmarkType(value);
  };

  useEffect(() => {
    if (!modal.visible) {
      setBookmarkType("link");
      setIsBookmarksFormVisible(false);
    }
  }, [modal.visible]);

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title className="text-center" size="md">
            {t("add_space_links")}
          </Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={modal.hide} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          {!isBookmarksFormVisible ? (
            <div className="mt-4">
              <RadioButtonGroup
                name="spaceLinkType"
                defaultChecked={bookmarkType}
                options={SPACE_LINKS_OPTIONS}
                size="sm"
                onChange={onRadioChangeHandler}
                hasRadioCheckmarks
              />
              <Modal.Footer className="flex justify-end gap-2 !px-0">
                <Button
                  type="button"
                  variant="secondary"
                  large
                  onClick={modal.hide}
                >
                  {t("cancel")}
                </Button>
                <Button
                  type="button"
                  variant="circle"
                  large
                  onClick={() => setIsBookmarksFormVisible(true)}
                >
                  {t("next")}
                </Button>
              </Modal.Footer>
            </div>
          ) : (
            <BookmarkForm
              key={bookmarkType}
              space={space}
              isEdit={false}
              isFolder={bookmarkType === "folder"}
              renderFooter={({ isSubmitting }) => (
                <BookmarkFormFooter
                  isSubmitting={isSubmitting}
                  onClickCancel={() => setIsBookmarksFormVisible(false)}
                />
              )}
              onAfterCreateOrUpdate={modal.hide}
            />
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

import { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line no-restricted-imports -- False positive since it's a type import
import type { HMSEffectsPlugin } from "@100mslive/hms-virtual-background";
import {
  selectEffectsKey,
  selectIsLocalVideoPluginPresent,
  selectLocalVideoTrackID,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

const BLUR_POWER = 0.3;
export const useHMSVirtualBackground = () => {
  const [VBPlugin, setVBPlugin] = useState<HMSEffectsPlugin | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const effectsKey = useHMSStore(selectEffectsKey);
  const hmsActions = useHMSActions();
  const pluginName = VBPlugin?.getName() || "";
  const isEnabled = useHMSStore(selectIsLocalVideoPluginPresent(pluginName));
  const localPeerVideoTrackID = useHMSStore(selectLocalVideoTrackID);

  // initialize Virtual Background Plugin when effectsKey is available
  useEffect(() => {
    async function initializePlugin() {
      if (!VBPlugin && effectsKey) {
        const module = await import(
          /* webpackChunkName: "VirtualBackground" */ "@100mslive/hms-virtual-background"
        );
        setVBPlugin(new module.HMSEffectsPlugin(effectsKey));
      }
    }
    void initializePlugin();
  }, [effectsKey, VBPlugin]);

  // Add Virtual Background Plugin to local video track once it's available
  useEffect(() => {
    async function enablePlugin() {
      if (localPeerVideoTrackID && !isEnabled && VBPlugin) {
        try {
          await hmsActions.addPluginsToVideoStream([VBPlugin]);
        } catch (e: any) {
          setErrorMessage(e.message);
        }
      }
    }

    void enablePlugin();
  }, [localPeerVideoTrackID, hmsActions, isEnabled, VBPlugin]);

  const addBlur = useCallback(() => {
    isEnabled && VBPlugin?.setBlur(BLUR_POWER);
  }, [VBPlugin, isEnabled]);

  const removeBlur = useCallback(() => {
    isEnabled && VBPlugin?.removeBlur();
  }, [VBPlugin, isEnabled]);

  return {
    addBlur,
    removeBlur,
    isEnabled,
    errorMessage,
  };
};

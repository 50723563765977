/* eslint-disable eslint-comments/no-restricted-disable -- TODO: Refactor this file */

/* eslint-disable sonarjs/cognitive-complexity -- TODO: Refactor this file */
import { CardElement } from "@stripe/react-stripe-js";
import { useMutation } from "react-query";
import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { communityMemberChargeApi } from "@circle-react/api/communityMemberChargeApi";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { isPaid } from "@circle-react/helpers/communityMemberChargeHelpers";
import { memberProfileModalPaths } from "@circle-react/helpers/urlHelpers";
import {
  GENERAL_ERROR,
  STRIPE_CONNECTION_ERROR,
  buildErrorMessage,
  getReferral,
} from "../../helpers";
import { usePaymentMethodDataParams } from "./usePaymentMethodDataParams";

const PAYMENT_POLLING_TIMEOUT_MILLISECONDS = 2000;
const PAYMENT_POLLING_MAX_TRIES = 30;

export const redirectToCheckoutConfirmation = (
  checkoutResponse: any,
  checkoutConfirmationUrl: string,
) => {
  const processorId =
    checkoutResponse?.subscription_processor_id ||
    checkoutResponse?.payment_intent_processor_id ||
    checkoutResponse?.invoice_processor_id;
  const url = `${checkoutConfirmationUrl}${processorId}`;

  window.webview?.paymentSuccessful?.();
  return window.open(url, "_self");
};

export const useCard = ({ stripe, elements }: any) => {
  const {
    paywall,
    currentCommunity,
    checkoutConfirmationUrl,
    loginUrl,
    communityRootUrl,
    handleError,
    setIsProcessingPayment,
    getSelectedPrice,
    isCardInfoRequired,
    previewMutation,
    isPaywallDigitalWalletEnabled,
  } = usePaywallCheckoutContext();

  const { paymentMethodDataParams } = usePaymentMethodDataParams();

  const handleCheckoutError = (error: any) => {
    handleError(
      buildErrorMessage(error, {
        loginUrl,
        billingUrl: `${communityRootUrl}${memberProfileModalPaths.billing.slice(
          1,
        )}`,
        eventUrl: paywall?.event_url ?? "#",
      }),
    );
  };

  const selectedPrice = getSelectedPrice();
  if (isPaywallDigitalWalletEnabled && elements && previewMutation.data) {
    elements.update({ amount: previewMutation.data.amount_due_now });
  }

  const verifyPayment = async (checkoutResponseBody: any, tries: any) => {
    // Poll backend to check payment status every 2 seconds for 30 times
    const {
      payment_intent_processor_id: paymentIntentProcessorId,
      invoice_processor_id: invoiceProcessorId,
    } = checkoutResponseBody;

    if (tries > PAYMENT_POLLING_MAX_TRIES) {
      console.error("Error: Payment polling timed out");
      handleError(GENERAL_ERROR);
      return;
    }

    const processorId = paymentIntentProcessorId ?? invoiceProcessorId;
    const response = await communityMemberChargeApi.show(processorId);

    if (response.ok) {
      const charge = await response.json();
      if (isPaid(charge)) {
        return redirectToCheckoutConfirmation(
          checkoutResponseBody,
          checkoutConfirmationUrl,
        );
      }
    }
    await new Promise(resolve =>
      setTimeout(resolve, PAYMENT_POLLING_TIMEOUT_MILLISECONDS),
    );
    await verifyPayment(checkoutResponseBody, tries + 1);
    return undefined;
  };

  const processPaymentInStripe = async (checkoutResponseBody: any) => {
    const {
      payment_intent_processor_id: paymentIntentProcessorId,
      payment_intent_client_secret: paymentIntentClientSecret,
    } = checkoutResponseBody;

    if (!stripe || !elements) {
      handleError(STRIPE_CONNECTION_ERROR); // Stripe.js has not yet loaded.
      return;
    }
    if (!paymentIntentProcessorId && !paymentIntentClientSecret) {
      // No payment intent? We are looking at a "free" subscription/one-time payment. In these
      await verifyPayment(checkoutResponseBody, 0); // cases, no payment occurs, so no need to confirm with Stripe just with our backend.
      return;
    }
    const paymentIntentResult = await stripe.retrievePaymentIntent(
      // TODO: move payment confirmation to the backend once the checkout process for subscriptions gets refactored.
      paymentIntentClientSecret,
    );
    if (paymentIntentResult.error) {
      console.error("Error: Payment intent error");
      handleError({
        message: paymentIntentResult.error.message,
        disablePayButton: true,
      });
      return;
    }

    const paymentIntent = paymentIntentResult.paymentIntent;
    if (paymentIntent.status === "canceled") {
      console.error("Error: Payment intent canceled");
      handleError(GENERAL_ERROR);
      return;
    }
    if (paymentIntent.status === "succeeded") {
      await verifyPayment(checkoutResponseBody, 0);
      return;
    }

    let confirmPaymentResult;
    if (isPaywallDigitalWalletEnabled) {
      confirmPaymentResult = await stripe.confirmPayment({
        elements,
        clientSecret: checkoutResponseBody.payment_intent_client_secret,
        confirmParams: {
          return_url: window.location.href,
          ...paymentMethodDataParams,
        },
        redirect: "if_required",
      });
    } else {
      confirmPaymentResult = await stripe.confirmCardPayment(
        checkoutResponseBody.payment_intent_client_secret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        },
      );
    }

    if (confirmPaymentResult.error) {
      console.error("Error: Payment confirmation error");
      handleError({
        message: confirmPaymentResult.error.message,
        disablePayButton: false,
      });
    } else if (confirmPaymentResult.paymentIntent.status !== "succeeded") {
      console.error("Error: Payment intent did not succeed");
      handleError(GENERAL_ERROR);
    } else {
      await verifyPayment(checkoutResponseBody, 0);
    }
  };

  const onSubmitMutation = useMutation<any, any, any>(async data => {
    const formData = data;
    if (getReferral()) {
      formData.referral_metadata = getReferral();
    }
    setIsProcessingPayment(true);
    try {
      if (isCardInfoRequired) {
        if (isPaywallDigitalWalletEnabled) {
          // Trigger form validation and wallet collection
          const { error: submitError } = await elements.submit();
          if (submitError) {
            console.error("Error: Form validation and wallet collection error");
            handleError({
              message: submitError.message,
              disablePayButton: false,
            });
            setIsProcessingPayment(false);
            return;
          }
        }

        // Request BE to create a SetupIntent on Stripe.
        const prepareResponse = await paywallCheckoutApi.prepare_DEPRECATED(
          currentCommunity.id,
        );
        if (!prepareResponse.ok) {
          const error = await prepareResponse.json();
          handleCheckoutError(error);
          setIsProcessingPayment(false);
          return;
        }
        const prepareResponseBody = await prepareResponse.json();
        const setupIntentSecret =
          prepareResponseBody.setup_intent.client_secret;

        // Request Stripe to confirm setup (`stripe.confirmSetup`).
        let setupIntentResult = await stripe.retrieveSetupIntent(
          setupIntentSecret,
        );
        if (
          !setupIntentResult.setupIntent ||
          setupIntentResult.setupIntent.status !== "succeeded"
        ) {
          if (isPaywallDigitalWalletEnabled) {
            setupIntentResult = await stripe.confirmSetup({
              elements,
              clientSecret: setupIntentSecret,
              confirmParams: {
                return_url: window.location.href,
                ...paymentMethodDataParams,
              },
              redirect: "if_required",
            });
          } else {
            setupIntentResult = await stripe.confirmCardSetup(
              setupIntentSecret,
              {
                payment_method: {
                  card: elements.getElement(CardElement),
                },
              },
            );
          }
        }
        if (setupIntentResult.error) {
          console.error("Error: Setup error");
          handleError({
            message: setupIntentResult.error.message,
            disablePayButton: false,
          });
          setIsProcessingPayment(false);
          return;
        }
        const paymentMethodId = setupIntentResult.setupIntent.payment_method;

        formData.payment_method_processor_id = paymentMethodId; // Pass payment method ID for checkout fulfillment.
      }

      // 3. Request BE to fulfill order (checkout) Create payment intent in stripe and user if needed
      const checkoutResponse = await paywallCheckoutApi.create_DEPRECATED({
        formData,
      });

      if (checkoutResponse.ok) {
        const checkoutResponseBody = await checkoutResponse.json();

        if (selectedPrice.trial_enabled) {
          return redirectToCheckoutConfirmation(
            checkoutResponseBody,
            checkoutConfirmationUrl,
          );
        }

        // let's create the payment in Stripe using payment intent secret key
        await processPaymentInStripe(checkoutResponseBody);
      } else {
        const checkoutResponseError = await checkoutResponse.json();
        handleCheckoutError(checkoutResponseError);
      }
    } catch (error) {
      notifyBugsnag(error);
      console.error("Error: unexpected error occurred: ", error);
      handleError(GENERAL_ERROR);
    }
    setIsProcessingPayment(false);
    return undefined;
  });

  return {
    onSubmitMutation,
  };
};

import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { TRIGGER_RESOURCE_TYPE } from "@circle-react/components/SettingsApp/Workflows/constants";
import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react/components/shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";
import { usePunditUserContext } from "@circle-react/contexts";
import { availableEvents } from "../Events";

export const useReleasedEvents = () => {
  const supportUnreleasedWorkflowsFeaturesEnabled =
    useSupportUnreleasedWorkflowsFeaturesEnabled();
  const { currentCommunitySettings } = usePunditUserContext();
  const { data: gamificationSettings } = useGamificationSettings();
  const isGamificationEnabled = currentCommunitySettings?.gamification_enabled;
  const shouldShowGamificationLeveledUpEvent =
    gamificationSettings?.enabled && isGamificationEnabled;

  let allAvailableEvents = availableEvents;
  if (!shouldShowGamificationLeveledUpEvent) {
    allAvailableEvents = allAvailableEvents.filter(
      availableEvent =>
        availableEvent.resource !== TRIGGER_RESOURCE_TYPE.GAMIFICATION_LEVEL,
    );
  }

  const releasedEvents = supportUnreleasedWorkflowsFeaturesEnabled
    ? allAvailableEvents
    : allAvailableEvents.filter(
        availableEvent => availableEvent.released !== false,
      );

  return { releasedEvents };
};

import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import type { ActionOption } from "../types";
import { FormTag } from "./shared/FormTag";
import { HistoryDetailViewTag } from "./shared/HistoryDetailViewTag";
import type { HistoryDetailViewTagProps } from "./shared/HistoryDetailViewTag/HistoryDetailViewTag";
import { ViewTag } from "./shared/ViewTag";

export const removeTag: Readonly<ActionOption<HistoryDetailViewTagProps>> = {
  label: t("settings.workflows.form.actions.remove_tag"),
  value: "remove_tag_from_community_member",
  group: t("settings.workflows.form.action_groups.tags"),
  leftAddon: <Icon type="16-remove-tags" size={16} className="!h-4 !w-4" />,
  formComponent: FormTag,
  viewComponent: ViewTag,
  historyDetailViewComponent: HistoryDetailViewTag,
  icon: "tag",
};

import { useEffect } from "react";
import { t } from "i18n-js";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { TextSelect } from "@circle-react-shared/TextSelect";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { usePaywallsEditFormContext } from "../PaywallsEditFormProvider";

export const PriceWebSelector = ({ paywall, filterByPrice, index }: any) => {
  const { allPaywallPrices, allWebPaywallPriceOptions, fieldNameRoot } =
    usePaywallsEditFormContext();

  const { setValue, watch } = useFormContext();

  const currentFieldName = `${fieldNameRoot}.${index}.web_paywall_price.id`;
  const currentPaywallPriceId = watch(currentFieldName);

  const updatePaywallPrice = () => {
    const selectedPrice = allPaywallPrices.find(
      (price: any) => price.id === currentPaywallPriceId,
    );

    setValue(`${fieldNameRoot}.${index}.web_paywall_price`, {
      id: selectedPrice?.id,
      currency_code: selectedPrice?.currency_code,
      amount: selectedPrice?.amount,
      price_type: selectedPrice?.price_type,
    });
  };

  useEffect(() => {
    if (currentPaywallPriceId) {
      updatePaywallPrice();
    }
  }, [currentPaywallPriceId]);

  const isEmptyAndNotSelected =
    allWebPaywallPriceOptions.length === 0 && !!paywall?.web_paywall_price?.id;

  return (
    <>
      {isEmptyAndNotSelected ? (
        <div className="bg-tertiary flex flex-col gap-y-2 rounded p-4">
          <Typography.BodySm>
            {t("paywalls.lock_screen.sidebar.paywall_edit_form.no_web_prices")}
          </Typography.BodySm>
          <div>
            <Button variant="circle" type="button">
              {t(
                "paywalls.lock_screen.sidebar.paywall_edit_form.add_web_price",
              )}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Typography.LabelSm weight="semibold">
            {t(
              "paywalls.lock_screen.sidebar.paywall_edit_form.fields.select_default_price",
            )}
          </Typography.LabelSm>
          <Controller
            name={currentFieldName}
            defaultValue={currentPaywallPriceId}
            render={({ field }) => (
              <TextSelect
                options={allWebPaywallPriceOptions}
                buttonClassName="flex flex-col w-full p-4"
                filterBy={filterByPrice}
                {...field}
              />
            )}
          />
        </>
      )}
    </>
  );
};

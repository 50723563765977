import type { StateCreator } from "zustand";
import { immer } from "zustand/middleware/immer";
import {
  DEFAULT_DEVICE_ID,
  DEFAULT_GRID_LAYOUT_PAGE_SIZE,
  DEFAULT_SPEAKER_LAYOUT_PAGE_SIZE,
  MAX_GRID_LAYOUT_PAGE_SIZE,
  MAX_SPEAKER_LAYOUT_PAGE_SIZE,
  MIN_GRID_LAYOUT_PAGE_SIZE,
  MIN_SPEAKER_LAYOUT_PAGE_SIZE,
} from "../constants";

interface Settings {
  microphoneEnabled: boolean;
  cameraEnabled: boolean;
  audioInputId: string;
  audioOutputId: string | null;
  videoInputId: string;
  blurEnabled: boolean;
  mirrorEnabled: boolean;
  noiseSuppression: string;
  gridLayoutPageSize: number;
  speakerLayoutPageSize: number;
}

interface SettingsActions {
  toggleMicrophone: () => void;
  setMicrophoneEnabled: (isMicrophoneEnabled: boolean) => void;
  toggleCamera: () => void;
  setCameraEnabled: (isCameraEnabled: boolean) => void;
  setAudioInputId: (inputId: string) => void;
  setAudioOutputId: (outputId: string | null) => void;
  setVideoInputId: (inputId: string) => void;
  toggleBlur: () => void;
  toggleMirror: () => void;
  setNoiseSuppression: (noiseSuppression: string) => void;
  setBlurEnabled: () => void;
  setGridLayoutPageSize: (pageSize: number) => void;
  setSpeakerLayoutPageSize: (pageSize: number) => void;
}

export interface SettingsSlice {
  settings: Settings;
  settingsActions: SettingsActions;
}

export const createSettingsSlice: StateCreator<
  SettingsSlice,
  [],
  [["zustand/immer", never]],
  SettingsSlice
> = immer(set => ({
  settings: {
    microphoneEnabled: true,
    cameraEnabled: true,
    audioInputId: DEFAULT_DEVICE_ID,
    audioOutputId: null,
    videoInputId: DEFAULT_DEVICE_ID,
    blurEnabled: false,
    mirrorEnabled: true,
    noiseSuppression: "basic",
    gridLayoutPageSize: DEFAULT_GRID_LAYOUT_PAGE_SIZE,
    speakerLayoutPageSize: DEFAULT_SPEAKER_LAYOUT_PAGE_SIZE,
  },
  settingsActions: {
    toggleMicrophone: () =>
      set(state => {
        state.settings.microphoneEnabled = !state.settings.microphoneEnabled;
      }),
    setMicrophoneEnabled: isMicrophoneEnabled =>
      set(state => {
        state.settings.microphoneEnabled = isMicrophoneEnabled;
      }),
    toggleCamera: () =>
      set(state => {
        state.settings.cameraEnabled = !state.settings?.cameraEnabled;
      }),
    setCameraEnabled: isCameraEnabled =>
      set(state => {
        state.settings.cameraEnabled = isCameraEnabled;
      }),
    setAudioInputId: inputId =>
      set(state => {
        state.settings.audioInputId = inputId;
      }),
    setAudioOutputId: outputId =>
      set(state => {
        state.settings.audioOutputId = outputId;
      }),
    setVideoInputId: inputId =>
      set(state => {
        state.settings.videoInputId = inputId;
      }),
    toggleBlur: () =>
      set(state => {
        state.settings.blurEnabled = !state.settings?.blurEnabled;
      }),
    toggleMirror: () =>
      set(state => {
        state.settings.mirrorEnabled = !state.settings?.mirrorEnabled;
      }),
    setNoiseSuppression: noiseSuppression =>
      set(state => {
        state.settings.noiseSuppression = noiseSuppression;
      }),
    setBlurEnabled: () =>
      set(state => {
        state.settings.blurEnabled = true;
      }),
    setGridLayoutPageSize: pageSize =>
      set(state => {
        const newPageSize = Math.min(
          Math.max(pageSize, MIN_GRID_LAYOUT_PAGE_SIZE),
          MAX_GRID_LAYOUT_PAGE_SIZE,
        );
        state.settings.gridLayoutPageSize = newPageSize;
      }),
    setSpeakerLayoutPageSize: pageSize =>
      set(state => {
        const newPageSize = Math.min(
          Math.max(pageSize, MIN_SPEAKER_LAYOUT_PAGE_SIZE),
          MAX_SPEAKER_LAYOUT_PAGE_SIZE,
        );
        state.settings.speakerLayoutPageSize = newPageSize;
      }),
  },
}));

import { t } from "i18n-js";
import { useMutation, useQueryClient } from "react-query";
import { broadcastsApi } from "@circle-react/api/broadcastsApi";
import { useToast } from "@circle-react-shared/uikit/ToastV2";
import type { BroadcastBuilderFormFields } from "./useGetBroadcast";
import { getBroadcastQueryKey } from "./useGetBroadcast";

interface UseUpdateBroadcastParams {
  id: number;
}

interface ResponseData {
  id: number;
}

export type PartialBroadcastBuilderFormFields = Partial<{
  [P in keyof BroadcastBuilderFormFields]: P extends "broadcast_content"
    ? Partial<BroadcastBuilderFormFields[P]>
    : BroadcastBuilderFormFields[P];
}>;

export const useUpdateBroadcast = ({ id }: UseUpdateBroadcastParams) => {
  const queryClient = useQueryClient();
  const toastr = useToast();

  return useMutation<
    ResponseData,
    unknown,
    { body: PartialBroadcastBuilderFormFields }
  >({
    mutationFn: ({ body }) =>
      broadcastsApi.update({
        id,
        body: {
          internal_name: body.internal_name,
          broadcast_content_attributes: body.broadcast_content,
          broadcast_audience_attributes: body.broadcast_audience,
          scheduled_at: body.scheduled_at,
          send_type: body.send_type,
        },
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries(getBroadcastQueryKey(id));
    },
    onError: () => toastr.error(t("unexpected_error")),
  });
};

import {
  selectIsChatSelected,
  selectIsParticipantsSelected,
  selectUI,
} from "./selectors";
import { useLiveStore } from "./useLiveStore";

export const useLiveSettingsActions = () =>
  useLiveStore(state => state.settingsActions);

export const useLiveUI = () => {
  const uiState = useLiveStore(selectUI);
  const isChatSelected = useLiveStore(selectIsChatSelected);
  const isParticipantsSelected = useLiveStore(selectIsParticipantsSelected);

  return {
    ...uiState,
    isChatSelected,
    isParticipantsSelected,
  };
};

import { adminMemberEditPaths } from "@circle-react/helpers/urlHelpers";
import { AdminMemberEditPage } from "@circle-react/pages/AdminMembers";
import { ProtectedRoute } from "@circle-react-shared/ProtectedRoute";

const profileRoutes = [
  adminMemberEditPaths.index(),
  adminMemberEditPaths.info(),
  adminMemberEditPaths.access(),
  adminMemberEditPaths.billing(),
  adminMemberEditPaths.activityLogs(),
];

export const AdminMemberEditRoutes = () => (
  <ProtectedRoute component={AdminMemberEditPage} path={profileRoutes} exact />
);

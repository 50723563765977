import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { CustomizeSpaceSectionSelector } from "@/react/components/Spaces/CustomizeSpaceFullPage/CustomizeSpaceSectionSelector";
import { Layout } from "@circle-react-shared/CustomizeUI";
import { useCustomizeUIData } from "@circle-react-shared/CustomizeUI";
import { MobileNotSupportedPanel } from "./MobileNotSupportedPanel";

export const CustomizeSpaceLayout = ({ space, scrollToId }) => {
  const { currentSection } = useCustomizeUIData();
  const isLockscreen = currentSection === t("customize_space.lock_screen");

  const variant = !isLockscreen ? "only-main" : "no-header";

  return (
    <>
      <Layout
        variant={variant}
        className={classNames({
          "hidden lg:grid": isLockscreen,
        })}
      >
        {space && (
          <CustomizeSpaceSectionSelector
            space={space}
            scrollToId={scrollToId}
          />
        )}
      </Layout>
      {isLockscreen && (
        <MobileNotSupportedPanel
          className="lg:hidden"
          title={t("courses.lockscreen.mobile_unsupported.title")}
          description={t("courses.lockscreen.mobile_unsupported.description")}
        />
      )}
    </>
  );
};

CustomizeSpaceLayout.propTypes = {
  space: PropTypes.object,
};

import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { Filter, InputFilter, useFilterUrl } from "@circle-react-shared/Filter";

const localeNamespace = "settings.workflows.history.filters";

export const RunIdFilter = ({ onApply }) => {
  const { queryParams, updateUrl } = useFilterUrl();

  return (
    <Filter
      chip={t([localeNamespace, "run_id"])}
      title={t([localeNamespace, "run_id_title"])}
      selectedText={queryParams.run_id}
      onApply={value => {
        updateUrl("run_id", value);
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
    >
      <InputFilter
        name="run-id-filter"
        placeholder={t([localeNamespace, "run_id_placeholder"])}
      />
    </Filter>
  );
};

RunIdFilter.propTypes = {
  onApply: PropTypes.func,
};

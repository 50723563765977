import type { PaymentElementProps } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import I18n from "i18n-js";
import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 as Tippy } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";
import { CheckoutFormFieldWrapper } from "../../";
import { usePaymentElementDefaultValuesOption } from "./hooks/usePaymentElementDefaultValuesOption";
import { usePaymentElementFieldsOption } from "./hooks/usePaymentElementFieldsOption";

export interface CheckoutFormPaymentElementFieldProps {
  handleOnChangeCard: PaymentElementProps["onChange"];
  handleOnReadyCard: PaymentElementProps["onReady"];
}

export const PaymentElementForm = ({
  handleOnChangeCard,
  handleOnReadyCard,
}: CheckoutFormPaymentElementFieldProps) => {
  const { getSelectedPrice } = usePaywallCheckoutContext();

  const { fieldsOption } = usePaymentElementFieldsOption();
  const { defaultValuesOption } = usePaymentElementDefaultValuesOption();

  const selectedPrice = getSelectedPrice();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Bump Stripe.js version
  const termsObject = {
    terms: {
      card: "never",
      googlePay: "never",
      applePay: "never",
    },
  } as any;

  const onLoadError = (event: any) => {
    const defaultMessage = "Failed to render PE";
    console.error(defaultMessage, event);
    notifyBugsnag(new Error(event?.error?.message ?? defaultMessage));
  };

  return (
    <CheckoutFormFieldWrapper
      name="payment"
      childClassName="!mb-4"
      LabelComponent={Typography.LabelLg}
      addon={
        selectedPrice.trial_enabled && (
          <span className="inline-flex">
            <Tippy
              className="inline-flex"
              content={I18n.t("paywall_checkout.payment_subtitle_tooltip")}
            >
              <Icon type="12-question-mark" size={12} color="text-primary" />
            </Tippy>
          </span>
        )
      }
    >
      <div data-testid="checkout-form-payment-methods-field-wrapper">
        <PaymentElement
          options={{
            ...defaultValuesOption,
            ...fieldsOption,
            ...{
              layout: {
                type: "accordion",
                radios: false,
              },
            },
            ...termsObject,
          }}
          onChange={handleOnChangeCard}
          onReady={handleOnReadyCard}
          onLoadError={onLoadError}
        />
      </div>
    </CheckoutFormFieldWrapper>
  );
};

import type { ReactNode } from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import { useIsWorkflowsV2Enabled } from "@circle-react/hooks/useIsWorkflowsV2Enabled";
import { useSubmitWorkflow } from "../ConfirmSubmitWorkflowModal/useSubmitWorkflow";

interface FormProps {
  children: ReactNode;
  isRerun?: boolean;
}

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ children, isRerun }, ref) => {
    const { submitWorkflow } = useSubmitWorkflow({ isRerun });
    const isWorkflowsV2Enabled = useIsWorkflowsV2Enabled();
    return (
      <form
        ref={ref}
        className={classNames("flex h-full flex-col", {
          "w-full": isWorkflowsV2Enabled,
        })}
        onSubmit={submitWorkflow}
      >
        {children}
      </form>
    );
  },
);

Form.displayName = "Form";

import { useEffect } from "react";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { SnapshotNumbers } from "@circle-react-shared/SnapshotNumbers";
import { Loader } from "@circle-react-uikit/Loader";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useChargeStatsQuery } from "./hooks/useChargeStatsQuery";

export const Stats = () => {
  const { error } = useToast();

  const { currentCommunitySettings } = usePunditUserContext();
  const isPaywallSnapshotsEnabled =
    currentCommunitySettings?.paywall_snapshots_enabled;

  const { isChargeStatsLoading, chargeStats, chargeStatsError } =
    useChargeStatsQuery();

  useEffect(() => {
    if (chargeStatsError) {
      error(chargeStatsError.message);
    }
  }, [chargeStatsError]);

  if (!isPaywallSnapshotsEnabled) {
    return null;
  }

  if (!isChargeStatsLoading && chargeStatsError) {
    return null;
  }

  const snapshots = [
    {
      label: t("settings.paywall_charge_stats.total_revenue.label"),
      tooltip: t("settings.paywall_charge_stats.total_revenue.tooltip"),
      value: chargeStats?.total_revenue_as_currency,
    },
    {
      label: t("settings.paywall_charge_stats.total_refunded.label"),
      tooltip: t("settings.paywall_charge_stats.total_refunded.tooltip"),
      value: chargeStats?.total_refunded_as_currency,
    },
    {
      label: t("settings.paywall_charge_stats.sales_taxes.label"),
      tooltip: t("settings.paywall_charge_stats.sales_taxes.tooltip"),
      value: chargeStats?.sales_taxes_as_currency,
    },

    {
      label: t("settings.paywall_charge_stats.affiliate_earnings.label"),
      tooltip: t("settings.paywall_charge_stats.affiliate_earnings.tooltip"),
      value: chargeStats?.affiliate_earnings_as_currency,
    },
  ];

  return (
    <>
      {isChargeStatsLoading ? (
        <Loader center />
      ) : (
        <div className="my-3 grid grid-cols-1 px-6 md:grid-cols-4 md:gap-6">
          {snapshots.map(snapshot => (
            <SnapshotNumbers
              number={snapshot.value}
              label={snapshot.label}
              tooltipText={snapshot.tooltip}
              key={snapshot.label}
            />
          ))}
        </div>
      )}
    </>
  );
};

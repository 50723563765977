import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createSessionSlice } from "./slices/session";
import { createSettingsSlice } from "./slices/settings";
import { createUISlice } from "./slices/ui";

export type LiveStore = ReturnType<typeof createSettingsSlice> &
  ReturnType<typeof createUISlice> &
  ReturnType<typeof createSessionSlice>;

export const useLiveStore = create<LiveStore>()(
  persist(
    (...a) => ({
      ...createSettingsSlice(...a),
      ...createUISlice(...a),
      ...createSessionSlice(...a),
    }),
    {
      name: "circle-live-storage",
      version: 3,
      partialize: state => ({
        settings: state.settings,
      }),
    },
  ),
);

import { t } from "i18n-js";

export const GENERAL_ERROR = {
  message: t("paywall_checkout.form.errors.payment_general_error"),
};

export const STRIPE_CONNECTION_ERROR = {
  message: t("paywall_checkout.form.errors.stripe_connection_failed"),
};

export const PIX_PAYMENT_EXPIRED = {
  message: t("paywall_checkout.form.errors.pix_payment.time_expired"),
  error_details: "PIX_PAYMENT_EXPIRED",
};

export const PIX_MODAL_CLOSED = {
  message: t("paywall_checkout.form.errors.pix_payment.pix_modal_closed"),
  error_details: "PIX_MODAL_CLOSED",
};

const TAX_ID_REQUIRED = {
  message: t(
    "paywall_checkout.form.errors.community_member_billing_info_attributes[tax_id_value]_required",
  ),
};

export const getReferral = () => window.Rewardful?.referral;

export const DEFAULT_ERROR = {
  disablePayButton: true,
  type: "danger",
  existingAccount: false,
  hasAlreadyPurchased: false,
  message: null,
};

export const buildErrorMessage = (
  error,
  { loginUrl, billingUrl, eventUrl } = {},
) => {
  const errors = {
    USER_LOGIN_REQUIRED: {
      disablePayButton: false,
      type: "warning",
      existingAccount: true,
      message: t("paywall_checkout.form.errors.existing_account", {
        sign_in_url: loginUrl,
      }),
    },
    USER_FORM_ERROR: {
      message: t("paywall_checkout.form.errors.user_error"),
      disablePayButton: false,
    },
    PAYWALL_ALREADY_PURCHASED: {
      hasAlreadyPurchased: true,
      type: "warning",
      message: t("paywall_checkout.form.errors.already_purchased", {
        billing_url: billingUrl,
      }),
    },
    PROCESSOR_ERROR: {
      message:
        error.message ||
        t("paywall_checkout.form.errors.payment_general_error"),
    },
    INVALID_COUPON: {
      message: error.message,
    },
    PIX_PAYMENT_EXPIRED,
    PIX_MODAL_CLOSED,
    TAX_ID_REQUIRED,
    EVENT_SPOT_EXPIRED: {
      type: "danger",
      message: t("paywall_checkout.form.errors.event_spot_expired", {
        event_url: eventUrl,
      }),
    },
    EVENT_SPOT_PAID: {
      type: "warning",
      message: t("paywall_checkout.form.errors.event_spot_paid", {
        event_url: eventUrl,
      }),
    },
    EVENT_RSVP_LIMIT_REACHED: {
      type: "danger",
      message: t("paywall_checkout.form.errors.event_rsvp_limit_reached"),
    },
    COLLECT_PAYMENT: {
      type: "danger",
      message: t("paywall_checkout.form.errors.collect_payment"),
    },
  };
  return errors[error.error_details] || GENERAL_ERROR;
};

export const buildPayButtonLabel = ({
  isPaywallActive,
  selectedPrice,
  preview,
}) => {
  if (!isPaywallActive || !selectedPrice) {
    return t("paywall_checkout.form.buttons.draft_pay");
  }
  if (selectedPrice?.trial_enabled) {
    if (selectedPrice?.trial_requires_upfront_payment) {
      return t("paywall_checkout.form.buttons.paid_trial");
    }
    return t("paywall_checkout.form.buttons.trial");
  }
  if (preview?.amount_due_now === 0 && selectedPrice?.display_interval) {
    return t("paywall_checkout.form.buttons.subscribe");
  }
  if (preview?.amount_due_now === 0 && !selectedPrice?.display_interval) {
    return t("paywall_checkout.form.buttons.continue");
  }
  if (preview?.amount_due_now > 0) {
    return `${t("paywall_checkout.form.buttons.pay")} ${
      preview.formatted_amount_due_now
    }`;
  }
  return `${t("paywall_checkout.form.buttons.pay")} ${
    selectedPrice.display_amount_with_currency
  }`;
};

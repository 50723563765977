import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useCommunityMember } from "@circle-react/hooks/useCommunityMember";
import { Filter, useFilterUrl } from "@circle-react-shared/Filter";
import { AutoCompleteInput } from "./AutoCompleteInput";

const localeNamespace = "settings.workflows.history.filters";
const fieldId = "member_id";

export const CommunityMemberFilter = ({ onApply }) => {
  const { queryParams, updateUrl } = useFilterUrl();
  const memberId = queryParams[fieldId];
  const {
    communityMemberQuery: { data: communityMember },
  } = useCommunityMember({ memberId });

  const getSelectedMember = () => {
    if (!communityMember) {
      return;
    }
    return communityMember.name;
  };

  return (
    <Filter
      chip={t([localeNamespace, "member"])}
      title={t([localeNamespace, "member_title"])}
      selectedText={memberId}
      renderSelectedText={getSelectedMember}
      onApply={value => {
        updateUrl(fieldId, value?.toString());
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
    >
      <AutoCompleteInput defaultValue={communityMember} />
    </Filter>
  );
};

CommunityMemberFilter.propTypes = {
  onApply: PropTypes.func,
};

import { isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import { useCommunityMembersInfiniteQuery } from "@circle-react/hooks/useCommunityMembersInfiniteQueryV2";
import { STATUS_OPTIONS_MAP } from "@circle-react-shared/MembersList/constants";
import { numberFormatter } from "@circle-react-shared/uikit/TabV2/Counter";
import { removeEmptyValues } from "./removeEmptyValues";

export const useFormAudienceCounter = query => {
  const { watch } = useFormContext();
  const audienceFilters = watch("audience.filter_rules") || {};

  const filters = removeEmptyValues(audienceFilters);

  if (query) {
    filters.push({ key: "name", filter_type: "contains", value: query });
  }

  const hasFilters = !isEmpty(filters);

  const { totalQueryMembers, ...membersQuery } =
    useCommunityMembersInfiniteQuery({
      filters,
      scope: {
        status: STATUS_OPTIONS_MAP.ACTIVE,
        exclude_empty_name: false,
        exclude_empty_profiles: false,
      },
      options: {
        disabled: !hasFilters,
        keepPreviousData: true,
      },
    });

  const formattedCount = numberFormatter(totalQueryMembers);

  return {
    hasFilters,
    formattedCount,
    count: totalQueryMembers,
    ...membersQuery,
  };
};

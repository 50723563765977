import { t } from "i18n-js";
import { FormTipTapEditor } from "@circle-react-shared/uikit/Form/FormTipTapEditor";
import { WorkflowDMMenuBar } from "@circle-react-shared/uikit/Form/FormTipTapEditor/WorkflowDMMenuBar";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useMessageBodyInput } from "./useMessageBodyInput";

export const MessageBodyInput = () => {
  const {
    paramsPath,
    triggerType,
    isLoadingDynamicVariables,
    dynamicVariables,
    shouldShowPreviewDmButton,
    handlePreviewDmClick,
  } = useMessageBodyInput();

  if (isLoadingDynamicVariables) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-2">
      <Typography.LabelSm weight="semibold">
        {t("settings.workflows.form.message_body")}
      </Typography.LabelSm>
      <FormTipTapEditor
        key={triggerType}
        placeholder={t("settings.workflows.form.message_body_placeholder")}
        name={`${paramsPath}.rich_text_body`}
        submitOnEnter={false}
        className="!border-primary focus-within:!border-light !mb-0 !px-4 !text-base"
        editorClassName="min-h-[5rem]"
        menuBarComponent={WorkflowDMMenuBar}
        menubarProps={{ dynamicVariables }}
        type="workflow"
      />
      {shouldShowPreviewDmButton && (
        <Button
          variant="circle-plain"
          type="button"
          className="max-w-fit !px-2"
          onClick={handlePreviewDmClick}
        >
          {t("settings.workflows.form.preview_message.button_label")}
        </Button>
      )}
    </div>
  );
};

import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { usePunditUserContext } from "@circle-react/contexts";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { Modal } from "@circle-react-uikit/ModalV2";
import { tabs } from "../constants";
import { Header } from "./Header";
import { PaywallFormContentOrchestrator } from "./PaywallFormContentOrchestrator";
import { usePaywallAdminFormModal } from "./hooks/usePaywallAdminFormModal";

export interface PaywallsAdminFormModalComponentProps {
  paywall: any;
  refetchPaywalls: () => void;
  paywallGroupsEnabled: boolean;
  initialTab?: (typeof tabs)[keyof typeof tabs];
}

const PaywallsAdminFormModalComponent = ({
  paywall,
  refetchPaywalls,
  paywallGroupsEnabled,
  initialTab,
}: PaywallsAdminFormModalComponentProps) => {
  const modal = useModal();

  const {
    currentCommunitySettings: communitySettings,
    isLoading: isLoadingCommunitySettings,
  } = usePunditUserContext();

  const onClose = () => {
    void modal.hide();
    modal.remove();
  };

  const {
    getDefaultValues,
    setActiveTab,
    activeTab,
    handleSubmit,
    mutation,
    currentPaywallStatus,
  } = usePaywallAdminFormModal({
    initialTab,
    paywall,
    refetchPaywalls,
    onClose,
    isModalVisible: modal.visible,
  });

  if (isLoadingCommunitySettings) {
    return <Loader />;
  }

  const requiresCurrency =
    paywall?.policies?.requires_currency ??
    communitySettings.currencies_enabled;

  const canChangeCurrency = paywall?.policies?.can_change_currency ?? true;

  const isWorkflowsActiveTab = activeTab === tabs.WORKFLOWS_TAB;

  const submitHandler = isWorkflowsActiveTab ? () => {} : handleSubmit;

  return (
    <Modal
      isOpen={modal.visible}
      onClose={onClose}
      contentPortalId="paywall-admin-modal"
    >
      <Modal.Overlay />
      <Modal.Content size="2xl">
        <Form
          onSubmit={submitHandler}
          data-testid="paywall-admin-modal"
          defaultValues={getDefaultValues(paywall)}
        >
          {({ formState: { isSubmitting } }) => (
            <>
              <Modal.Header className="!pt-4">
                <Modal.Title>
                  <Header isPaywallNew={!paywall} />
                </Modal.Title>
                <Modal.HeaderActions>
                  <Modal.CloseButton
                    onClick={onClose}
                    data-testid="paywall-admin-modal-close-button"
                  />
                </Modal.HeaderActions>
              </Modal.Header>
              <PaywallFormContentOrchestrator
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isSubmitting={isSubmitting}
                mutation={mutation}
                currentPaywallStatus={currentPaywallStatus}
                paywallGroupsEnabled={paywallGroupsEnabled}
                requiresCurrency={requiresCurrency}
                canChangeCurrency={canChangeCurrency}
              />
            </>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const PaywallsAdminFormModal = ModalManager.create(
  (prop: PaywallsAdminFormModalComponentProps) => (
    <PaywallsAdminFormModalComponent {...prop} />
  ),
);

export const usePaywallsAdminFormModal = () => useModal(PaywallsAdminFormModal);

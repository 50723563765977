import { t } from "i18n-js";
import isFunction from "lodash/isFunction";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isCourseSpace,
  isDraftSpace,
  isEventsSpace,
} from "@circle-react/helpers/spaceHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Space } from "@circle-react/types";
import type { RegularOption } from "@circle-react-shared/uikit/Dropdown/Option";
import { useCoverImageLabel } from "./useCoverImageLabel";
import { useCustomizeMembers } from "./useCustomizeMembers";
import { useSpaceOptionsIcons } from "./useSpaceOptionsIcons";

const customizeSection = t("customize_space.customize");
const customizeOptions = t("customize_space.options");
const customizePaywalls = t("customize_space.paywalls.title");
const customizeMembers = t("customize_space.members");
const customizeLessons = t("customize_space.lessons");
const customizeLockScreen = t("customize_space.lock_screen");
const setWelcomeBanner = t("customize_space.set_welcome_banner");
const customizeWorkflows = t("customize_space.workflows");
const addSpaceLinks = t("add_space_links");

export interface UseManageSpaceOptionsProps {
  space: Space;
  onInviteMemberClick?: () => void;
  onAddCoverClick?: () => void;
  openCustomizeModal: (props: {
    initialSection: string;
    scrollToId?: string;
  }) => void;
  openAddSpaceLinksModal: () => void;
}

export const useManageSpaceOptions = ({
  space,
  onInviteMemberClick,
  onAddCoverClick,
  openCustomizeModal,
  openAddSpaceLinksModal,
}: UseManageSpaceOptionsProps) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const {
    space_description_enabled: spaceDescriptionEnabled,
    upgrade_flows_for_workflows_spaces_enabled:
      upgradeFlowsForWorkflowsSpacesEnabled,
  } = currentCommunitySettings || {};
  const isCourse = isCourseSpace(space);
  const shouldShowAddCoverImage = isFunction(onAddCoverClick);
  const shouldShowInviteMember = isFunction(onInviteMemberClick);
  const shouldShowLessons = isCourse;
  const isEventsSpaceType = isEventsSpace(space);
  const isLgScreen = useLgScreenMediaQuery();
  const coverImageLabel = useCoverImageLabel({ space });
  const { customizeMembersBadge } = useCustomizeMembers({ space });

  const { isV3Enabled, iconSize } = useIsV3();

  const {
    coverImageIcon,
    customizeLessonsIcon,
    welcomeBannerIcon,
    customizeIcon,
    creditCardIcon,
    customizeLockScreenIcon,
    customizeMembersIcon,
    inviteMemberIcon,
    customizeOptionsIcon,
    addSpaceLinksIcon,
  } = useSpaceOptionsIcons();

  const manageSpaceOptions: RegularOption[] = [];

  if (shouldShowAddCoverImage) {
    manageSpaceOptions.push({
      value: t("add_cover_image"),
      label: coverImageLabel,
      onClick: onAddCoverClick,
      icon: coverImageIcon,
      iconSize,
    });
  }

  if (isV3Enabled) {
    manageSpaceOptions.push({
      value: addSpaceLinks,
      label: addSpaceLinks,
      onClick: () => openAddSpaceLinksModal(),
      icon: addSpaceLinksIcon,
      iconSize,
    });
  }

  if (shouldShowLessons) {
    manageSpaceOptions.push({
      value: customizeLessons,
      label: customizeLessons,
      onClick: () => openCustomizeModal({ initialSection: customizeLessons }),
      icon: customizeLessonsIcon,
      iconSize,
    });
  }

  if (spaceDescriptionEnabled && isEventsSpaceType) {
    manageSpaceOptions.push({
      value: customizeSection,
      label: setWelcomeBanner,
      onClick: () =>
        openCustomizeModal({
          initialSection: customizeSection,
          scrollToId: "space-welcome-banner",
        }),
      icon: welcomeBannerIcon,
      iconSize,
    });
  }

  manageSpaceOptions.push(
    {
      value: customizeSection,
      label: customizeSection,
      onClick: () => openCustomizeModal({ initialSection: customizeSection }),
      icon: customizeIcon,
      iconSize,
    },
    {
      value: customizePaywalls,
      label: customizePaywalls,
      onClick: () => openCustomizeModal({ initialSection: customizePaywalls }),
      icon: creditCardIcon,
      iconSize,
    },
    {
      value: customizeLockScreen,
      label: customizeLockScreen,
      onClick: () =>
        openCustomizeModal({ initialSection: customizeLockScreen }),
      icon: customizeLockScreenIcon,
      iconSize,
    },
    {
      value: customizeMembers,
      label: customizeMembers,
      badge: customizeMembersBadge,
      onClick: () => openCustomizeModal({ initialSection: customizeMembers }),
      icon: customizeMembersIcon,
      iconSize,
    },
  );

  if (shouldShowInviteMember && (!isV3Enabled || !isLgScreen)) {
    const label = !isDraftSpace(space)
      ? t("space_header.invite_member")
      : t("customize_space.add_waitlist");

    manageSpaceOptions.push({
      value: t("space_header.invite_member"),
      label,
      onClick: onInviteMemberClick,
      icon: inviteMemberIcon,
      iconSize,
    });
  }

  manageSpaceOptions.push({
    value: customizeOptions,
    label: customizeOptions,
    onClick: () => openCustomizeModal({ initialSection: customizeOptions }),
    icon: customizeOptionsIcon,
    iconSize,
  });

  if (upgradeFlowsForWorkflowsSpacesEnabled) {
    manageSpaceOptions.push({
      value: customizeWorkflows,
      label: customizeWorkflows,
      onClick: () => openCustomizeModal({ initialSection: customizeWorkflows }),
      icon: "lightning",
    });
  }

  return manageSpaceOptions;
};

import { t } from "i18n-js";
import { useSpacesContext } from "@circle-react/contexts";

export const usePaywallFormDefaultValues = () => {
  const { records: spaceRecords } = useSpacesContext();

  const getDefaultSpaceIds = (
    spaceIdsSelected: any,
    spaceGroupIdsSelected: any,
  ) => {
    const newSpaceIdsSelect = [...spaceIdsSelected];
    spaceRecords.forEach((space: any) => {
      if (spaceGroupIdsSelected.includes(space.space_group_id)) {
        newSpaceIdsSelect.push(space.id);
      }
    });

    return Array.from(new Set(newSpaceIdsSelect));
  };

  const getDefaultValues = (paywallParam: any) => {
    const defaultPaywall = {
      trial_days: 0,
      trial_requires_card: false,
      can_be_repurchased: false,
      prices_attributes: [],
      all_spaces: false,
      space_group_ids: [],
      space_ids: [],
      redirect_url_enabled: false,
      thank_you_page_enabled: true,
      post_purchase_email_enabled: true,
      self_trial_cancelable: false,
      post_purchase_email_message: t(
        "settings.paywalls.form.post_purchase_email_default_message_text",
      ),
      post_purchase_email_meta: {
        subject: t(
          "settings.paywalls.form.post_purchase_email_default_subject_text",
        ),
      },
      currency_id: "",
      conversion_tracking_js_code: "",
    };

    if (!paywallParam) {
      return defaultPaywall;
    }

    return {
      ...defaultPaywall,
      ...paywallParam,
      redirect_url_enabled: !!paywallParam?.redirect_url,
      enable_trial: paywallParam?.trial_days > 0,
      enable_subscription_group: !!paywallParam?.paywall_group_id,
      space_ids: getDefaultSpaceIds(
        paywallParam.space_ids,
        paywallParam.space_group_ids,
      ),
      space_group_ids: paywallParam.space_group_ids ?? [],
      currency_id: paywallParam?.currency?.id ?? "",
    };
  };

  return {
    getDefaultValues,
  };
};

import classNames from "classnames";
import { t } from "i18n-js";
import { Link, useRouteMatch } from "react-router-dom";
import { SearchWrapper } from "@/react/components/HeaderV2/Search";
import { usePunditUserContext } from "@circle-react/contexts";
import { useBackendProps } from "@circle-react/providers";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { CommunityBrand } from "./CommunityBrand";
import { RightActionContent } from "./RightActionContent";

export const MobileAppBar = () => {
  const { minimal_layout: isMinimalLayout } = useBackendProps();

  const { currentCommunity = {}, currentCommunitySettings } =
    usePunditUserContext();

  const isPostDetailsPage = Boolean(useRouteMatch("/c/:spaceSlug/:postSlug"));

  const isNewPostDetailsHeaderOnMobileWebEnabled = Boolean(
    currentCommunitySettings?.post_details_mobile_header_enabled,
  );

  if (isPostDetailsPage && isNewPostDetailsHeaderOnMobileWebEnabled) {
    return null;
  }

  return (
    <div className="sticky top-0 z-30">
      <header id="mainHeader" className="header header-v2">
        <div className="header__inside">
          {!isMinimalLayout && (
            <div className="header__menu">
              <IconButton
                name="hamburger"
                data-action="click->mobile-sidebar#toggle"
                ariaLabel={t("toggle_menu")}
                className="toggle-button"
                buttonSize={28}
                iconSize={20}
                iconClassName="!w-5 !h-5 !text-dark"
              />
            </div>
          )}
          <div
            className={classNames("header__left", {
              "!justify-start": isMinimalLayout,
            })}
          >
            <CommunityBrand currentCommunity={currentCommunity} />
          </div>
          <div className="header__actions">
            <SearchWrapper />

            <div className="header__action">
              <a href="/analytics">
                <svg className="icon icon-analytics " viewBox="0 0 24 24">
                  <use xlinkHref="#icon-analytics" />
                </svg>
              </a>
            </div>

            <div className="header__action ">
              <Link to="/audience/manage">
                <svg className="icon icon-members " viewBox="0 0 24 24">
                  <use xlinkHref="#icon-members" />
                </svg>
              </Link>
            </div>
            <div className="header__action ">
              <Link to="/settings">
                <svg className="icon icon-settings " viewBox="0 0 24 24">
                  <use xlinkHref="#icon-settings" />
                </svg>
              </Link>
            </div>
            <div className="header__user relative z-30">
              <RightActionContent />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

import { useMemberTags } from "@circle-workflows/hooks/useMemberTags";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";

export const MemberTagSelector = () => {
  const { register } = useFormContext();
  const { memberTags, isLoading } = useMemberTags();

  if (isLoading) {
    return <Loader center />;
  }

  const options = memberTags.map(memberTag => ({
    label: memberTag.name,
    value: memberTag.id,
  }));

  return (
    <>
      <div className="hidden">
        <input type="hidden" {...register("trigger.resource_type")} />
      </div>

      <FormTextSelect
        name="trigger.resource_id"
        label={
          <Typography.LabelSm weight="semibold">
            {t("settings.workflows.form.which_tag")}
          </Typography.LabelSm>
        }
        options={options}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        emptyMessage={t("settings.workflows.form.errors.member_tags.not_found")}
      />
    </>
  );
};

MemberTagSelector.propTypes = {};

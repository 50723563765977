import { noop } from "lodash";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../usePricingTabContext";
import { PricingTabAddPriceModalContent } from "./Content";

export const PricingTabAddPriceModal = () => {
  const { defaultPriceTypeOption } = usePricingTabContext();

  const defaultOption = defaultPriceTypeOption();

  return (
    <Form
      className="w-full"
      defaultValues={{ price_type: defaultOption.value }}
      onSubmit={noop}
    >
      <PricingTabAddPriceModalContent />
    </Form>
  );
};

import { Tab } from "@circle-react-uikit/TabV2";
import { Filters } from "../Filters";
import { useTabWithFilters } from "./hooks/useTabWithFilters";

const filters = [
  "community_member_name",
  "community_member_email",
  "community_member_id",
  "charge_status",
  "paywalls",
  "events",
  "amount",
  "created_at",
  "subscription_id",
];

const customFilters = ["currency", "transaction_id", "invoice_processor_id"];

export const TabWithFilters = ({
  setActiveFilterTab,
  activeFilterTab,
  setSearchKey,
  resetAllChargesToFirstPage,
}) => {
  const { tabOptions, changeTabAndResetForm } = useTabWithFilters(
    setActiveFilterTab,
    setSearchKey,
    resetAllChargesToFirstPage,
  );

  const selectedTabIndex = tabOptions.findIndex(
    tab => tab.value === activeFilterTab,
  );

  return (
    <div className="border-primary">
      <Tab.Group selectedIndex={selectedTabIndex}>
        <Tab.List className="border-primary border-b px-6 py-0">
          {tabOptions.map(tab => (
            <Tab
              key={tab.value}
              onClick={() => changeTabAndResetForm(tab.value)}
            >
              {tab.label}
              <Tab.Counter>{tab.badge.value}</Tab.Counter>
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
      <Filters
        setSearchKey={setSearchKey}
        filterNames={filters}
        customFilterNames={customFilters}
      />
    </div>
  );
};

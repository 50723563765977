import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";
import { useSpacesContext } from "@circle-react/contexts";
import { ErrorBoundary } from "@circle-react-uikit/ErrorBoundary";
import { SupportWidgetContextProvider } from "../SupportWidgetContext";
import { SupportWidgetError } from "../SupportWidgetError";
import { SupportWidgetPortalBody } from "../SupportWidgetPortalBody";
import { WIDGET_HIDDEN_ROUTES } from "../constants";
import { useCheckConversations } from "../queries";
import { getLocalStorage, setLocalStorage } from "../utils";

export const SupportWidgetPortal = () => {
  const location = useLocation();
  const { records: spaces } = useSpacesContext();
  const { currentCommunity, currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();

  const { data } = useCheckConversations();

  // Remove empty keys from local storage that depend on conversation id
  const localStorageObject = getLocalStorage();
  for (const key in localStorageObject) {
    if (
      (key.match(/conversation-view-\d+-comment/) &&
        localStorageObject[key] === "") ||
      (key.match(/conversation-view-\d+-attachments/) &&
        localStorageObject[key].length === 0) ||
      key === "isChatWindowExpanded"
    ) {
      delete localStorageObject[key];
    }
  }
  setLocalStorage(localStorageObject);

  const shouldHideSupportWidget = !currentCommunityMember?.is_admin;

  const chatSpaces = spaces.filter(space => space.post_type === "chat");
  const chatSpacesSlugs = chatSpaces.map(space => space.slug);
  const routesWithWidgetHidden = WIDGET_HIDDEN_ROUTES.concat(chatSpacesSlugs);

  const isWidgetHiddenOnRoute = routesWithWidgetHidden.some(route =>
    location.pathname.includes(route),
  );

  if (shouldHideSupportWidget || isWidgetHiddenOnRoute) {
    return null;
  }

  return (
    <>
      {createPortal(
        <ErrorBoundary renderFunc={SupportWidgetError}>
          <SupportWidgetContextProvider
            has_active_conversations={data?.has_active_conversations || false}
            unread_conversations={data?.unread_conversations || []}
            live_chat_enabled={
              currentCommunitySettings.support_widget_v2_enabled ||
              currentCommunity.current_plan_tier == "basic"
            }
            support_widget_v2_enabled={
              currentCommunitySettings.support_widget_v2_enabled
            }
            display_onboarding={
              currentCommunityMember.preferences?.has_seen_widget_onboarding ===
              false // Strict equality is on purpose here, we want to display onboarding only when `has_seen_widget_onboarding` is equal to false
            }
          >
            <SupportWidgetPortalBody />
          </SupportWidgetContextProvider>
        </ErrorBoundary>,
        document.body,
      )}
    </>
  );
};

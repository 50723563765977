import classNames from "classnames";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { PaywallCurrencyAmountFormInput } from "@circle-react/components/Paywalls";
import {
  buildTransformInputValueToCurrencyAmountStrFunc,
  mapCurrencyAmountStrToInt,
} from "@circle-react/components/Paywalls/Admin/utils";
import { formatPaywallCurrencyAmount } from "@circle-react/helpers/paywalls/currencies";
import { paywallPriceTypes } from "@circle-react/types";
import { Form } from "@circle-react-uikit/Form";
import { usePricingTabContext } from "../usePricingTabContext";

export const PricingTabAddPriceModalFieldsUpfrontPaymentAmountField = () => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();
  const priceType = watch("price_type");
  const totalValue = watch("amount");
  const totalInCents = mapCurrencyAmountStrToInt(totalValue);

  const { currency } = usePricingTabContext();

  const mapAmountIntToCurrencyAmountStr =
    buildTransformInputValueToCurrencyAmountStrFunc(currency, {
      amountInCents: true,
    });

  const upfrontPaymentAmountBoundsErrorMessage = t(
    "settings.paywalls.modal.tabs.pricing.add_price_modal.field_errors.amount",
    {
      min: formatPaywallCurrencyAmount(currency, currency.min_charge_amount, {
        amountInCents: true,
        includeCurrencyCode: false,
      }),
      max: formatPaywallCurrencyAmount(currency, currency.max_charge_amount, {
        amountInCents: true,
        includeCurrencyCode: false,
      }),
    },
  );
  const upfrontPaymentAmountGreaterThanAmountErrorMessage = t(
    "settings.paywalls.modal.tabs.pricing.add_price_modal.field_errors.upfront_payment_amount",
    {
      total: formatPaywallCurrencyAmount(currency, totalInCents, {
        amountInCents: true,
        includeCurrencyCode: false,
      }),
    },
  );

  return (
    <Form.Item
      name="upfront_payment_amount"
      className={classNames(
        { "border-red-500": errors["upfront_payment_amount"] },
        "!m-0 !mt-2 grow !p-0",
      )}
      errorClassName="mt-1 text-red-500 text-sm"
      placeholder={mapAmountIntToCurrencyAmountStr(0)}
      hideBorder
      hideLabel
      hideDescription
      rules={{
        required: true,
        validate: {
          bounds: value => {
            const valueInt = mapCurrencyAmountStrToInt(value);
            if (
              isNaN(valueInt) ||
              valueInt < currency.min_charge_amount ||
              valueInt > currency.max_charge_amount
            ) {
              return upfrontPaymentAmountBoundsErrorMessage;
            }
            if (
              priceType === paywallPriceTypes.installments &&
              valueInt > totalInCents
            ) {
              return upfrontPaymentAmountGreaterThanAmountErrorMessage;
            }
            return true;
          },
        },
      }}
      transformValue={mapAmountIntToCurrencyAmountStr}
    >
      <PaywallCurrencyAmountFormInput currency={currency} />
    </Form.Item>
  );
};

import { useMemo } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { WORKFLOW_STATUS } from "@circle-react/components/SettingsApp/Workflows/constants";
import { workflowsSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { InfiniteQueryDataTable } from "@circle-react-shared/InfiniteQueryDataTable";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { CommunityMembersListProvider } from "../../CommunityMemberColumn";
import { TableContainer } from "../TableContainer";
import { useTable } from "./useTable";

const i18nRoot = "settings.workflows.table";

export const WorkflowsTable = ({ columns, colgroup, workflowType, status }) => {
  const {
    infiniteQuery,
    isLoading,
    totalWorkflows,
    initialPage,
    defaultPageSize,
    setPage,
  } = useTable({ workflowType, status });

  const history = useHistory();

  const onClickRowHandler = ({ original }) => {
    const { id: workflowId, status } = original || {};
    if (!workflowId) {
      return;
    }

    const isDraft = status === WORKFLOW_STATUS.DRAFT;
    const params = {
      pathname: isDraft
        ? workflowsSettingsPaths.edit({ workflowId })
        : workflowsSettingsPaths.view({ workflowId }),
      state: {
        from: {
          path: history.location.pathname,
          query: history.location.search,
        },
      },
    };
    history.push(params);
  };

  const communityMemberIds = useMemo(() => {
    const { isLoading, workflows, isError } = infiniteQuery;
    if (isLoading) {
      return null;
    }
    if (isError) {
      return null;
    }
    return workflows.map(
      ({ created_by_community_member_id }) => created_by_community_member_id,
    );
  }, [infiniteQuery]);

  return (
    <CommunityMembersListProvider ids={communityMemberIds}>
      <InfiniteQueryDataTable
        columns={columns({ refetch: infiniteQuery.refetch })}
        infiniteQuery={infiniteQuery}
        pageSize={defaultPageSize}
        initialPage={initialPage}
        totalCountPath="total_count"
        onChangePage={newPage => {
          setPage(newPage);
        }}
        onClickRow={onClickRowHandler}
      >
        {!isLoading && (
          <DataTable.Headline>
            <DataTable.Headline.Title>
              {t([i18nRoot, "workflows_count"], { count: totalWorkflows })}
            </DataTable.Headline.Title>
          </DataTable.Headline>
        )}
        <TableContainer className="px-6">
          <DataTable.Table>
            {colgroup && <>{colgroup}</>}
            <DataTable.Header className="z-10" />
            <DataTable.Loader />
            <DataTable.NoRecords text={t([i18nRoot, "no_workflows_found"])} />
            <DataTable.Body />
          </DataTable.Table>
          <DataTable.Pagination align="left" direction="reverse" />
        </TableContainer>
      </InfiniteQueryDataTable>
    </CommunityMembersListProvider>
  );
};

WorkflowsTable.propTypes = {
  columns: PropTypes.func.isRequired,
  colgroup: PropTypes.node,
  workflowType: PropTypes.string,
  status: PropTypes.string,
};

import { useMemo } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { InfiniteQueryDataTable } from "@circle-react-shared/InfiniteQueryDataTable";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { WORKFLOW_TYPE } from "../../../constants";
import { CommunityMembersListProvider } from "../../CommunityMemberColumn";
import { useHistoryWorkflowModal } from "../../HistoryWorkflowDetailModal";
import { TableContainer } from "./TableContainer";
import { useTable } from "./useTable";

const i18nRoot = "settings.workflows.table";

export const HistoryWorkflowsTable = ({ columns, workflowType }) => {
  const {
    infiniteQuery,
    isLoading,
    totalWorkflows,
    initialPage,
    defaultPageSize,
    setPage,
  } = useTable({ workflowType });
  const historyWorkflowModal = useHistoryWorkflowModal();

  const onClickRowHandler = ({ original: { id: taskId } }) => {
    historyWorkflowModal.show({ taskId });
  };
  const communityMemberIds = useMemo(() => {
    const { isLoading, tasks, isError } = infiniteQuery;
    if (isLoading) {
      return null;
    }
    if (isError) {
      return null;
    }
    return tasks.map(({ community_member_id }) => community_member_id);
  }, [infiniteQuery]);

  return (
    <CommunityMembersListProvider ids={communityMemberIds}>
      <InfiniteQueryDataTable
        columns={columns}
        infiniteQuery={infiniteQuery}
        pageSize={defaultPageSize}
        initialPage={initialPage}
        totalCountPath="total_count"
        onChangePage={setPage}
        onClickRow={onClickRowHandler}
      >
        {!isLoading && (
          <DataTable.Headline>
            <DataTable.Headline.Title>
              {t([i18nRoot, "workflows_runs"], { count: totalWorkflows })}
            </DataTable.Headline.Title>
          </DataTable.Headline>
        )}
        <TableContainer className="px-6">
          <DataTable.Table>
            <colgroup>
              <col data-testid="workflow-history-name-column" />
              <col data-testid="workflow-history-member-column" />
              <col
                data-testid="workflow-history-status-column"
                className="min-w-[10rem]"
              />
              <col data-testid="workflow-history-steps-column" />
              <col data-testid="workflow-history-run-at-column" />
            </colgroup>
            <DataTable.Header className="z-10" />
            <DataTable.Loader />
            <DataTable.NoRecords text={t([i18nRoot, "no_runs_found"])} />
            <DataTable.Body />
          </DataTable.Table>
          <DataTable.Pagination align="left" direction="reverse" />
        </TableContainer>
      </InfiniteQueryDataTable>
    </CommunityMembersListProvider>
  );
};

HistoryWorkflowsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  workflowType: PropTypes.oneOf(Object.values(WORKFLOW_TYPE)),
};

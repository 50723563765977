import { t } from "i18n-js";
import { StandardLayoutHeader } from "@/react/components/Layout/StandardLayoutV2/StandardLayoutHeader";
import { SpaceHeaderTitleText } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceHeaderTitleText";
import { usePunditUserContext } from "@circle-react/contexts";
import { useCommunityPaymentProcessorStatus } from "@circle-react/hooks/communityPaymentProcessor/useCommunityPaymentProcessorStatus";
import { PaywallsEmptyState } from "@circle-react-shared/Paywalls/PaywallsEmptyState";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { AnalyticsInfo } from "../AnalyticsInfo";
import { HeaderRightContent } from "./HeaderRightContent";
import { Headline } from "./Headline";
import { Stats } from "./Stats";
import { SubscriptionList } from "./SubscriptionList";
import { TabWithFilters } from "./TabWithFilters";
import { usePaywallSubscriptionsAdmin } from "./hooks/usePaywallSubscriptionsAdmin";

export const PaywallSubscriptionsAdmin = () => {
  const {
    subscriptionsDataByTab,
    resetAllSubscriptionToFirstPage,
    activeFilterTab,
    setActiveFilterTab,
    defaultValues,
    refetchAllSubscriptions,
    setSearchKey,
    onSubmit,
    showStats,
    isLoadingSubscriptions,
  } = usePaywallSubscriptionsAdmin();

  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();
  const paymentProcessor = currentCommunity?.payment_processor;

  const isPaywallSnapshotsEnabled =
    currentCommunitySettings?.paywall_snapshots_enabled;

  const totalSubscriptionsCount =
    subscriptionsDataByTab[activeFilterTab]?.totalSubscriptions;

  const { isPaymentProcessorEnabled } = useCommunityPaymentProcessorStatus();

  if (isLoadingSubscriptions) {
    return (
      <div className="flex h-full">
        <Loader center />
      </div>
    );
  }

  if (
    !isLoadingSubscriptions &&
    (totalSubscriptionsCount === 0 || !isPaymentProcessorEnabled)
  ) {
    return (
      <PaywallsEmptyState
        isPaymentProcessorEnabled={isPaymentProcessorEnabled}
        i18nBase="settings.paywalls_admin_dashboard.subscriptions"
        redirectToPaywalls
      />
    );
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <StandardLayoutHeader
        leftContent={
          <SpaceHeaderTitleText>
            {t("settings.paywalls_admin_dashboard.subscriptions.title")}
          </SpaceHeaderTitleText>
        }
        rightContent={
          <HeaderRightContent
            totalSubscriptionsCount={totalSubscriptionsCount}
          />
        }
        filters={
          <TabWithFilters
            setActiveFilterTab={setActiveFilterTab}
            activeFilterTab={activeFilterTab}
            subscriptionsDataByTab={subscriptionsDataByTab}
            setSearchKey={setSearchKey}
            resetAllSubscriptionToFirstPage={resetAllSubscriptionToFirstPage}
          />
        }
        hasBorder={false}
        headline={
          <>
            {!isPaywallSnapshotsEnabled && <AnalyticsInfo />}
            <Headline totalSubscriptionsCount={totalSubscriptionsCount} />
          </>
        }
      />

      {isPaywallSnapshotsEnabled && paymentProcessor && showStats() && (
        <Stats />
      )}

      <SubscriptionList
        activeFilterTab={activeFilterTab}
        subscriptionsDataByTab={subscriptionsDataByTab}
        refetchAllSubscriptions={refetchAllSubscriptions}
      />
    </Form>
  );
};
